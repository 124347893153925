<div class="cardSkeleton" [class.cardSkeleton_hor]="variant === 'horizontal'">
  <ngx-skeleton-loader
    appearance="circle"
    count="1"
    [theme]="{
      borderRadius: '1rem',
      margin: 0,
      width: '100%',
      height: 'inherit'
    }"
    class="cardSkeleton__top"
  ></ngx-skeleton-loader>

  <ngx-skeleton-loader
    class="cardSkeleton__bottom"
    appearance="line"
    [count]="variant === 'horizontal' ? 4 : 7"
    [theme]="{
      marginBottom: 0,
      height: variant === 'horizontal' ? '2rem' : '1.2rem'
    }"
  ></ngx-skeleton-loader>
</div>
