<section class="container adminLogin">
  <form #af="ngForm" [formGroup]="adminForm" (ngSubmit)="handleLogin()" class="adminLogin__form">
    <h2>Access to the Admin Panel</h2>

    <app-input
      caption="Email"
      formControlName="email"
      [error]="
        af.form.controls['email'].touched &&
        af.form.controls['email'].errors?.required &&
        'Please enter the email'
      "
    ></app-input>
    <app-input
      caption="Password"
      formControlName="password"
      type="password"
      [error]="
        af.form.controls['password'].touched &&
        af.form.controls['password'].errors?.required &&
        'Please enter password'
      "
    ></app-input>

    <button type="submit" class="button" [disabled]="adminForm.invalid">
      Log in to admin panel
    </button>
  </form>
</section>
