<div class="copyButton">
  <button class="copyButton__button" (click)="copyValueToClipboard($event)">
    <img
      [src]="variant === 'outlined' ? 'assets/icons/copy-outline.svg' : 'assets/icons/copy.svg'"
      alt="copy"
    />
  </button>
  <small class="copyButton__message" [class.copyButton__message_active]="showCopiedMessage">
    {{ "COPIED_TO_CLIPBOARD" | translate }}
  </small>
</div>
