<section class="orders">
  <div class="orders__tabsWrapper">
    <app-tabs
      variant="mini"
      [tabs]="orderTabs"
      [currentTabNumber]="selectedTab"
      (tabSelected)="onTabChange($event)"
    ></app-tabs>
  </div>

  <ng-container *ngIf="selectedTab === 0">
    <table
      *ngIf="activeOrders?.data.length > 0; else noActive"
      mat-table
      [dataSource]="activeOrders"
      matSort
      (matSortChange)="onActiveTableSort($event)"
      class="matTable orders__table"
    >
      <ng-container [matColumnDef]="activeOrdersColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.ORDER_ID" | translate }}</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.request.id">
          {{ prop.request.id | shortenText }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.PROPERTY" | translate }}</th>
        <td mat-cell *matCellDef="let prop">{{ prop.property.name }}</td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.TYPE" | translate }}</th>
        <td mat-cell *matCellDef="let prop">{{ renderOrderType(prop.request.type) }}</td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.AREA" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokensAmount | prettifyNumber }} {{ prop.token.ticker }} ({{
            prop.request.tokensAmount * prop.token.squarePerToken | prettifyNumber
          }}
          m²)
        </td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.PRICE" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokenPrice | prettifyNumber }} {{ TOKEN }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.FILLED" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokensFullfilled | prettifyNumber }} {{ prop.token.ticker }} ({{
            prop.request.tokensFullfilled * prop.token.squarePerToken | prettifyNumber
          }}
          m²)
        </td>
      </ng-container>
      <ng-container [matColumnDef]="activeOrdersColumns[6]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "P2P.EXPIRATION" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.endsAt ? (prop.request.endsAt | prettifyDate) : "N/A" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="activeOrdersColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: activeOrdersColumns"
        (click)="openOrderPopup(row)"
      ></tr>
    </table>

    <mat-paginator
      [pageIndex]="activeOrdersPage"
      [pageSize]="10"
      [length]="activeOrdersCount"
      aria-label="Select page"
      (page)="onActivePageChange($event)"
    ></mat-paginator>

    <ng-template #noActive>
      <div class="orders__nothing">No active orders</div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="selectedTab === 1">
    <table
      *ngIf="ordersHistory?.data.length > 0; else noHistory"
      mat-table
      [dataSource]="ordersHistory"
      matSort
      (matSortChange)="onHistoryTableSort($event)"
      class="matTable orders__table"
    >
      <ng-container [matColumnDef]="ordersHistoryColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.ORDER_ID" | translate }}</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.property.id">
          {{ prop.property.id | shortenText }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.PROPERTY" | translate }}</th>
        <td mat-cell *matCellDef="let prop">{{ prop.property.name }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.TYPE" | translate }}</th>
        <td mat-cell *matCellDef="let prop">{{ renderOrderType(prop.request.type) }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.AREA" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokensAmount | prettifyNumber }} {{ prop.token.ticker }} ({{
            prop.request.tokensAmount * prop.token.squarePerToken | prettifyNumber
          }}
          m²)
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.PRICE" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokenPrice | prettifyNumber }} {{ TOKEN }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.FILLED" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.request.tokensFullfilled | prettifyNumber }} {{ prop.token.ticker }} ({{
            prop.request.tokensFullfilled * prop.token.squarePerToken | prettifyNumber
          }}
          m²)
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ordersHistoryColumns[6]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "P2P.STATUS" | translate }}</th>
        <td mat-cell *matCellDef="let prop">
          {{ renderOrderStatus(prop.request.status) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="ordersHistoryColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ordersHistoryColumns"
        (click)="openOrderPopup(row)"
      ></tr>
    </table>

    <mat-paginator
      [pageIndex]="historyPage"
      [pageSize]="10"
      [length]="historyCount"
      aria-label="Select page"
      (page)="onHistoryPageChange($event)"
    ></mat-paginator>

    <ng-template #noHistory>
      <div class="orders__nothing">No order history</div>
    </ng-template>
  </ng-container>
</section>

<jw-modal class="orderModal" id="orderPopup">
  <div *ngIf="selectedOrder" class="orderModal__body">
    <div class="orderModal__header">
      <h3 class="title title_sub">{{ "P2P.ORDER_DETAILS" | translate }}</h3>
      <button (click)="closeOrderPopup()">&#10006;</button>
    </div>
    <div class="orderModal__details">
      <p>
        {{ "P2P.ORDER_ID" | translate }} <span>{{ selectedOrder.property.id }}</span>
      </p>
      <p>
        {{ "P2P.CREATION_DATE" | translate }}
        <span>{{ selectedOrder.request.createdAt | prettifyDate : "full" }}</span>
      </p>
      <p>
        {{ "P2P.STATUS" | translate }}
        <span>{{ renderOrderStatus(selectedOrder.request.status) }}</span>
      </p>
      <p>
        {{ "P2P.EXPIRATION_DATE" | translate }}
        <span>{{
          selectedOrder.request.endsAt
            ? (selectedOrder.request.endsAt | prettifyDate : "full")
            : "N/A"
        }}</span>
      </p>
      <p>
        {{ "P2P.TYPE" | translate }} <span>{{ renderOrderType(selectedOrder.request.type) }}</span>
      </p>
      <p>
        {{ "P2P.PROPERTY" | translate }} <span>{{ selectedOrder.property.name }}</span>
      </p>
      <p>
        {{ "P2P.AREA" | translate }}
        <span
          >{{
            selectedOrder.request.tokensAmount * selectedOrder.token.squarePerToken | prettifyNumber
          }}
          m²</span
        >
      </p>
      <p>
        {{ "P2P.PRICE" | translate }}
        <span>{{ selectedOrder.request.tokenPrice | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <p>
        {{ "P2P.TOTAL_ORDER_AMOUNT" | translate }}
        <span
          >{{ selectedOrder.request.tokensAmount | prettifyNumber }}
          {{ selectedOrder.token.ticker }}</span
        >
      </p>
      <p>
        {{ "P2P.FILLED" | translate }}
        <span>
          {{ selectedOrder.request.tokensFullfilled | prettifyNumber }}
          {{ selectedOrder.token.ticker }} ({{
            selectedOrder.request.tokensFullfilled * selectedOrder.token.squarePerToken
              | prettifyNumber
          }}
          m²)
        </span>
      </p>
    </div>

    <div class="orderModal__trans">
      <h3 class="title title_sub">{{ "P2P.ORDER_TRANSACTION_HISTORY" | translate }}</h3>
      <table mat-table [dataSource]="selectedOrderTransactions" class="matTable">
        <ng-container [matColumnDef]="transactionColumns[0]">
          <th mat-header-cell *matHeaderCellDef>{{ "P2P.DATE" | translate }}</th>
          <td mat-cell *matCellDef="let prop">{{ prop.createdAt | prettifyDate : "full" }}</td>
        </ng-container>
        <ng-container [matColumnDef]="transactionColumns[1]">
          <th mat-header-cell *matHeaderCellDef>{{ "P2P.AREA" | translate }}</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.tokensAmount | prettifyNumber }} {{ selectedOrder.token.ticker }} ({{
              prop.tokensAmount * selectedOrder.token.squarePerToken | prettifyNumber
            }}
            m²)
          </td>
        </ng-container>
        <ng-container [matColumnDef]="transactionColumns[2]">
          <th mat-header-cell *matHeaderCellDef>{{ "P2P.AMOUNT" | translate }}</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.adrdAmount | prettifyNumber }} {{ TOKEN }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="transactionColumns[3]">
          <th mat-header-cell *matHeaderCellDef>{{ "P2P.HASH" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let prop"
            class="orderModal__coloredCell"
            [title]="prop.transactionHash"
          >
            {{ prop.transactionHash | shortenText }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="transactionColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: transactionColumns"></tr>
      </table>
      <div *ngIf="selectedOrderTransactions.data.length === 0" class="orderModal__noTransactions">
        {{ "P2P.NO_TRANSACTIONS" | translate }}
      </div>
    </div>

    <div class="orderModal__bottom" *ngIf="selectedTab === 0">
      <button class="button button_outline" (click)="openDeletePopup()">
        {{ "P2P.DELETE_ORDER" | translate }}
      </button>
    </div>
  </div>
</jw-modal>

<jw-modal id="deleteOrderModal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">{{ "P2P.ARE_YOU_SURE_TO_DELETE" | translate }}</h3>
      <button (click)="closeDeletePopup()">&#10006;</button>
    </div>
    <div class="infoModal__footer">
      <button
        class="button button_outline"
        (click)="handleDeleteOrder()"
        [disabled]="deletionInProgress"
      >
        {{ "P2P.YES" | translate }}
      </button>
      <button class="button" (click)="closeDeletePopup()">{{ "P2P.NO" | translate }}</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="deleteSuccessModal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">{{ "P2P.ORDER_SUCCESSFULLY_DELETED" | translate }}</h3>
      <button (click)="closeDeletionSuccessPopup()">&#10006;</button>
    </div>
    <div class="infoModal__footer">
      <button class="button" (click)="closeDeletionSuccessPopup()">
        {{ "P2P.CLOSE" | translate }}
      </button>
    </div>
  </div>
</jw-modal>
