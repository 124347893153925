import { ChangeContext, Options } from "@angular-slider/ngx-slider";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-input-slider",
  templateUrl: "./input-slider.component.html",
  styleUrls: ["./input-slider.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSliderComponent),
      multi: true,
    },
  ],
})
export class InputSliderComponent implements OnInit, ControlValueAccessor {
  @Input() caption?: string;
  @Input() ticker?: string;
  @Input() minValue: number;
  @Input() maxValue: number;

  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
  };

  range: FormGroup;

  constructor(private fb: FormBuilder) {
    this.range = this.fb.group({
      from: this.minValue ?? 0,
      to: this.maxValue ?? 0,
    });
  }

  ngOnInit(): void {
    const newOptions: Options = Object.assign({}, this.sliderOptions);
    newOptions.floor = 0;
    this.maxValue = +this.maxValue ?? 0;
    newOptions.ceil = this.maxValue;
    if (this.maxValue < 10) {
      newOptions.step = this.maxValue * 0.01;
    }
    this.sliderOptions = newOptions;

    // TODO Smart calculations, may not be needed
    // let step;
    // const calculatedPercent = (1 / 100) * this.maxValue;
    // if (+this.maxValue >= 1000) {
    //   step = Math.floor(calculatedPercent);
    // } else {
    //   step = +calculatedPercent.toFixed(4);
    // }
    // const maxRange = +(step * (100 / 1) + step).toFixed(4);

    // newOptions.ceil = maxRange;
    // newOptions.step = step;
  }

  writeValue(value: any) {
    if (value) {
      this.range.patchValue(value);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.range.valueChanges.subscribe(fn);
  }

  registerOnTouched() {}

  onRangeChange(e: ChangeContext) {
    this.range.controls.from.setValue(e.value);
    this.range.controls.to.setValue(e.highValue);
  }

  handleInput(e: any) {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    switch (inputName) {
      case "min":
        if (+inputValue > this.range.value.to) {
          this.range.controls.from.setValue(this.range.value.to);
        } else if (+inputValue < +this.minValue) {
          this.range.controls.from.setValue(this.minValue);
        }
        break;
      case "max":
        if (+inputValue > this.maxValue) {
          this.range.controls.to.setValue(this.maxValue);
        } else if (+inputValue < this.minValue) {
          this.range.controls.to.setValue(this.minValue);
        }
        break;
    }
  }
}
