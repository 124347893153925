import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-copy-button",
  templateUrl: "./copy-button.component.html",
  styleUrls: ["./copy-button.component.scss"],
})
export class CopyButtonComponent implements OnInit {
  @Input() value: string;
  @Input() variant: "filled" | "outlined" = "filled";

  showCopiedMessage: boolean = false;
  timer: any;

  constructor() {}

  ngOnInit(): void {}

  copyValueToClipboard(event: any) {
    event.stopPropagation();

    if (!this.value) return;

    navigator.clipboard.writeText(this.value);
    this.triggerCopiedMessage();
  }

  triggerCopiedMessage() {
    this.showCopiedMessage = true;

    this.timer = setTimeout(() => {
      this.showCopiedMessage = false;
    }, 2000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
