import { TOKEN } from "src/app/constants/token-name.constant";
import { RAW_TRANSACTION_TYPE } from "./transaction-manager";
import { SC_TRANSACTION_STATUS } from "./property";

export enum LAYOUT_VARIANT {
  GRID,
  ROW,
}

export enum SORTING_TYPE {
  BY_PRICE = "target_price",
  BY_MIN_PURCHASE_AMOUNT = "min_purchase_amount",
  BY_AREA = "area",
  BY_ESTIMATED_INCOME = "estimated_income",
  BY_AVAILABLE_AREA = "available_area",
  BY_TOKEN_PRICE = "token_price",
  BY_CURRENT_INCOME = "current_income",
  BY_PURCHASED_AREA = "purchased_area",
  BY_PURCHASE_AMOUNT = "purchase_amount",
  BY_EXPECTED_INCOME = "expected_income",
}

export enum ORDER_TYPE {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ADMIN_PROPERTY_SORTING_TYPE {
  BY_TICKER = "ticker",
  BY_NAME = "name",
  BY_START_AT = "start_at",
  BY_END_AT = "end_at",
  BY_TARGET = "target",
  BY_COLLECTED = "collected",
  BY_PROGRESS = "progress",
  BY_STATUS = "status",
}

export enum CROWDFUNDING_STATUS {
  DRAFT,
  STARTED,
  COMPLETED,
  FAILED,
}

export const LOCATIONS = ["CZ", "UK", "USA"];

// Filters

export interface IRangeFilter {
  id: number;
  name: string;
  text: string;
  ticker: string;
  from: number;
  to: number;
  type: "number" | "date";
}

export const RANGE_FILTERS: IRangeFilter[] = [
  {
    id: 0,
    name: "minPurchaseAmount",
    text: "Minimum purchase amount",
    ticker: TOKEN,
    from: 0,
    to: 100000,
    type: "number",
  },
  {
    id: 1,
    name: "estimatedAnnualIncome",
    text: "Estimated annual income",
    ticker: "%",
    from: 0,
    to: 100,
    type: "number",
  },
  {
    id: 2,
    name: "targetPrice",
    text: "Target price",
    ticker: TOKEN,
    from: 0,
    to: 100000,
    type: "number",
  },
];

export const P2P_BUY_RANGE_FILTERS: IRangeFilter[] = [
  {
    id: 0,
    name: "availableArea",
    text: "Available area",
    ticker: "m²",
    from: 0,
    to: 10000,
    type: "number",
  },
  {
    id: 1,
    name: "tokenPrice",
    text: "Token price",
    ticker: TOKEN,
    from: 1,
    to: 10000,
    type: "number",
  },
  {
    id: 2,
    name: "estimatedIncome",
    text: "Estimated income",
    ticker: "%",
    from: 0,
    to: 100,
    type: "number",
  },
];

export const P2P_SELL_RANGE_FILTERS: IRangeFilter[] = [
  {
    id: 0,
    name: "availableArea",
    text: "Available area",
    ticker: "m²",
    from: 0,
    to: 10000,
    type: "number",
  },
  {
    id: 1,
    name: "tokenPrice",
    text: "Token price",
    ticker: TOKEN,
    from: 1,
    to: 10000,
    type: "number",
  },
];

export const ADMIN_PROPERTY_RANGE_FILTERS: IRangeFilter[] = [
  {
    id: 0,
    name: "investStartAt",
    type: "date",
    text: "Enter an invest starting date",
    ticker: "",
    from: null,
    to: null,
  },
  {
    id: 1,
    name: "investEndAt",
    type: "date",
    text: "Enter an invest ending date",
    ticker: "",
    from: null,
    to: null,
  },
  { id: 2, name: "target", type: "number", text: "Target", ticker: TOKEN, from: 0, to: 999999 },
  {
    id: 3,
    name: "collected",
    type: "number",
    text: "Collected",
    ticker: TOKEN,
    from: 0,
    to: 999999,
  },
  { id: 4, name: "progress", type: "number", text: "Progress", ticker: "%", from: 0, to: 100 },
];

// Sorting

export interface ISortingField {
  type: SORTING_TYPE;
  order: ORDER_TYPE;
  name?: string;
}

export const PROPERTIES_SORTING_LIST: ISortingField[] = [
  { type: SORTING_TYPE.BY_PRICE, order: ORDER_TYPE.ASC, name: "by Target price ↑" },
  { type: SORTING_TYPE.BY_PRICE, order: ORDER_TYPE.DESC, name: "by Target price ↓" },
  {
    type: SORTING_TYPE.BY_MIN_PURCHASE_AMOUNT,
    order: ORDER_TYPE.ASC,
    name: "by Min. purchase amount ↑",
  },
  {
    type: SORTING_TYPE.BY_MIN_PURCHASE_AMOUNT,
    order: ORDER_TYPE.DESC,
    name: "by Min. purchase amount ↓",
  },
  { type: SORTING_TYPE.BY_AREA, order: ORDER_TYPE.ASC, name: "by Area ↑" },
  { type: SORTING_TYPE.BY_AREA, order: ORDER_TYPE.DESC, name: "by Area ↓" },
  {
    type: SORTING_TYPE.BY_ESTIMATED_INCOME,
    order: ORDER_TYPE.ASC,
    name: "by Estimated income ↑",
  },
  {
    type: SORTING_TYPE.BY_ESTIMATED_INCOME,
    order: ORDER_TYPE.DESC,
    name: "by Estimated income ↓",
  },
];

export const P2P_BUY_SORTING_LIST: ISortingField[] = [
  { type: SORTING_TYPE.BY_TOKEN_PRICE, order: ORDER_TYPE.ASC, name: "by Token price ↑" },
  { type: SORTING_TYPE.BY_TOKEN_PRICE, order: ORDER_TYPE.DESC, name: "by Token price ↓" },
  { type: SORTING_TYPE.BY_AVAILABLE_AREA, order: ORDER_TYPE.ASC, name: "by Available area ↑" },
  { type: SORTING_TYPE.BY_AVAILABLE_AREA, order: ORDER_TYPE.DESC, name: "by Available area ↓" },
  {
    type: SORTING_TYPE.BY_ESTIMATED_INCOME,
    order: ORDER_TYPE.ASC,
    name: "by Estimated income ↑",
  },
  {
    type: SORTING_TYPE.BY_ESTIMATED_INCOME,
    order: ORDER_TYPE.DESC,
    name: "by Estimated income ↓",
  },
];

export const P2P_SELL_SORTING_LIST: ISortingField[] = [
  { type: SORTING_TYPE.BY_TOKEN_PRICE, order: ORDER_TYPE.ASC, name: "by Token price ↑" },
  { type: SORTING_TYPE.BY_TOKEN_PRICE, order: ORDER_TYPE.DESC, name: "by Token price ↓" },
  { type: SORTING_TYPE.BY_AVAILABLE_AREA, order: ORDER_TYPE.ASC, name: "by Available area ↑" },
  { type: SORTING_TYPE.BY_AVAILABLE_AREA, order: ORDER_TYPE.DESC, name: "by Available area ↓" },
];

export const OWNED_PROPERTIES_SORTING_LIST: ISortingField[] = [
  { type: SORTING_TYPE.BY_CURRENT_INCOME, order: ORDER_TYPE.ASC, name: "Sort by current income ↑" },
  {
    type: SORTING_TYPE.BY_CURRENT_INCOME,
    order: ORDER_TYPE.DESC,
    name: "Sort by current income ↓",
  },
  { type: SORTING_TYPE.BY_PURCHASED_AREA, order: ORDER_TYPE.ASC, name: "Sort by purchased area ↑" },
  {
    type: SORTING_TYPE.BY_PURCHASED_AREA,
    order: ORDER_TYPE.DESC,
    name: "Sort by purchased area ↓",
  },
  {
    type: SORTING_TYPE.BY_PURCHASE_AMOUNT,
    order: ORDER_TYPE.ASC,
    name: "Sort by purchase amount ↑",
  },
  {
    type: SORTING_TYPE.BY_PURCHASE_AMOUNT,
    order: ORDER_TYPE.DESC,
    name: "Sort by purchase amount ↓",
  },
  {
    type: SORTING_TYPE.BY_EXPECTED_INCOME,
    order: ORDER_TYPE.ASC,
    name: "Sort by expected income ↑",
  },
  {
    type: SORTING_TYPE.BY_EXPECTED_INCOME,
    order: ORDER_TYPE.DESC,
    name: "Sort by expected income ↓",
  },
];

export const FUNDING_STATUS = [
  { id: CROWDFUNDING_STATUS.DRAFT, text: "Not started" },
  { id: CROWDFUNDING_STATUS.STARTED, text: "Collecting" },
  { id: CROWDFUNDING_STATUS.COMPLETED, text: "Collected" },
  { id: CROWDFUNDING_STATUS.FAILED, text: "Failed" },
];

export const TRANSACTION_TYPE_FILTER = [
  { id: RAW_TRANSACTION_TYPE.BURN_PROPERTY, text: "Burn property" },
  { id: RAW_TRANSACTION_TYPE.BUY_PROPERTY, text: "Buy property" },
  { id: RAW_TRANSACTION_TYPE.CREATE_PROPERTY, text: "Create property" },
  { id: RAW_TRANSACTION_TYPE.DEPOSIT, text: "Deposit" },
  { id: RAW_TRANSACTION_TYPE.PAY_DIVIDENDS, text: "Pay dividends" },
  { id: RAW_TRANSACTION_TYPE.TRANSFER_TOKENS, text: "Transfer tokens" },
];

export const TRANSACTION_STATUS_FILTER = [
  { id: SC_TRANSACTION_STATUS.NOT_STARTED, text: "Not started" },
  { id: SC_TRANSACTION_STATUS.CANCELLED, text: "Cancelled" },
  { id: SC_TRANSACTION_STATUS.COMPLETED, text: "Completed" },
  { id: SC_TRANSACTION_STATUS.FAILED, text: "Failed" },
  { id: SC_TRANSACTION_STATUS.PENDING, text: "Pending" },
];
