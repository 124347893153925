import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ORDER_TYPE } from "src/app/models/filters";
import {
  IPropertyTransaction,
  SC_TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "src/app/models/property";
import { PropertiesService } from "src/app/services/properties.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { Utils } from "src/app/utils/utils";

@Component({
  selector: "app-property-activity-table",
  templateUrl: "./property-activity-table.component.html",
  styleUrls: ["./property-activity-table.component.scss"],
})
export class PropertyActivityTableComponent implements OnInit {
  @Input() propertyId: string;

  TOKEN = TOKEN;
  Utils = Utils;
  pageIndex = 0;
  totalPages = 1;
  dataSource = new MatTableDataSource<IPropertyTransaction>();
  displayedColumns = ["date", "type", "area", "amount"];
  sorting: string[] = [];
  Status = SC_TRANSACTION_STATUS;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private _propertiesService: PropertiesService) {}

  async ngOnInit() {
    const transactions = await this.getPropertyTransactions();
    this.dataSource.data = transactions.items;
    this.totalPages = transactions.pagination.totalPages;
  }

  async getPropertyTransactions() {
    const response = await this._propertiesService.getPropertyTransactions(
      this.propertyId,
      this.pageIndex,
      this.sorting
    );
    return response;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  renderActivityType(type: TRANSACTION_TYPE) {
    switch (type) {
      default:
      case TRANSACTION_TYPE.BUY:
        return "Buy";
      case TRANSACTION_TYPE.SELL:
        return "Sell";
      case TRANSACTION_TYPE.P2P:
        return "P2P";
    }
  }

  getIsLastPage() {
    return this.pageIndex + 1 >= this.totalPages;
  }

  async onLoadMore() {
    if (this.getIsLastPage()) {
      return;
    }

    this.pageIndex++;
    const newItems = await this.getPropertyTransactions();
    this.dataSource.data = [...this.dataSource.data, ...newItems.items];
  }

  async onTableSorting(sort: Sort) {
    const dir =
      sort.direction === "asc"
        ? ORDER_TYPE.ASC
        : sort.direction === "desc"
        ? ORDER_TYPE.DESC
        : null;
    if (!dir) {
      this.sorting = [];
      this.pageIndex = 0;
      const res = await this.getPropertyTransactions();
      this.dataSource.data = res.items;
      return;
    }
    const orderFieldQuery = `order[field]=${sort.active}`;
    const orderDirectionQuery = `order[direction]=${dir}`;
    this.sorting = [orderFieldQuery, orderDirectionQuery];
    this.pageIndex = 0;
    const res = await this.getPropertyTransactions();
    this.dataSource.data = res.items;
  }
}
