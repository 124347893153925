import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[formControlName][appTicker]",
})
export class TickerDirective {
  maxLength = 5;

  constructor(private readonly control: NgControl) {}

  // Do uppercase
  @HostListener("input", ["$event.target"])
  public onInput(input: HTMLInputElement): void {
    const caretPos = input.selectionStart;
    this.control.control.setValue(input.value.toUpperCase());
    input.setSelectionRange(caretPos, caretPos);
  }

  // Prevent more than max length characters
  @HostListener("keydown", ["$event"]) onKeyDown(event: any) {
    const inputValue = this.control.value;
    if (inputValue?.length >= this.maxLength && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
  }
}
