export interface IWalletActivity {
  id: string;
  date: string;
  type: string;
  paymentMethod: string;
  amount: number;
  status: string;
}

export interface ITransaction {
  id: string;
  date: string;
  type: FUNDING_TRANSACTION_TYPE;
  amountAdrd: number;
  amountCurrency: number;
  status: TRANSACTION_STATUS;
  comment: string;
  hash: string;
  currency: string;
}

export interface IWalletBalance {
  balanceRemain: number;
  totalInvested: number;
  balanceFrozen: number;
}

export interface IInvoice {
  recipientBankName: string;
  recipientAccountNumber: string;
  paymentReference: string;
  invoiceLifetime: string;
}

export interface INewDeposit {
  amountAdrd: number;
  amountCurrency: number;
  currency: string;
}

export enum FUNDING_TRANSACTION_TYPE {
  WITHDRAWAL,
  DEPOSIT,
  BONUS,
  REFERRAL_WITHDRAW,
  DIVIDENDS,
}

export enum TRANSACTION_STATUS {
  PENDING,
  COMPLETED,
  CANCELLED,
  ACCEPTED,
  NEW,
}

export const withdrawalCommissionPercent = 1;

export const DEPOSIT_ETH_WALLET = "Oxfd633f604f13d2b23bd3ce2a89524646a35dfe2736cfb2ca97d4b8cc2c64d";

export interface ICryptoCurrency {
  name: string;
  symbol: string;
  priceInEuro: number;
}

export interface ICompleteTransactionBody {
  hash: string;
  comment: string;
}

export interface IAdminFundingTransaction {
  id: string;
  date: string;
  status: TRANSACTION_STATUS;
  type: FUNDING_TRANSACTION_TYPE;
  amountAdrd: number;
  amountCurrency: number;
  comment: string | null;
  hash: string;
  currency: string;
  currencyPrice: string;
  userId: string;
}
