import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { Utils } from "../utils/utils";
import { IResponseWithPagination } from "../models/api-response";
import { IUser, IUserPropertyTransaction, UserListItem } from "../models/account";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  constructor(private _api: BaseRequestService) {}

  getAccounts(page = 0, perPage = 10, filters: string[] = []) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<UserListItem>>(
      `/accounts?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  getAccountById(id: string) {
    return this._api.get<IUser>(`/accounts/${id}`);
  }

  getAccountTransactions(id: string, page: number, perPage: number, filters: string[] = []) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IUserPropertyTransaction>>(
      `/admin/properties/transactions/accounts/${id}?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }
}
