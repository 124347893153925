import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ADMIN_PROPERTY_STATUS,
  IImageData,
  IPropertyInfo,
  IRawProperty,
  PROPERTY_TYPE,
  RENT_TYPE,
  SMART_CONTRACT_STATUS,
} from "src/app/models/property";
import { ILanguageField, LANGUAGE_TAGS } from "src/app/models/settings";
import { environment } from "src/environments/environment";
import { ModalService } from "../../_modal";
import {
  MaxImageSizeMb,
  MaxPropertyFullDescriptionLength,
  MaxPropertyNameLength,
  MaxPropertyShortDescriptionLength,
} from "src/app/constants/validation.constant";
import { ToastService } from "src/app/services/toast.service";

interface IEnumString<E> {
  type: E;
  name: string;
}

@Component({
  selector: "app-admin-property",
  templateUrl: "./admin-property.component.html",
  styleUrls: ["./admin-property.component.scss"],
})
export class AdminPropertyComponent implements OnInit {
  @Input() propertyData: IRawProperty;
  @Input() editable: boolean = true;
  @Input() isImagesUploading: boolean = true;
  @Output() onSubmit = new EventEmitter();

  languagesList = ["English", "Czech"];
  languageTab = 0;
  propertyTypes: IEnumString<PROPERTY_TYPE>[] = [
    { type: PROPERTY_TYPE.FULL, name: "Full" },
    { type: PROPERTY_TYPE.ROOM, name: "Room" },
    { type: PROPERTY_TYPE.CAPSULE, name: "Capsule" },
    { type: PROPERTY_TYPE.HOSTEL, name: "Hostel" },
  ];
  rentTypes: IEnumString<RENT_TYPE>[] = [
    { type: RENT_TYPE.HOUSE, name: "House" },
    { type: RENT_TYPE.FLAT, name: "Flat" },
    { type: RENT_TYPE.TOWNHOUSE, name: "Townhouse" },
    { type: RENT_TYPE.COMMERCIAL, name: "Commercial" },
  ];
  selectedImages: any = [];
  previews: string[] = [];
  serverImages: IImageData[] = [];

  MaxPropertyShortDescriptionLength = MaxPropertyShortDescriptionLength;
  MaxPropertyFullDescriptionLength = MaxPropertyFullDescriptionLength;

  locationForm = new FormGroup({
    country: new FormControl(undefined),
    city: new FormControl(undefined),
    district: new FormControl(undefined),
    address: new FormControl(undefined),
  });

  nameForm = new FormGroup({
    EN: new FormControl(undefined, [
      Validators.required,
      Validators.maxLength(MaxPropertyNameLength),
    ]),
    CZ: new FormControl(undefined, [
      Validators.required,
      Validators.maxLength(MaxPropertyNameLength),
    ]),
  });

  shortDescriptionForm = new FormGroup({
    EN: new FormControl(undefined, [Validators.maxLength(MaxPropertyShortDescriptionLength)]),
    CZ: new FormControl(undefined, [Validators.maxLength(MaxPropertyShortDescriptionLength)]),
  });

  fullDescriptionForm = new FormGroup({
    EN: new FormControl(undefined, [Validators.maxLength(MaxPropertyFullDescriptionLength)]),
    CZ: new FormControl(undefined, [Validators.maxLength(MaxPropertyFullDescriptionLength)]),
  });

  form = new FormGroup({
    name: this.nameForm,
    shortDescription: this.shortDescriptionForm,
    fullDescription: this.fullDescriptionForm,
    propertyType: new FormControl(this.propertyTypes[0].type),
    totalSpace: new FormControl(undefined),
    livingSpace: new FormControl(undefined),
    roomsAmount: new FormControl(undefined),
    rentType: new FormControl(this.rentTypes[0].type),
    address: this.locationForm,
    isVisible: new FormControl(false),
    isP2PEnabled: new FormControl(false),
  });
  activeToggle: boolean = false;

  isCreation = false;

  constructor(
    private _modalService: ModalService,
    private router: Router,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes("create")) {
      this.isCreation = true;
      this.isImagesUploading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propertyData?.currentValue) {
      const data = changes.propertyData.currentValue;
      this.distributeValuesToForm(data);
      this.serverImages = data.images;
      this.disableFormFields(data);
      this.isImagesUploading = false;
      this.setValidatorsToForm(data);
      this.previews = [];
      this.selectedImages = [];
    }

    if (changes.editable?.currentValue === true) {
      this.form.enable();
      this.disableFormFields(this.propertyData);
      this.setValidatorsToForm(this.propertyData);
    } else if (changes.editable?.currentValue === false) {
      this.form.disable();
    }

    if (changes.isImagesUploading?.currentValue) {
      this.isImagesUploading = changes.isImagesUploading.currentValue;
    }
  }

  getImageUrl(path: string) {
    return `${environment.serverUrl}/${path}`;
  }

  disableFormFields(data: IRawProperty) {
    if (!data) {
      return;
    }
    if (data.token && data.token.scStatus !== SMART_CONTRACT_STATUS.NOT_STARTED) {
      this.form.controls.totalSpace.disable();
    }
    if (data.status === ADMIN_PROPERTY_STATUS.DRAFT) {
      this.form.controls.isVisible.disable();
    }
    if (data.status !== ADMIN_PROPERTY_STATUS.COMPLETED) {
      this.form.controls.isP2PEnabled.disable();
    }
  }

  distributeValuesToForm(data: IPropertyInfo) {
    this.form.patchValue(data);

    this.form.controls.name.setValue(this.prepareLanguageFieldsForForm(data.name));
    this.form.controls.shortDescription.setValue(
      this.prepareLanguageFieldsForForm(data.shortDescription)
    );
    this.form.controls.fullDescription.setValue(
      this.prepareLanguageFieldsForForm(data.fullDescription)
    );

    this.activeToggle = data.isVisible;
  }

  setValidatorsToForm(data: IPropertyInfo) {
    if ((data && data.status !== ADMIN_PROPERTY_STATUS.DRAFT) || this.isCreation) {
      Object.keys(this.locationForm.controls).forEach(key => {
        this.locationForm.controls[key].setValidators(Validators.required);
      });
      Object.keys(this.shortDescriptionForm.controls).forEach(key => {
        this.shortDescriptionForm.controls[key].addValidators(Validators.required);
      });
      Object.keys(this.fullDescriptionForm.controls).forEach(key => {
        this.fullDescriptionForm.controls[key].addValidators(Validators.required);
      });
      this.form.controls.totalSpace.setValidators(Validators.required);
      this.form.controls.livingSpace.setValidators(Validators.required);
      this.form.controls.roomsAmount.setValidators(Validators.required);
    }
    this.form.updateValueAndValidity();
  }

  onChangeTabLanguage(tab: number) {
    this.languageTab = tab;
  }

  onFileChange(event: any) {
    const newFiles: File[] = Array.from(event.target.files);

    const isValidSizes = newFiles.every(file => {
      const fileSize = file.size;
      const fileMb = fileSize / 1024 ** 2;
      return fileMb < MaxImageSizeMb;
    });

    if (!isValidSizes) {
      this._toastService.open(`The image size must be less than ${MaxImageSizeMb}mb`, "error");
      return;
    }

    this.selectedImages = [...this.selectedImages, ...newFiles];

    if (this.selectedImages && this.selectedImages[0]) {
      this.previews = [];
      const numberOfFiles = this.selectedImages.length;

      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedImages[i]);
      }
    }
  }

  deleteLocalImage(idx: number) {
    this.previews.splice(idx, 1);
    this.selectedImages.splice(idx, 1);
  }

  deleteServerImage(image: IImageData) {
    this.serverImages = this.serverImages.filter(img => img !== image);
  }

  getTypesList(data: IEnumString<PROPERTY_TYPE | RENT_TYPE>[]): string[] {
    return data.map(x => x.name);
  }

  getNameFromType(data: IEnumString<PROPERTY_TYPE | RENT_TYPE>[], type: number): string {
    return data.find(x => x.type === type).name;
  }

  setType(
    name: string,
    data: IEnumString<PROPERTY_TYPE | RENT_TYPE>[],
    control: AbstractControl
  ): void {
    const found = data.find(x => x.name === name);
    control.setValue(found.type);
  }

  submit() {
    if (
      this.form.controls.name.invalid
      // ||
      // this.form.controls.shortDescription.invalid ||
      // this.form.controls.fullDescription.invalid
    ) {
      this.openNSLModal();
      return;
    }

    const newData = this.createObjectToSend(this.form.value);
    this.onSubmit.emit(newData);
  }

  createObjectToSend(data: any) {
    const dataToSend: IPropertyInfo = { ...data };
    dataToSend.name = this.convertLanguageFieldsToArray(data.name);
    dataToSend.shortDescription = this.convertLanguageFieldsToArray(data.shortDescription);
    dataToSend.fullDescription = this.convertLanguageFieldsToArray(data.fullDescription);
    const totalSpace = data.totalSpace ? +data.totalSpace : 0;
    dataToSend.totalSpace = totalSpace ?? this.propertyData?.totalSpace;
    dataToSend.livingSpace = +dataToSend.livingSpace;
    dataToSend.roomsAmount = +dataToSend.roomsAmount;
    dataToSend.images = this.selectedImages;
    dataToSend.imagesIds = this.serverImages.map(x => x.id);
    dataToSend.isP2PEnabled = data.isP2PEnabled ?? false;
    dataToSend.isVisible = data.isVisible ?? false;

    if (this.isCreation) {
      delete dataToSend.isVisible;
      delete dataToSend.isP2PEnabled;
    }

    return dataToSend;
  }

  openNSLModal() {
    this._modalService.open("noSecondLangModal");
  }

  closeNSLModal() {
    this._modalService.close("noSecondLangModal");
  }

  handleActiveToggle() {
    if (this.activeToggle) {
      this.openActivatePropertyModal();
      this.form.controls.isVisible.setValue(true);
    } else {
      this.form.controls.isVisible.setValue(false);
    }
  }

  openActivatePropertyModal() {
    this._modalService.open("activate-property-modal");
  }
  closeActivatePropertyModal() {
    this._modalService.close("activate-property-modal");
  }

  deactivateAndCloseModal() {
    this.activeToggle = false;
    this.form.controls.isVisible.setValue(false);
    this.closeActivatePropertyModal();
  }

  private prepareLanguageFieldsForForm(languageField: ILanguageField[]) {
    let obj = {};
    languageField.forEach(el => {
      const newField = { [el.language]: el.title };
      obj = { ...obj, ...newField };
    });
    return obj;
  }

  private convertLanguageFieldsToArray(formField: any) {
    const res = [];

    for (const field in formField) {
      const languageField: ILanguageField = {
        language: field as LANGUAGE_TAGS,
        title: formField[field],
      };

      res.push(languageField);
    }
    return res;
  }
}
