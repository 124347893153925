import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewChildren,
} from "@angular/core";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements AfterViewInit {
  @HostListener("window:click", ["$event"])
  handleClick(event: KeyboardEvent) {
    this.hideOnClick(event);
  }

  @Input() items: string[];
  @Input() selectedItem?: string;
  @Input() img: string;
  @Input() caption?: string;
  @Input() disabled?: boolean;

  @Output() pick = new EventEmitter<string>();

  @ViewChild("customDropdown") dropdown!: ElementRef;

  isOpen = false;
  openDirection: "up" | "down" = "down";

  constructor() {}

  ngAfterViewInit() {
    // This is a hack to make sure that the dropdown is rendered before we try to get its size
    setTimeout(() => {
      const dropdownSizes = this.dropdown.nativeElement.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownSizes.bottom;
      const itemHeight = dropdownSizes.height; // Attention! Select height is taken but it's more correct to take the height of one element from the list
      const dropdownHeight = itemHeight * this.items.length;

      if (spaceBelow < dropdownHeight) {
        this.openDirection = "up";
      } else {
        this.openDirection = "down";
      }
    });
  }

  toggleSelect() {
    this.isOpen = !this.isOpen;
  }

  pickItem(item: string) {
    this.selectedItem = item;
    this.pick.emit(this.selectedItem);
  }

  hideOnClick(e: any) {
    if (
      !e.target.classList.contains("dropdown") &&
      !e.target.parentNode?.classList.contains("dropdown")
    ) {
      this.isOpen = false;
    }
  }

  displaySelectedItem() {
    if (this.selectedItem) {
      return this.selectedItem;
    } else {
      return this.items[0];
    }
  }
}
