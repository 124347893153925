<div class="payFilters">
  <app-input
    type="search"
    placeholder="Search by name or ticker"
    icon="search"
    [(ngModel)]="searchField"
    (onSearchClick)="applyPayoutsFilters()"
    (keyup.enter)="applyPayoutsFilters()"
  ></app-input>

  <app-switch
    caption="Payable today"
    [(ngModel)]="payoutsIsPaymentToday"
    (onSwitch)="applyPayoutsFilters()"
  ></app-switch>
</div>

<div class="payTable">
  <table
    mat-table
    [dataSource]="payouts"
    matSort
    (matSortChange)="onPayoutsSort($event)"
    [matSortActive]="payoutsColumns[4]"
    matSortDirection="desc"
    class="matTable"
  >
    <ng-container [matColumnDef]="payoutsColumns[0]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
      <td mat-cell *matCellDef="let prop">
        {{ prop.token.ticker }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="payoutsColumns[1]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Object</th>
      <td mat-cell *matCellDef="let prop">{{ prop.property.name }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutsColumns[2]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Investors</th>
      <td mat-cell *matCellDef="let prop">{{ prop.sharedCount | prettifyNumber }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutsColumns[3]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Profit amount</th>
      <td mat-cell *matCellDef="let prop">{{ prop.euroAmount | prettifyNumber }} EUR</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutsColumns[4]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment date</th>
      <td mat-cell *matCellDef="let prop">{{ prop.paymentDate | prettifyDate }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutsColumns[5]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let prop" class="payTable__status">{{ renderStatus(prop) }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="payoutsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: payoutsColumns" (click)="openPayPage(row)"></tr>
  </table>

  <mat-paginator
    [pageIndex]="payoutsPage"
    [pageSize]="payoutsPageSize"
    [length]="payoutsTotal"
    (page)="onPayoutsPageChange($event)"
    aria-label="Select page"
  ></mat-paginator>
</div>
