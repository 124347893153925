import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IInvestInfo, RENTAL_DEMAND } from "src/app/models/property";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  selector: "app-invest-info-tab",
  templateUrl: "./invest-info-tab.component.html",
  styleUrls: ["./invest-info-tab.component.scss"],
})
export class InvestInfoTabComponent implements OnInit {
  @Input() editable: boolean;
  @Input() investData: IInvestInfo;
  @Input() scIsCreated: boolean = false;
  @Output() onSubmit = new EventEmitter();

  rentalDemand = [
    { type: RENTAL_DEMAND.AVERAGE, name: "Average" },
    { type: RENTAL_DEMAND.HIGH, name: "High" },
  ];
  withoutEndDate = false;
  TOKEN = TOKEN;

  form = new FormGroup({});
  tokenPice: number;
  priceInEuro: number;

  constructor(private fb: FormBuilder, private _walletService: WalletService) {
    this.form = this.fb.group({
      totalPrice: [undefined, [Validators.min(1)]], // ADRD
      profitability: [undefined, [Validators.min(0), Validators.max(100)]],
      minInvestAmount: [undefined, [Validators.min(1)]],
      demand: [this.rentalDemand[0].type],
      investStartAt: [null],
      investEndAt: [null],
    });
  }

  ngOnInit(): void {
    this._walletService.appTokenPrice$.subscribe(price => {
      this.tokenPice = price;
    });

    this.form.controls.totalPrice.valueChanges.subscribe(value => {
      const calculated = +(value * this.tokenPice).toFixed(2);
      this.priceInEuro = calculated;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.investData?.currentValue) {
      const data = changes.investData.currentValue;
      this.distributeValuesToForm(data);
    }

    if (changes.editable?.currentValue === true) {
      this.form.enable();
      if (this.scIsCreated) {
        this.form.controls.totalPrice.disable();
      }
    } else if (changes.editable?.currentValue === false) {
      this.form.disable();
    }

    if (changes.scIsCreated?.currentValue) {
      const scCreated = changes.scIsCreated.currentValue;
      if (scCreated) {
        this.form.controls.totalPrice.disable();
        this.setFormValidators();
      }
    }
  }

  distributeValuesToForm(data: IInvestInfo) {
    this.form.patchValue(data);

    if (!data.investEndAt) {
      this.withoutEndDate = true;
      this.form.controls.investEndAt.clearValidators();
      this.form.controls.investEndAt.disable();
    }
    if (!data.demand) {
      this.form.controls.demand.setValue(this.rentalDemand[0].type);
    }
  }

  setFormValidators() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].addValidators(Validators.required);
    });
    if (!this.form.controls.investEndAt.value) {
      this.form.controls.investEndAt.clearValidators();
      this.form.controls.investEndAt.disable();
    }
    this.form.updateValueAndValidity();
  }

  getRentalDemandList() {
    return this.rentalDemand.map(x => x.name);
  }
  setRentalDemand(demand: string) {
    const foundedDemand = this.rentalDemand.find(x => x.name === demand);
    this.form.controls.demand.setValue(foundedDemand.type);
  }
  returnDemandName() {
    return this.rentalDemand.find(x => x.type === this.form.value.demand)?.name;
  }

  onToggleIndefinite(checked: boolean) {
    if (checked) {
      this.form.controls.investEndAt.disable();
    } else {
      this.form.controls.investEndAt.addValidators(Validators.required);
      this.form.controls.investEndAt.enable();
    }
  }

  submit() {
    if (this.form.invalid) return;

    const dataToSend: IInvestInfo = { ...this.form.value };

    const totalPrice = dataToSend.totalPrice ? Number(dataToSend.totalPrice) : null;
    dataToSend.totalPrice = totalPrice ?? this.investData.totalPrice;

    dataToSend.profitability = dataToSend.profitability ? Number(dataToSend.profitability) : null;

    dataToSend.minInvestAmount = dataToSend.minInvestAmount
      ? Number(dataToSend.minInvestAmount)
      : null;

    if (this.withoutEndDate) {
      dataToSend.investEndAt = null;
    }

    this.onSubmit.emit(dataToSend);
  }

  convertEuroToToken() {
    if (!this.priceInEuro) {
      this.form.controls.totalPrice.setValue(null);
      return;
    }

    const calculated = +(this.priceInEuro / this.tokenPice).toFixed(2);
    this.form.controls.totalPrice.setValue(calculated);
  }
}
