<section class="up" *ngIf="userData; else loader">
  <div class="up__header">
    <button class="backArrowButton" (click)="goBack()"></button>
    <h2 class="title">{{ userData.email }}</h2>
    <div class="up__id">
      {{ userData.id }} <app-copy-button variant="outlined" [value]="userData.id"></app-copy-button>
    </div>
  </div>

  <div class="up__infoWrapper">
    <div class="up__info">
      <h4 class="title title_sub">Personal data</h4>
      <div class="up__infoFields">
        <p>
          Name
          <span>{{ userData.identity.firstName + " " + userData.identity.secondName }}</span>
        </p>
        <p>
          Verification status
          <span>{{ renderVerificationStatus(userData.sumsub.verificationStatus) }}</span>
        </p>
        <p>
          Registration date <span>{{ userData.createdAt | prettifyDate }}</span>
        </p>
        <p>
          Country <span>{{ renderCountryName(userData.identity.country) ?? "-" }}</span>
        </p>
        <p>
          City <span>{{ userData.identity.city ?? "-" }}</span>
        </p>
        <p>
          Address <span>{{ userData.identity.street ?? "-" }}</span>
        </p>
        <p>
          Email <a [href]="'mailto:' + userData.email">{{ userData.email }}</a>
        </p>
        <p>
          Phone
          <a
            *ngIf="userData.identity.phoneNumber; else dash"
            [href]="'tel:' + userData.identity.phoneNumber"
            >{{ userData.identity.phoneNumber }}</a
          >
          <ng-template #dash>
            <span>-</span>
          </ng-template>
        </p>
      </div>
    </div>
    <div class="up__info">
      <h4 class="title title_sub">Financial information</h4>
      <div class="up__infoFields">
        <p>
          First deposit date
          <span>{{
            userData.depositFirstDate ? (userData.depositFirstDate | prettifyDate) : "-"
          }}</span>
        </p>
        <p>
          Wallet balance <span>{{ userData.wallet.balance | prettifyNumber }} {{ TOKEN }}</span>
        </p>
        <p>
          In investment <span>{{ userData.totalInvested | prettifyNumber }} {{ TOKEN }}</span>
        </p>
        <p>
          Portfolio balance, total
          <span>{{ userData.totalBalance | prettifyNumber }} {{ TOKEN }}</span>
        </p>
        <p>
          Purchased objects <span>{{ userData.propertiesCount | prettifyNumber }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="up__info">
    <h4 class="title title_sub">Transactions</h4>
    <app-input
      type="search"
      icon="search"
      placeholder="Search by ID, property"
      [(ngModel)]="searchField"
      (onSearchClick)="applyTransactionsFilters()"
      (keyup.enter)="applyTransactionsFilters()"
    ></app-input>
    <div *ngIf="userTrans.data.length > 0; else noTrans">
      <table
        mat-table
        [dataSource]="userTrans"
        matSort
        (matSortChange)="onTransSort($event)"
        class="matTable"
      >
        <ng-container [matColumnDef]="userTransColumns[0]">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let prop" [title]="prop.id">
            {{ prop.id | shortenText }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[1]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Request type</th>
          <td mat-cell *matCellDef="let prop">{{ renderTransactionType(prop.type) }}</td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[2]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let prop" [title]="prop.createdAt | prettifyDate : 'full'">
            {{ prop.createdAt | prettifyDate }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[3]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Token</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.property.token.ticker }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[4]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.tokensAmount | prettifyNumber }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[5]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total, {{ TOKEN }}</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.adrdAmount | prettifyNumber }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="userTransColumns[6]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Property</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.property.name }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="userTransColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userTransColumns"></tr>
      </table>

      <mat-paginator
        [pageIndex]="userTransPage"
        [pageSize]="userTransPageSize"
        [length]="userTransTotal"
        (page)="onTransPageChange($event)"
        aria-label="Select page"
      ></mat-paginator>
    </div>

    <ng-template #noTrans>
      <p class="up__noTrans">No transactions</p>
    </ng-template>
  </div>
</section>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
