<div class="customSwitch" [ngClass]="{ customSwitch_disabled: disabled }">
  <label class="customSwitch__box">
    <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onModelChange($event)"
      [disabled]="disabled"
    />
    <span class="toggler"></span>
  </label>

  <span class="customSwitch__caption" [innerHTML]="caption"></span>
</div>
