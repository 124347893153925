import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import {
  IAdminPropertiesRangeFiltersResponse,
  IAdminPropertyItem,
  IDetailedProperty,
  IImageData,
  IOwnedPropertyAmount,
  IPortfolioResponse,
  IProperty,
  IPropertyRangeFiltersResponse,
  IPropertyTransaction,
  IRawProperty,
} from "src/app/models/property";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { IAPIResponse, IResponseWithPagination } from "src/app/models/api-response";
import { Utils } from "src/app/utils/utils";

@Injectable({
  providedIn: "root",
})
export class PropertiesService {
  constructor(private _api: BaseRequestService, private _http: HttpClient) {}

  async getPropertiesList(page: number = 0, filters?: string[], perPage: number = 9) {
    const joinedFilters = filters?.length > 0 ? "&" + filters.join("&") : "";
    const response = await this._api.get<IResponseWithPagination<IProperty>>(
      `/properties?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
    return response;
  }

  getPropertyRanges() {
    return this._api.get<IPropertyRangeFiltersResponse>("/properties/filter-ranges");
  }

  async getUserProperties(page: number, filters: string[] = []) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    const response = await this._api.get<IPortfolioResponse>(
      `/properties/owned?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
    return response;
  }

  getPropertyById(id: string) {
    return this._api.get<IDetailedProperty>(`/properties/${id}`);
  }

  getPropertyTransactions(propertyId: string, page: number, filters: string[] = []) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IPropertyTransaction>>(
      `/properties/${propertyId}/transactions?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
  }

  async buyPropertyPart(id: string, amount: number) {
    try {
      const response = await this._api.post<void>(`/properties/${id}/buy`, { amount });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMyTransactions(page: number, filters: string[] = []) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    const response = await this._api.get<IResponseWithPagination<IPropertyTransaction>>(
      `/properties/transactions?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
    return response;
  }

  async createProperty(newProperty: FormData) {
    const accessToken = JSON.parse(localStorage.getItem("ardr-tokens")).accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await this._http
      .post(`${environment.serverUrl}/admin/properties`, newProperty, { headers: headers })
      .toPromise();
    return response;
  }

  async editPropertyById(id: string, newPropertyInfo: IRawProperty) {
    const response = await this._api.put(`/admin/properties/${id}`, { ...newPropertyInfo });
    return response;
  }

  getRawPropertyById(id: string) {
    const response = this._api.get<IRawProperty>(`/admin/properties/${id}`);
    return response;
  }

  publishSmartContract(id: string) {
    const response = this._api.post(`/admin/properties/${id}/smart-contract`, {});
    return response;
  }

  publishProperty(id: string) {
    const response = this._api.post(`/admin/properties/${id}/publish`, {});
    return response;
  }

  deleteProperty(id: string) {
    return this._api.delete(`/admin/properties/${id}`);
  }

  getAdminPropertiesList(page: number = 0, filters?: string[], perPage: number = 10) {
    const joinedFilters = filters?.length > 0 ? "&" + filters.join("&") : "";
    return this._api.get<IResponseWithPagination<IAdminPropertyItem>>(
      `/admin/properties?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  getAdminPropertyRanges() {
    return this._api.get<IAdminPropertiesRangeFiltersResponse>("/admin/properties/filter-ranges");
  }

  async addImagesToProperty(propertyId: string, images: FormData) {
    const accessToken = JSON.parse(localStorage.getItem("ardr-tokens")).accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await this._http
      .post<IAPIResponse<IImageData[]>>(
        `${environment.serverUrl}/admin/properties/${propertyId}/images`,
        images,
        {
          headers: headers,
        }
      )
      .toPromise();
    return response;
  }

  getPropertiesForMonetization() {
    return this._api.get<IAdminPropertyItem[]>(`/admin/properties/available/dividends`);
  }

  getAmountOfOwnedProperty(propertyId: string) {
    return this._api.get<IOwnedPropertyAmount>(`/properties/owned/${propertyId}/amount`);
  }
}
