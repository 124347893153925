<form class="bsForm" [formGroup]="form" (ngSubmit)="handleTransaction()" [ngSwitch]="currentStep">
  <h3 class="title title_sub">{{ renderTitle() }}</h3>

  <ng-template ngSwitchCase="1">
    <app-tokens-area-input
      [minTokens]="minTokens"
      [maxTokens]="maxTokens"
      [tokenName]="tokenName"
      [minSqm]="minSqm"
      [maxSqm]="maxSqm"
      formControlName="tokens"
      [errorTokens]="renderTokensError()"
    ></app-tokens-area-input>

    <div class="bsForm__info">
      <p class="bsForm__infoItem">
        {{ type === "buy" ? ("BUY_PROPERTY.YOU_PAY" | translate) : "" }}
        {{ type === "sell" ? ("SELL_PROPERTY.YOU_RECEIVE" | translate) : "" }}
        <span>{{ valueInTokens | prettifyNumber }} {{ TOKEN }}</span>
      </p>
    </div>
    <div class="bsForm__buttonsWrapper">
      <button type="button" class="button button_outline" (click)="cancelForm()">
        {{ "BUY_PROPERTY.CANCEL" | translate }}
      </button>
      <button type="button" class="button" (click)="checkFunds()" [disabled]="form.invalid">
        {{ "BUY_PROPERTY.PROCEED" | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="2">
    <div class="bsForm__info">
      <div class="bsForm__infoPaymentWrapper">
        <p class="bsForm__infoItem">
          {{ type === "buy" ? ("BUY_PROPERTY.YOU_BUY" | translate) : "" }}
          {{ type === "sell" ? ("SELL_PROPERTY.YOU_SELL" | translate) : "" }}
          <span>
            {{ form.value.tokens | prettifyNumber }} {{ tokenName }} /
            {{ renderSqmFromTokens() | prettifyNumber }} m&#xB2;
          </span>
        </p>
        <p class="bsForm__infoItem">
          {{ type === "buy" ? ("BUY_PROPERTY.YOU_PAY" | translate) : "" }}
          {{ type === "sell" ? ("SELL_PROPERTY.YOU_RECEIVE" | translate) : "" }}
          <span>{{ valueInTokens | prettifyNumber }} {{ TOKEN }}</span>
        </p>
      </div>
    </div>
    <div class="bsForm__buttonsWrapper">
      <button type="button" class="button button_outline" (click)="prevStep()">
        {{ "BUY_PROPERTY.BACK" | translate }}
      </button>
      <button type="submit" class="button" [disabled]="isPending">
        {{ type === "buy" ? ("BUY_PROPERTY.PROCEED" | translate) : "" }}
        {{ type === "sell" ? ("SELL_PROPERTY.CONFIRM_AND_SELL" | translate) : "" }}
      </button>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="3">
    <div>Loader...</div>
    <button class="button" (click)="nextStep()">TEMP: Next step</button>
  </ng-template>

  <ng-template ngSwitchCase="4">
    <div class="bsForm__info">
      <div class="bsForm__infoPaymentWrapper">
        <p class="bsForm__infoItem">
          {{ type === "buy" ? ("BUY_PROPERTY.YOU_BOUGHT" | translate) : "" }}
          {{ type === "sell" ? ("SELL_PROPERTY.YOU_SOLD" | translate) : "" }}
          <span>
            {{ form.value.tokens | prettifyNumber }} {{ tokenName }} /
            {{ renderSqmFromTokens() | prettifyNumber }} m&#xB2;
          </span>
        </p>
        <p class="bsForm__infoItem">
          {{ type === "buy" ? ("BUY_PROPERTY.YOU_PAID" | translate) : "" }}
          {{ type === "sell" ? ("SELL_PROPERTY.YOU_RECEIVED" | translate) : "" }}
          <span>{{ valueInTokens | prettifyNumber }} {{ TOKEN }}</span>
        </p>
      </div>
    </div>

    <button
      *ngIf="usedIn === 'property'; else p2pSuccessButtons"
      type="button"
      class="button"
      routerLink="/dashboard/portfolio"
    >
      {{ "BUY_PROPERTY.GO_TO_DASHBOARD" | translate }}
    </button>

    <ng-template #p2pSuccessButtons>
      <div class="bsForm__buttonsWrapper">
        <button class="button button_outline" routerLink="/dashboard/portfolio">
          {{ "BUY_PROPERTY.GO_TO_DASHBOARD" | translate }}
        </button>
        <button class="button" routerLink="/dashboard/p2p">
          {{ type === "buy" ? ("BUY_PROPERTY.BUY_MORE_ON_P2P" | translate) : "" }}
          {{ type === "sell" ? ("SELL_PROPERTY.SELL_MORE_ON_P2P" | translate) : "" }}
        </button>
      </div>
    </ng-template>
  </ng-template>
</form>

<jw-modal id="insuf-funds-modal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">{{ "BUY_PROPERTY.DEPOSIT_TITLE" | translate }}</h4>
      <button (click)="closeInsufficientFundsModal()">&#10006;</button>
    </div>
    <p class="infoModal__text">
      {{ "BUY_PROPERTY.DEPOSIT_TEXT" | translate : { amount: renderInsufficientAmount() } }}
    </p>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closeInsufficientFundsModal()">
        {{ "BUY_PROPERTY.ANOTHER_AMOUNT" | translate }}
      </button>
      <button class="button" (click)="openDepositModal()">
        {{ "WALLET.DEPOSIT" | translate }}
      </button>
    </div>
  </div>
</jw-modal>
