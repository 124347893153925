import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IVerificationStatus } from "../models/settings";
import { BaseRequestService } from "./base-request.service";

export enum SUMSUB_STATUS {
  REQUIRED,
  FAILED,
  COMPLETE,
  PENDING,
  RETRY,
}

@Injectable({
  providedIn: "root",
})
export class SumsubService {
  verificationStatus = SUMSUB_STATUS.REQUIRED;
  verificationStatusChange = new Subject<SUMSUB_STATUS>();

  constructor(private _api: BaseRequestService) {
    this.verificationStatusChange.subscribe(value => {
      this.verificationStatus = value;
    });
  }

  async getNewAccessToken() {
    try {
      const res = await this._api.get<string>("/sumsub/access-token");
      return res;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getVerificationStatus() {
    const res = await this._api.get<IVerificationStatus>("/sumsub/status");
    this.verificationStatusChange.next(res.verificationStatus);
    return res;
  }
}
