<main class="buy-property">
  <app-main-header
    [headline]="'BUY_PROPERTY.TITLE' | translate"
    [subtitle]="'BUY_PROPERTY.SUBTITLE' | translate"
  ></app-main-header>

  <app-loader *ngIf="!propertyData"></app-loader>

  <div class="buy-property__wrapper" *ngIf="propertyData">
    <div class="box">
      <app-buy-sell-form
        type="buy"
        usedIn="property"
        [propertyData]="propertyData"
        (onCancel)="goBack()"
      ></app-buy-sell-form>
    </div>

    <app-property-card
      [data]="convertDataForCard(propertyData)"
      cardType="property"
    ></app-property-card>
  </div>
</main>
