import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import SwiperCore, { Navigation } from "swiper";
import { environment } from "src/environments/environment";
import { Utils } from "src/app/utils/utils";
import { TOKEN } from "src/app/constants/token-name.constant";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from "dayjs";
import { PropertiesService } from "src/app/services/properties.service";
import { IDetailedProperty, PROPERTY_TYPE, RENT_TYPE } from "src/app/models/property";

SwiperCore.use([Navigation]);
@Component({
  templateUrl: "./property.component.html",
  styleUrls: ["./property.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyComponent implements OnInit {
  Utils = Utils;
  TOKEN = TOKEN;
  baseUrl = environment.serverUrl + "/";
  percentage = 0;
  id: string;

  propertyData: IDetailedProperty;

  isCollecting = true;
  isUserInvested = false;
  isP2PAvailable = false;
  isFutureFundraising = false;

  constructor(
    private _propertyService: PropertiesService,
    private _activatedRoute: ActivatedRoute,
    private _translate: TranslateService
  ) {}

  async ngOnInit() {
    this.id = this._activatedRoute.snapshot.params.id;
    this.propertyData = await this._propertyService.getPropertyById(this.id);
    this.isCollecting = this.propertyData.canBeBought;
    this.isUserInvested = this.propertyData.investInfo.invested > 0;
    this.isP2PAvailable = this.propertyData.isP2PEnabled;
    this.isFutureFundraising =
      !this.isCollecting && dayjs().isBefore(dayjs(this.propertyData.fundraisingStartDate));
    this.calculatePercentage();
  }

  calculatePercentage() {
    this.percentage = +Utils.calculatePercentage(
      this.propertyData?.investInfo.totalPrice,
      this.propertyData?.investInfo.collected
    );
  }

  displayAddress() {
    return `${this.propertyData?.address.address}, ${this.propertyData?.address.district}, ${this.propertyData?.address.city}, ${this.propertyData?.address.country}`;
  }

  renderInvestedAmount() {
    const amount = this.propertyData.investInfo.invested / this.propertyData.token.squarePerToken;
    return amount;
  }

  getObjectTypeName(type: PROPERTY_TYPE) {
    switch (type) {
      case PROPERTY_TYPE.FULL:
        return this._translate.instant("PROPERTY_TYPE.FULL");
      case PROPERTY_TYPE.CAPSULE:
        return this._translate.instant("PROPERTY_TYPE.CAPSULE");
      case PROPERTY_TYPE.HOSTEL:
        return this._translate.instant("PROPERTY_TYPE.HOSTEL");
      case PROPERTY_TYPE.ROOM:
        return this._translate.instant("PROPERTY_TYPE.ROOM");
    }
  }

  getRentTypeName(type: RENT_TYPE) {
    switch (type) {
      case RENT_TYPE.HOUSE:
        return this._translate.instant("RENT_TYPE.HOUSE");
      case RENT_TYPE.COMMERCIAL:
        return this._translate.instant("RENT_TYPE.COMMERCIAL");
      case RENT_TYPE.FLAT:
        return this._translate.instant("RENT_TYPE.FLAT");
      case RENT_TYPE.TOWNHOUSE:
        return this._translate.instant("RENT_TYPE.TOWNHOUSE");
    }
  }

  areaToTokens(area: number) {
    return area / this.propertyData.token.squarePerToken;
  }
}
