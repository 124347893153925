import { CountryISO } from "ngx-intl-tel-input";
import { SUMSUB_STATUS } from "../services/sumsub.service";

export enum LANGUAGE_TAGS {
  ENGLISH = "EN",
  CZECH = "CZ",
}

export interface IUserSettings {
  language: LANGUAGE_TAGS;
  currency: string;

  [index: string]: any;
}

export const languages: ILanguage[] = [
  {
    tag: LANGUAGE_TAGS.ENGLISH,
    language: "English",
    icon: "../../assets/icons/eng.svg",
  },
  {
    tag: LANGUAGE_TAGS.CZECH,
    language: "Czech",
    icon: "../../assets/icons/cz.svg",
  },
  // {
  //   tag: "ru",
  //   language: "Russian",
  // },
];

export interface ILanguage {
  tag: LANGUAGE_TAGS;
  language: string;
  icon?: string;
}

export interface ILanguageField {
  language: LANGUAGE_TAGS;
  title: string;
}

export interface IUserIdentity {
  phoneNumber: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  firstName: string;
  secondName: string;
  birthDate: Date;
  referralCode?: string;
}

export interface IVerificationStatus {
  verificationStatus: SUMSUB_STATUS;
}

export const COUNTRIES_LIST = [
  { name: "Austria", iso: CountryISO.Austria },
  { name: "Belgium", iso: CountryISO.Belgium },
  { name: "Bulgaria", iso: CountryISO.Bulgaria },
  { name: "Cyprus", iso: CountryISO.Cyprus },
  { name: "Croatia", iso: CountryISO.Croatia },
  { name: "Czech Republic", iso: CountryISO.CzechRepublic },
  { name: "Denmark", iso: CountryISO.Denmark },
  { name: "Estonia", iso: CountryISO.Estonia },
  { name: "Finland", iso: CountryISO.Finland },
  { name: "France", iso: CountryISO.France },
  { name: "Germany", iso: CountryISO.Germany },
  { name: "Greece", iso: CountryISO.Greece },
  { name: "Hungary", iso: CountryISO.Hungary },
  { name: "Ireland", iso: CountryISO.Ireland },
  { name: "Italy", iso: CountryISO.Italy },
  { name: "Latvia", iso: CountryISO.Latvia },
  { name: "Lithuania", iso: CountryISO.Lithuania },
  { name: "Luxembourg", iso: CountryISO.Luxembourg },
  { name: "Malta", iso: CountryISO.Malta },
  { name: "Netherlands", iso: CountryISO.Netherlands },
  { name: "Poland", iso: CountryISO.Poland },
  { name: "Portugal", iso: CountryISO.Portugal },
  { name: "Romania", iso: CountryISO.Romania },
  { name: "Slovakia", iso: CountryISO.Slovakia },
  { name: "Slovenia", iso: CountryISO.Slovenia },
  { name: "Spain", iso: CountryISO.Spain },
  { name: "Sweden", iso: CountryISO.Sweden },
  { name: "United Kingdom", iso: CountryISO.UnitedKingdom },
];
