import { Component, OnInit } from "@angular/core";

import { AuthService } from "src/app/services/auth.service";
import {
  COUNTRIES_LIST,
  IUserIdentity,
  IUserSettings,
  LANGUAGE_TAGS,
} from "src/app/models/settings";
import { SettingsService } from "src/app/services/settings.service";
import { languages } from "src/app/models/settings";
import { ModalService } from "src/app/components/_modal";
import { Router } from "@angular/router";
import { SumsubService, SUMSUB_STATUS } from "src/app/services/sumsub.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "src/app/services/toast.service";
import { DEPOSIT_ETH_WALLET } from "src/app/models/wallet";

@Component({
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  tabs = ["SETTINGS.USER_SETTINGS", "SETTINGS.PERSONAL", "SETTINGS.LEGAL"];
  activeTab = 0;

  languages = languages;

  userSettings: IUserSettings;
  tempParams: IUserSettings;

  currencies = ["EUR"];
  userIdentity: IUserIdentity;
  VerificationType = SUMSUB_STATUS;
  verificationStatus = SUMSUB_STATUS.REQUIRED;
  userEmail = this._authService.accountData.email;
  countriesList = COUNTRIES_LIST;
  depositEthWallet = DEPOSIT_ETH_WALLET;

  changeAddressForm: FormGroup;

  constructor(
    private _settingsService: SettingsService,
    private _authService: AuthService,
    private _modalService: ModalService,
    private _router: Router,
    private _sumsubService: SumsubService,
    private _formBuilder: FormBuilder,
    private _toastService: ToastService
  ) {}

  ngOnInit() {
    this._sumsubService.getVerificationStatus();

    this.userIdentity = this._settingsService.identity;
    this.verificationStatus = this._sumsubService.verificationStatus;

    this._sumsubService.verificationStatusChange.subscribe(value => {
      this.verificationStatus = value;
    });

    this._settingsService.userSettings$.subscribe(value => {
      this.userSettings = value;
      this.tempParams = { ...this.userSettings };
    });

    this._settingsService.identityChange.subscribe(identity => {
      this.userIdentity = identity;
    });

    this.changeAddressForm = this._formBuilder.group({
      street: [this.userIdentity.street, [Validators.required]],
      city: [this.userIdentity.city, [Validators.required]],
      postalCode: [this.userIdentity.postalCode, [Validators.required]],
      country: [this.userIdentity.country],
    });
  }

  ngAfterViewInit() {
    const isEmailChanged = sessionStorage.getItem("emailWasChanged");
    if (isEmailChanged === "true") {
      this._modalService.open("successful-email-change");
      sessionStorage.removeItem("emailWasChanged");
    }
  }

  switchTab(tabNumber: number) {
    this.activeTab = tabNumber;
  }

  areParamsChanged() {
    for (let key of Object.keys(this.userSettings)) {
      if (this.tempParams[key] !== this.userSettings[key]) {
        return true;
      }
    }
    return false;
  }

  onLanguageChange(langTag: LANGUAGE_TAGS) {
    this.tempParams.language = langTag;
  }

  renderAddressString() {
    return `${this.userIdentity.street}, ${this.userIdentity.city}, ${this.userIdentity.country}`;
  }

  openVerificationOfferModal() {
    this._modalService.open("verificationOffer");
  }

  closeVerificationOfferModal() {
    this._modalService.close("verificationOffer");
  }

  openChangeAddressModal() {
    this._modalService.open("change-address");
  }

  closeChangeAddressModal() {
    this._modalService.close("change-address");
    this.changeAddressForm.reset({
      street: this.userIdentity.street,
      city: this.userIdentity.city,
      postalCode: this.userIdentity.postalCode,
      country: this.userIdentity.country,
    });
  }

  handleStartVerification() {
    this._router.navigateByUrl("/dashboard/settings/verification");
  }

  getCountriesNames() {
    return this.countriesList.map(c => c.name);
  }

  setCountry(country: string) {
    const isoCode = this.countriesList.find(x => x.name === country).iso.toUpperCase();
    this.changeAddressForm.controls.country.setValue(isoCode);
  }

  countryCodeToName(code: string) {
    // For admin account
    if (code === "unknown") return "";

    return this.countriesList.find(x => x.iso.toUpperCase() === code)?.name;
  }

  async saveChanges() {
    if (!this.areParamsChanged()) {
      return;
    }

    const newSettings = this.tempParams;
    await this.changeLanguage(newSettings.language);
    this._toastService.open("User settings changed!", "success");
    this.userSettings = { ...this.tempParams };
  }

  async changeAddress() {
    const newIdentity = { ...this.userIdentity, ...this.changeAddressForm.value };
    await this._settingsService.updateUserIdentity(newIdentity);
    this._toastService.open("Address changed!", "success");
    this.closeChangeAddressModal();
  }

  openChangeEmailModal() {
    this._modalService.open("sure-to-change-email");
  }

  closeChangeEmailModal() {
    this._modalService.close("sure-to-change-email");
  }

  closeSuccessfulEmailChangeModal() {
    this._modalService.close("successful-email-change");
  }

  private async changeLanguage(lang: LANGUAGE_TAGS) {
    await this._settingsService.changeLanguage(lang);
  }
}
