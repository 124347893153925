<section class="adminPropPage">
  <div class="adminPropPage__header">
    <div>
      <a class="backArrowButton" routerLink="/admin/properties"></a>
      <h2 class="title">{{ isEditMode ? "Property editing" : generalData?.name[0].title }}</h2>
    </div>
    <div>
      <div
        class="adminPropPage__status"
        [ngClass]="{
          adminPropPage__status_draft: generalData?.status === 0,
          adminPropPage__status_error: generalData?.status === 3
        }"
      >
        {{ renderStatusMessage(generalData?.status) }}
      </div>
      <ng-template [ngIf]="!isEditMode">
        <a class="button" [routerLink]="[]" [queryParams]="{ edit: true, currentTab }">Edit</a>
      </ng-template>
    </div>
  </div>

  <app-tabs
    [tabs]="adminPropTabs"
    [currentTabNumber]="currentTab"
    (tabSelected)="switchTab($event)"
    [withQuery]="true"
  ></app-tabs>

  <div [hidden]="currentTab !== 0">
    <app-admin-property
      [propertyData]="generalData"
      [editable]="isEditMode"
      [isImagesUploading]="isImagesUploading"
      (onSubmit)="handlePropertyFormSubmit($event)"
    ></app-admin-property>
  </div>

  <div [hidden]="currentTab !== 1">
    <app-invest-info-tab
      [investData]="investData"
      [editable]="isEditMode"
      [scIsCreated]="scData && scData?.scStatus !== 0"
      (onSubmit)="handleInvestFormSubmit($event)"
    ></app-invest-info-tab>
  </div>

  <div [hidden]="currentTab !== 2">
    <app-sc-tab
      [propertySmartContractData]="scData"
      [investInfo]="investData"
      [editable]="isEditMode"
      (onSubmit)="handleContractFormSubmit($event)"
    ></app-sc-tab>
  </div>

  <ng-template [ngIf]="isEditMode">
    <div class="adminPropPage__editButtonsWrapper">
      <button class="button button_warning" (click)="handleDelete()">Delete the property</button>
      <button class="button" (click)="handleSave()" [disabled]="!checkValidation()">Save</button>
      <button class="button" (click)="openScWarningModal()" [disabled]="!canPublishSmartContract">
        Publish smart contract
      </button>
      <button class="button" [disabled]="!canPublishProperty" (click)="openPublishWarningModal()">
        Publish the property
      </button>
    </div>
  </ng-template>
</section>

<jw-modal id="publish-sc-modal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">Are you sure?</h4>
      <button (click)="closeScWarningModal()">&#10006;</button>
    </div>
    <p class="infoModal__text">
      Are you sure you want to create a smart contract? The action is irreversible, the funds for
      launching the contract on the blockchain will be debited.
    </p>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closeScWarningModal()">No</button>
      <button class="button" (click)="handlePublishSmartContract()">Yes</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="publish-property-modal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">Are you sure?</h4>
      <button (click)="closePublishWarningModal()">&#10006;</button>
    </div>
    <p class="infoModal__text">Are you sure you want to publish the property?</p>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closePublishWarningModal()">No</button>
      <button class="button" (click)="handlePublishProperty()">Yes</button>
    </div>
  </div>
</jw-modal>
