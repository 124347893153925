<div>
  <span *ngIf="caption" class="dropdown__caption">{{ caption }}</span>
  <div
    #customDropdown
    class="dropdown"
    (click)="toggleSelect()"
    [class.dropdown_disabled]="disabled"
  >
    <img class="dropdown__pic" [src]="img" alt="img" *ngIf="img != null" />
    <span class="dropdown__selectedItemText">{{ displaySelectedItem() }}</span>
    <img
      class="dropdown__arrow"
      src="assets/icons/arrow_select.svg"
      alt="selector"
      [class.dropdown__arrow_flipped]="isOpen"
    />

    <ul *ngIf="isOpen" class="dropdown__list" [class.dropdown__list_up]="openDirection === 'up'">
      <li class="dropdown__listItem" *ngFor="let item of items" (click)="pickItem(item)">
        {{ item }}
      </li>
    </ul>
  </div>
</div>
