import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDatepickerInput } from "@matheo/datepicker";
import { fromEvent, Subscription } from "rxjs";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() placeholder?: string = "";
  @Input() caption?: string = null;
  @Input() note?: string = null;
  @Input() type: "text" | "number" | "search" | "password" | "date" | "datetime" = "text";
  @Input() error?: string;
  @Input() tag: "input" | "textarea" = "input";
  @Input() rows?: number;
  @Input() disabled: boolean;
  @Input() withCopyButton: boolean = false;
  @Input() withSendButton: boolean = false;
  @Input() tooltip: string = null;
  @Input() icon: "mail" | "search" = null;
  @Input() maxSymbols: number = null;
  @Input() shouldFocus: boolean = false;

  @Output() onSearchClick = new EventEmitter();
  @Output() onSendClick = new EventEmitter();

  value: string | number = null;
  showCopiedMessage = false;
  resendTimer: number;

  @ViewChild("inputElement") inputElement: ElementRef;

  eventSubscription: Subscription;
  @ViewChild("dateInputElement") dateElement: ElementRef;
  @ViewChild(MatDatepickerInput) datepickerInput: MatDatepickerInput<any>;

  constructor() {}

  ngOnInit() {
    if (this.withSendButton) {
      this.triggerResendTimer();
    }
  }

  ngAfterViewInit() {
    if (this.dateElement) {
      this.eventSubscription = fromEvent(this.dateElement.nativeElement, "input").subscribe(() => {
        this.datepickerInput._onInput(this.dateElement.nativeElement.value);
        if (!this.value) {
          this.error = "Please check date format";
        } else {
          this.error = null;
        }
      });
    }

    if (this.shouldFocus) {
      this.inputElement.nativeElement.focus();
    }
  }

  private onChange: any = (_: any) => {};
  private onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateValue(insideValue: number) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
    if (this.type === "date" || this.type === "datetime") {
      this.error = null;
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleSearchClick() {
    this.onSearchClick.emit(this.value);
  }

  copyValueToClipboard() {
    const type = "text/plain";
    const blob = new Blob([this.value.toString()], { type });
    const str = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(str);
    this.triggerCopiedMessage();
  }

  triggerCopiedMessage() {
    this.showCopiedMessage = true;

    setTimeout(() => {
      this.showCopiedMessage = false;
    }, 2000);
  }

  handleSendClick() {
    if (this.resendTimer > 0) {
      return;
    }

    this.onSendClick.emit(this.value);
    this.triggerResendTimer();
  }

  autoResizeTextarea(event: Event) {
    if (!this.maxSymbols) {
      return;
    }
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    const microOffset = 3;
    textarea.style.height = textarea.scrollHeight + microOffset + "px";
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  private triggerResendTimer() {
    this.resendTimer = 59;

    const resendTimeout = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer < 1) {
        clearInterval(resendTimeout);
      }
    }, 1000);
  }
}
