<jw-modal id="deposit-modal">
  <section class="deposit">
    <div class="deposit__header">
      <h3 class="title title_sub">{{ "DEPOSIT.TITLE" | translate }}</h3>
      <button (click)="closeDepositModal()">&#10006;</button>
    </div>

    <ng-container *ngIf="step === 0">
      <app-select
        [caption]="'DEPOSIT.CURRENCY' | translate"
        [items]="renderCurrenciesList()"
        [selectedItem]="selectedCurrency?.name"
        (pick)="pickCurrency($event)"
      ></app-select>
      <app-input
        [caption]="'DEPOSIT.WALLET_TO_SEND_FUNDS' | translate"
        tag="textarea"
        [disabled]="true"
        [withCopyButton]="true"
        [ngModel]="depositEthWallet"
      ></app-input>
      <div class="deposit__row" [formGroup]="depositForm">
        <app-input
          type="number"
          [caption]="
            'DEPOSIT.ENTER_AMOUNT' | translate : { amount: selectedCurrency?.symbol | uppercase }
          "
          formControlName="amountCurrency"
          (keyup)="convertCurrencyToEuro()"
        ></app-input>
        <app-input
          type="number"
          [caption]="'DEPOSIT.ENTER_AMOUNT' | translate : { amount: 'EUR' }"
          formControlName="amountEuro"
          (keyup)="convertEuroToCurrency()"
        ></app-input>
      </div>
      <div class="deposit__summary">
        <h4>{{ "DEPOSIT.SUMMARY" | translate }}:</h4>
        <div class="deposit__summaryFields">
          <p class="deposit__row">
            {{ "DEPOSIT.YOU_PAY" | translate }}
            <span
              >{{ depositForm.value.amountCurrency | prettifyNumber }}
              {{ selectedCurrency?.symbol | uppercase }}</span
            >
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.YOU_RECEIVE" | translate : { token: TOKEN } }}
            <span
              >{{ calculateTokensAmount(depositForm.value.amountEuro) | prettifyNumber }}
              {{ TOKEN }}</span
            >
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.EUR_EQUIVALENT" | translate }}
            <span>{{ depositForm.value.amountEuro | prettifyNumber }} EUR</span>
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.RATE" | translate }} ({{ selectedCurrency?.symbol | uppercase }}/EUR)
            <span>
              {{ selectedCurrency?.priceInEuro | prettifyNumber }} EUR / 1
              {{ selectedCurrency?.symbol | uppercase }}
            </span>
          </p>
        </div>
      </div>
      <div class="deposit__buttons">
        <button class="button button_warningLight" (click)="closeDepositModal()">
          {{ "DEPOSIT.CANCEL" | translate }}
        </button>
        <button
          class="button"
          (click)="createDepositRequest()"
          [disabled]="depositForm.invalid || isPending"
        >
          {{ "DEPOSIT.NEXT" | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 1" [formGroup]="transactionForm">
      <p class="deposit__row">
        {{ "DEPOSIT.DEPOSIT_ID" | translate }} <span>{{ transactionId }}</span>
      </p>
      <div class="deposit__summary">
        <h4>{{ "DEPOSIT.SUMMARY" | translate }}:</h4>
        <div class="deposit__summaryFields">
          <p class="deposit__row">
            {{ "DEPOSIT.YOU_PAY" | translate }}
            <span
              >{{ transactionData?.amountCurrency }}
              {{ transactionData?.currency | uppercase }}</span
            >
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.YOU_RECEIVE" | translate : { token: TOKEN } }}
            <span>{{ transactionData?.amountAdrd | prettifyNumber }} {{ TOKEN }}</span>
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.EUR_EQUIVALENT" | translate }}
            <span
              >{{
                selectedCurrency?.priceInEuro * transactionData?.amountCurrency | prettifyNumber
              }}
              EUR</span
            >
          </p>
          <p class="deposit__row">
            {{ "DEPOSIT.RATE" | translate }} ({{ transactionData?.currency | uppercase }}/EUR)
            <span>
              {{ selectedCurrency?.priceInEuro }} EUR / 1
              {{ transactionData?.currency | uppercase }}
            </span>
          </p>
        </div>
      </div>
      <app-input
        tag="textarea"
        [caption]="'DEPOSIT.ENTER_TRANSACTION_HASH' | translate"
        formControlName="hash"
        [withCopyButton]="transactionForm.controls.hash.disabled"
      ></app-input>
      <app-input
        tag="textarea"
        [caption]="'DEPOSIT.COMMENT' | translate"
        formControlName="comment"
      ></app-input>
      <div class="deposit__buttons">
        <button class="button button_warningLight" (click)="closeDepositModal()">
          {{ "DEPOSIT.CANCEL" | translate }}
        </button>
        <button
          class="button"
          [disabled]="!transactionForm.enabled || transactionForm.invalid || isPending"
          (click)="completeTransaction()"
        >
          {{ "DEPOSIT.CONFIRM" | translate }}
        </button>
      </div>
    </ng-container>
  </section>
</jw-modal>
