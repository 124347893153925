import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AdminPropertyComponent } from "src/app/components/_admin/admin-property/admin-property.component";
import { InvestInfoTabComponent } from "src/app/components/_admin/invest-info-tab/invest-info-tab.component";
import { ScTabComponent } from "src/app/components/_admin/sc-tab/sc-tab.component";
import { ModalService } from "src/app/components/_modal";
import {
  ADMIN_PROPERTY_STATUS,
  IInvestInfo,
  IPropertyInfo,
  IRawProperty,
  IToken,
  SC_TRANSACTION_STATUS,
  SMART_CONTRACT_STATUS,
} from "src/app/models/property";
import { PropertiesService } from "src/app/services/properties.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./admin-property-page.component.html",
  styleUrls: ["./admin-property-page.component.scss"],
})
export class AdminPropertyPageComponent implements OnInit {
  isEditMode = false;
  adminPropTabs = ["General", "Invest info", "Smart contract"];
  currentTab = 0;
  generalData: IPropertyInfo = null;
  scData: IToken = null;
  investData: IInvestInfo = null;
  editedData: IRawProperty = null;
  id: string;
  isImagesUploading: boolean = false;

  canPublishProperty = false;
  canPublishSmartContract = false;

  @ViewChild(AdminPropertyComponent) propertyTabComponent: AdminPropertyComponent;
  @ViewChild(InvestInfoTabComponent) investTabComponent: InvestInfoTabComponent;
  @ViewChild(ScTabComponent) scTabComponent: ScTabComponent;

  private querySubscription: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _propertiesService: PropertiesService,
    private _toastService: ToastService,
    private _modalService: ModalService
  ) {
    this.querySubscription = _activatedRoute.queryParams.subscribe(q => {
      if (q["edit"] === "true") {
        this.isEditMode = true;
      }
      if (q["currentTab"]) {
        this.currentTab = +q["currentTab"];
      } else {
        this.currentTab = 0;
      }
    });
  }

  ngOnInit() {
    this.id = this._activatedRoute.snapshot.params.id;
    this.getPropertyData();
  }

  async getPropertyData() {
    const data = await this._propertiesService.getRawPropertyById(this.id);
    this.generalData = {
      ...data,
    };
    this.scData = data.token;
    this.investData = data.investInfo;

    this.checkCanPublishSc();
    this.checkCanPublishProperty();
  }

  checkCanPublishSc() {
    if (
      this.scData &&
      (this.scData.scStatus === SMART_CONTRACT_STATUS.NOT_STARTED ||
        !this.scData.transaction ||
        this.scData.transaction.status === SC_TRANSACTION_STATUS.FAILED) &&
      this.scData.name &&
      this.scData.ticker &&
      this.investData.totalPrice
    ) {
      this.canPublishSmartContract = true;
    } else {
      this.canPublishSmartContract = false;
    }
  }

  checkCanPublishProperty() {
    if (
      this.generalData.status === ADMIN_PROPERTY_STATUS.DRAFT &&
      this.scData?.scStatus === SMART_CONTRACT_STATUS.DEPLOYED
    ) {
      this.canPublishProperty = true;
    } else {
      this.canPublishProperty = false;
    }
  }

  switchTab(tab: number) {
    this.currentTab = +tab;
  }

  renderStatusMessage(status: ADMIN_PROPERTY_STATUS) {
    switch (status) {
      case ADMIN_PROPERTY_STATUS.DRAFT:
        return "Draft";
      case ADMIN_PROPERTY_STATUS.COMPLETED:
        return "Successfully raised funds";
      case ADMIN_PROPERTY_STATUS.STARTED:
        return "Fundraising is underway";
      case ADMIN_PROPERTY_STATUS.FAILED:
        return "Failure to collect";
      case ADMIN_PROPERTY_STATUS.OUTDATED:
        return "Funds are not collected in the specified period of time";
    }
  }

  async handlePropertyFormSubmit(data: IPropertyInfo) {
    this.editedData = { ...this.editedData, ...data };
  }

  handleContractFormSubmit(data: IToken) {
    if (!this.scData || this.scData.scStatus === SMART_CONTRACT_STATUS.NOT_STARTED) {
      this.editedData = { ...this.editedData, token: { ...data } };
    }
  }

  handleInvestFormSubmit(data: IInvestInfo) {
    this.editedData = { ...this.editedData, investInfo: { ...data } };
  }

  async handleUploadImages(images: FileList) {
    if (!images || images.length <= 0) {
      return null;
    }

    const fd = new FormData();
    for (let index = 0; index < images.length; index++) {
      fd.append("images", images[index]);
    }

    return await this._propertiesService.addImagesToProperty(this.id, fd);
  }

  async handleSave() {
    this.propertyTabComponent.submit();
    this.investTabComponent.submit();
    this.scTabComponent.submit();

    try {
      if (this.editedData.images.length > 0) {
        this.isImagesUploading = true;
        const addedImages = await this.handleUploadImages(this.editedData.images);
        const newImagesIds = addedImages.data.map(x => x.id);
        this.editedData.imagesIds.push(...newImagesIds);
        this.isImagesUploading = false;
      }
      delete this.editedData.images;
      await this._propertiesService.editPropertyById(this.id, this.editedData);
      this.exitEditMode();
      this._toastService.open("Saved!", "success");
      this.getPropertyData();
    } catch (error) {
      throw error;
    }
  }

  exitEditMode() {
    this.isEditMode = false;
    this._router.navigate([], {
      queryParams: { edit: null },
      queryParamsHandling: "merge",
    });
  }

  checkValidation() {
    if (
      this.propertyTabComponent?.form?.valid &&
      this.investTabComponent?.form?.valid &&
      (this.scTabComponent?.scFrom?.valid || this.scTabComponent?.scFrom?.disabled)
    ) {
      return true;
    } else {
      return false;
    }
  }

  async handlePublishSmartContract() {
    try {
      await this._propertiesService.publishSmartContract(this.id);
      this._toastService.open(
        "Success! The smart contract will be created for some time",
        "success"
      );
      this.canPublishSmartContract = false;
      this.exitEditMode();
      this.closeScWarningModal();
      window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  async handlePublishProperty() {
    try {
      await this._propertiesService.publishProperty(this.id);
      this._toastService.open("Property published!", "success");
      this.canPublishProperty = false;
      this.exitEditMode();
      this.closePublishWarningModal();
      window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  async handleDelete() {
    try {
      await this._propertiesService.deleteProperty(this.id);
      this._toastService.open("Property deleted!", "success");
      this.exitEditMode();
      this._router.navigateByUrl("/admin/properties");
    } catch (error) {
      throw error;
    }
  }

  openScWarningModal() {
    this._modalService.open("publish-sc-modal");
  }
  closeScWarningModal() {
    this._modalService.close("publish-sc-modal");
  }

  openPublishWarningModal() {
    this._modalService.open("publish-property-modal");
  }
  closePublishWarningModal() {
    this._modalService.close("publish-property-modal");
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
