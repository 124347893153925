<div class="ta__wrapper">
  <div class="ta">
    <label class="ta__input">
      <p class="ta__inputText">
        {{ "BUY_PROPERTY.TOKENS" | translate }}
        <span *ngIf="minTokens && maxTokens && tokenName">
          {{
            "BUY_PROPERTY.FORM_TOKENS_DESCR"
              | translate : { minTokens, maxTokens, token: tokenName }
          }}
        </span>
      </p>
      <input
        type="number"
        name="tokens"
        [min]="minTokens"
        [max]="maxTokens"
        (keypress)="handleOnlyPositiveNumbers($event)"
        (keyup)="convertTokensToSqm()"
        [ngModel]="value"
        (ngModelChange)="updateValue($event)"
        required
        positiveNumber
        class="ta__inputField"
        [class.ta__inputField_error]="errorTokens"
        [ngStyle]="{ paddingRight: getPxWidthOfString(tokenName) }"
        placeholder="0"
      />
      <span class="ta__inputTicker">{{ tokenName }}</span>
      <small *ngIf="errorTokens" class="ta__inputErrorText">{{ errorTokens }}</small>
      <div class="ta__inputBorder"></div>
    </label>
    <label class="ta__input">
      <p class="ta__inputText">
        {{ "BUY_PROPERTY.AREA" | translate }}
        <span *ngIf="minSqm && maxSqm">{{
          "BUY_PROPERTY.FORM_SQM_DESCR" | translate : { minSqm, maxSqm }
        }}</span>
      </p>
      <input
        type="text"
        name="sqm"
        #inputSqm="ngModel"
        [min]="minSqm"
        [max]="maxSqm"
        required
        [(ngModel)]="sqm"
        (keyup)="convertSqmToTokens()"
        appTwoDigitDecimaNumber
        class="ta__inputField"
        [class.ta__inputField_error]="errorTokens"
        [ngStyle]="{ paddingRight: getPxWidthOfString('m²') }"
        placeholder="0"
      />
      <span class="ta__inputTicker">m²</span>
      <small *ngIf="errorTokens" class="ta__inputErrorText">{{ errorTokens }}</small>
      <div class="ta__inputBorder"></div>
    </label>
  </div>

  <button type="button" class="ta__max" (click)="setMax()">
    {{ "BUY_PROPERTY.MAX" | translate }}
  </button>
</div>
