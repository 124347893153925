import { SC_TRANSACTION_STATUS } from "./property";

export type TransactionManagerType = "admin" | "client";

export enum TRANSACTION_MANAGER_STATUS {
  ACTIVE,
  STOPPED,
  FAILED,
}

export interface ITransactionManagerStatus {
  status: TRANSACTION_MANAGER_STATUS;
  failReason?: any;
}

export enum RAW_TRANSACTION_TYPE {
  CREATE_PROPERTY = 0,
  TRANSFER_TOKENS = 1,
  BURN_PROPERTY = 2,
  DEPOSIT = 5,
  BUY_PROPERTY = 6,
  PAY_DIVIDENDS = 7,
}

export enum TRANSACTION_FAIL_REASON {
  UNKNOWN,
  LOW_BALANCE,
  NO_PERMISSIONS,
  TIMEOUT,
}

export interface IRawTransaction {
  id: string;
  hash: string;
  type: RAW_TRANSACTION_TYPE;
  isRestartable: boolean;
  isCancellable: boolean;
  status: SC_TRANSACTION_STATUS;
  failReason: TRANSACTION_FAIL_REASON;
  decodedData: any;
  completedAt: string;
  createdAt: string;
  updatedAt: string;
  initializerEmail: string;
  cancellerEmail: string;
}
