import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  templateUrl: "./monetization.component.html",
  styleUrls: ["./monetization.component.scss"],
})
export class MonetizationComponent implements OnInit {
  tabs = ["Future payouts", "Monetization", "Payout history"];
  currentTabNumber = 0;

  private querySubscription: Subscription;

  constructor(private _activatedRoute: ActivatedRoute) {
    this.querySubscription = this._activatedRoute.queryParams.subscribe(q => {
      if (q["currentTab"]) {
        this.currentTabNumber = +q["currentTab"];
      } else {
        this.currentTabNumber = 0;
      }
    });
  }

  ngOnInit(): void {}

  onTabChange(tabNumber: number) {
    this.currentTabNumber = tabNumber;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
