<main class="properties">
  <app-main-header [headline]="'PROPERTIES.TITLE' | translate"></app-main-header>

  <app-filter-bar
    (onSearch)="requestFilteredData()"
    (onFiltersApply)="requestFilteredData()"
    (onSortingChange)="requestFilteredData()"
    usedIn="properties"
    [searchPlaceholder]="'FILTERS.FIND_A_PROPERTY' | translate"
  ></app-filter-bar>

  <div
    *ngIf="propertiesList.length > 0; else nothingFound"
    class="properties__wrapper"
    [ngClass]="{ properties__wrapper_row: layoutType === LayoutVariant.ROW }"
  >
    <app-property-card
      *ngFor="let propItem of propertiesList"
      [data]="propItem"
      [variant]="layoutType === LayoutVariant.GRID ? 'vertical' : 'horizontal'"
      cardType="property"
      (onClick)="openPropertyPage($event)"
      [showFavButton]="true"
    ></app-property-card>
  </div>

  <ng-template #nothingFound>
    <div *ngIf="!isLoading" class="properties__nothing">
      <img src="assets/icons/sad-smile.svg" alt="Nothing in properties" />
      <p>{{ "PROPERTIES.NOTHING_FOUND" | translate }}</p>
    </div>
  </ng-template>

  <ng-container *ngIf="!isLoading && !isLimitReached">
    <div appIntersectionObserver (intersectionObserver)="onIntersection($event)"></div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div
      class="properties__wrapper"
      [ngClass]="{ properties__wrapper_row: layoutType === LayoutVariant.ROW }"
    >
      <app-card-skeleton
        [variant]="layoutType === LayoutVariant.GRID ? 'vertical' : 'horizontal'"
      ></app-card-skeleton>
      <app-card-skeleton
        [variant]="layoutType === LayoutVariant.GRID ? 'vertical' : 'horizontal'"
      ></app-card-skeleton>
      <app-card-skeleton
        [variant]="layoutType === LayoutVariant.GRID ? 'vertical' : 'horizontal'"
      ></app-card-skeleton>
    </div>
  </ng-container>
</main>
