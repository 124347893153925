import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[positiveNumber]",
})
export class PositiveNumberDirective {
  // Allow decimal numbers. Change the false value to true if decimals are allowed.
  private regex: RegExp = new RegExp(/^[0-9]*$/);
  // Allow key codes for special events. Reflect :
  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
  ];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Do not allow non-numeric characters
    let current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
