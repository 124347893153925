import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { ORDER_TYPE } from "src/app/models/filters";
import { IP2pOrder, IP2pProperty } from "src/app/models/p2p";
import { P2pService } from "src/app/services/p2p.service";
import { TOKEN } from "src/app/constants/token-name.constant";

enum STEPS {
  LISTING,
  BUYING,
}

@Component({
  templateUrl: "./buy-p2p.component.html",
  styleUrls: ["./buy-p2p.component.scss"],
})
export class BuyP2pComponent implements OnInit {
  TOKEN = TOKEN;
  propertyData: IP2pProperty;
  step = STEPS.LISTING;
  StepType = STEPS;
  orders = new MatTableDataSource<IP2pOrder>();
  displayedColumns = ["numericId", "available_area", "token_price", "total_amount", "action"];
  propertyId: string;
  selectedOrder: IP2pOrder;
  page = 0;
  ordersCount: number;
  sorting: string[] = [];

  constructor(private _activatedRoute: ActivatedRoute, private _p2pService: P2pService) {
    this.propertyId = this._activatedRoute.snapshot.params.id;
  }

  async ngOnInit() {
    this.propertyData = await this._p2pService.getPropertyInfo(this.propertyId, "sell");
    this.getOrdersData();
  }

  async getOrdersData() {
    const res = await this._p2pService.getPropertyOrders(
      this.propertyId,
      "sell",
      this.page,
      this.sorting
    );
    this.orders.data = res.items;
    this.ordersCount = res.pagination.perPage * res.pagination.totalPages;
  }

  startBuySequence(orderId: string) {
    this.step = STEPS.BUYING;
    this.selectedOrder = this.orders.data.find(x => x.id === orderId);
  }

  goBackToListing() {
    this.step = STEPS.LISTING;
    this.selectedOrder;
  }

  async onSort(sort: Sort) {
    const dir =
      sort.direction === "asc"
        ? ORDER_TYPE.ASC
        : sort.direction === "desc"
        ? ORDER_TYPE.DESC
        : null;

    if (!dir) {
      this.sorting = [];
      this.page = 0;
      await this.getOrdersData();
      return;
    }

    const orderFieldQuery = `order[field]=${sort.active}`;
    const orderDirectionQuery = `order[direction]=${dir}`;
    this.sorting = [orderFieldQuery, orderDirectionQuery];

    this.page = 0;
    this.getOrdersData();
  }

  onPageChange(e: PageEvent) {
    this.page = e.pageIndex;
    this.getOrdersData();
  }
}
