import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LAYOUT_VARIANT } from "src/app/models/filters";
import { IProperty } from "src/app/models/property";
import { FiltersService } from "src/app/services/filters.service";
import { PropertiesService } from "src/app/services/properties.service";
import { SettingsService } from "src/app/services/settings.service";

@Component({
  templateUrl: "./properties.component.html",
  styleUrls: ["./properties.component.scss"],
})
export class PropertiesComponent implements OnInit {
  propertiesList: IProperty[] = [];
  page: number = 0;
  totalPages: number;
  isLimitReached = false;
  LayoutVariant = LAYOUT_VARIANT;
  layoutType = this._settingsService.layoutType;
  isLoading = true;

  constructor(
    private readonly _propertiesService: PropertiesService,
    private _settingsService: SettingsService,
    private _filtersService: FiltersService,
    private _router: Router
  ) {}

  async ngOnInit() {
    await this.getPropertiesData(this.page);

    this._settingsService.layoutVariantChange.subscribe((value: LAYOUT_VARIANT) => {
      this.layoutType = value;
    });
  }

  async getPropertiesData(page: number) {
    this.isLoading = true;
    try {
      const resp = await this._propertiesService.getPropertiesList(
        page,
        this._filtersService.filtersQuery
      );
      this.totalPages = resp.pagination.totalPages;
      this.propertiesList.push(...resp.items);
      if (this.page + 1 === this.totalPages || this.totalPages === 0) {
        this.isLimitReached = true;
      } else {
        this.isLimitReached = false;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  loadMore() {
    this.page++;
    this.getPropertiesData(this.page);
  }

  requestFilteredData() {
    this.isLoading = true;
    this.propertiesList = [];
    this.page = 0;
    this.getPropertiesData(this.page);
  }

  openPropertyPage(id: string) {
    this._router.navigateByUrl(`/dashboard/properties/${id}`);
  }

  onIntersection(isIntersecting: boolean) {
    if (isIntersecting && !this.isLimitReached) {
      this.loadMore();
    }
  }
}
