<div class="sumsub">
  <div class="sumsub__wrapper" #sumsubWebsdkContainer></div>

  <div class="sumsub__bottom" *ngIf="showEndButton">
    <p>{{ "SETTINGS.YOU_CAN_LEAVE" | translate }}</p>
    <a class="button" routerLink="/dashboard/settings">{{
      "SETTINGS.GO_TO_SETTINGS" | translate
    }}</a>
  </div>
</div>
