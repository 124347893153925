import { Component, OnInit } from "@angular/core";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  constructor(private _walletService: WalletService) {}

  ngOnInit(): void {
    this._walletService.getAppTokenPrice();
  }
}
