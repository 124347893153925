<form
  id="propertyForm"
  #propertyForm="ngForm"
  class="propertyForm"
  [formGroup]="form"
  (ngSubmit)="submit()"
>
  <div class="propertyForm__wrapper">
    <div class="propertyForm__formHeader">
      <h3 class="title title_sub">Property description</h3>
      <app-tabs
        [tabs]="languagesList"
        [currentTabNumber]="languageTab"
        variant="mini"
        (tabSelected)="onChangeTabLanguage($event)"
      ></app-tabs>
    </div>

    <div formGroupName="name">
      <ng-template [ngIf]="languageTab === 0">
        <app-input caption="Name" formControlName="EN"></app-input>
      </ng-template>
      <ng-template [ngIf]="languageTab === 1">
        <app-input caption="Name" formControlName="CZ"></app-input>
      </ng-template>
    </div>

    <app-switch
      caption="Active"
      [(ngModel)]="activeToggle"
      [ngModelOptions]="{ standalone: true }"
      (onSwitch)="handleActiveToggle()"
      [hidden]="isCreation"
      [disabled]="form.controls.isVisible.disabled"
    ></app-switch>
    <app-switch
      caption="P2P Enabled"
      formControlName="isP2PEnabled"
      [hidden]="isCreation"
    ></app-switch>

    <app-select
      caption="Type of Object"
      [items]="getTypesList(propertyTypes)"
      [selectedItem]="getNameFromType(propertyTypes, form.value.propertyType)"
      (pick)="setType($event, propertyTypes, form.controls.propertyType)"
      [disabled]="!editable"
    ></app-select>
    <div class="propertyForm__inputRow">
      <app-input
        caption="Total area, m²"
        type="number"
        formControlName="totalSpace"
        appTwoDigitDecimaNumber
      ></app-input>
      <app-input
        caption="Living area, m²"
        type="number"
        formControlName="livingSpace"
        appTwoDigitDecimaNumber
      ></app-input>
    </div>
    <div class="propertyForm__inputRow">
      <app-input
        caption="No. of rooms"
        type="number"
        formControlName="roomsAmount"
        appIntegerNumber
      ></app-input>
      <app-select
        caption="Rent type"
        [items]="getTypesList(rentTypes)"
        [selectedItem]="getNameFromType(rentTypes, form.value.rentType)"
        (pick)="setType($event, rentTypes, form.controls.rentType)"
        [disabled]="!editable"
      ></app-select>
    </div>
    <div formGroupName="shortDescription">
      <ng-template [ngIf]="languageTab === 0">
        <app-input
          caption="Brief description of the object"
          tag="textarea"
          rows="5"
          formControlName="EN"
          [maxSymbols]="MaxPropertyShortDescriptionLength"
        ></app-input>
      </ng-template>
      <ng-template [ngIf]="languageTab === 1">
        <app-input
          caption="Brief description of the object"
          tag="textarea"
          rows="5"
          formControlName="CZ"
          [maxSymbols]="MaxPropertyShortDescriptionLength"
        ></app-input>
      </ng-template>
    </div>
    <div formGroupName="fullDescription">
      <ng-template [ngIf]="languageTab === 0">
        <app-input
          caption="Full description of the object"
          tag="textarea"
          rows="8"
          formControlName="EN"
          [maxSymbols]="MaxPropertyFullDescriptionLength"
        ></app-input>
      </ng-template>
      <ng-template [ngIf]="languageTab === 1">
        <app-input
          caption="Full description of the object"
          tag="textarea"
          rows="8"
          formControlName="CZ"
          [maxSymbols]="MaxPropertyFullDescriptionLength"
        ></app-input>
      </ng-template>
    </div>
  </div>

  <div class="propertyForm__wrapper" formGroupName="address">
    <h3 class="title title_sub">Location</h3>
    <app-input caption="Country" formControlName="country"></app-input>
    <app-input caption="City" formControlName="city"></app-input>
    <app-input caption="District" formControlName="district"></app-input>
    <app-input caption="Address" formControlName="address"></app-input>
  </div>

  <div class="propertyForm__wrapper propertyForm__imagesBlock">
    <div class="propertyForm__imagesHeader">
      <h3 class="title title_sub">Photos</h3>
      <ng-template [ngIf]="(previews.length > 0 || serverImages.length > 0) && editable">
        <div class="propertyForm__plusImageBtn">
          <input
            type="file"
            name="file"
            multiple
            accept="image/png, image/jpeg,.png,.jpg,.jpeg"
            (change)="onFileChange($event)"
            #plusUpload
          />
          <button type="button" (click)="plusUpload.click()">+</button>
        </div>
      </ng-template>
    </div>

    <app-loader *ngIf="isImagesUploading"></app-loader>

    <ng-container *ngIf="!isImagesUploading">
      <div
        class="propertyForm__images"
        *ngIf="previews.length > 0 || serverImages.length > 0; else fileZone"
      >
        <div *ngFor="let image of serverImages; index as i" class="propertyForm__imageItem">
          <img [src]="getImageUrl(image.path)" alt="photo" />
          <button *ngIf="editable" type="button" (click)="deleteServerImage(image)">x</button>
        </div>
        <div *ngFor="let image of previews; index as i" class="propertyForm__imageItem">
          <img [src]="image" alt="photo" />
          <button *ngIf="editable" type="button" (click)="deleteLocalImage(i)">x</button>
        </div>
      </div>
      <ng-template #fileZone>
        <label class="propertyForm__fileZone" *ngIf="editable">
          <img src="assets/icons/image-add.svg" alt="add Photo" />
          <span>Photos</span>
          <div class="button button_light">Choose a file</div>
          <input
            type="file"
            name="file"
            multiple
            class="button button_light"
            accept="image/png,image/jpeg,.png,.jpg,.jpeg"
            (change)="onFileChange($event)"
          />
        </label>
      </ng-template>
    </ng-container>
  </div>
</form>

<jw-modal class="modal" id="noSecondLangModal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">You did not fill out the information in the second language</h3>
      <button (click)="closeNSLModal()">&#10006;</button>
    </div>
    <p class="infoModal__text">You did not fill out the information in the second language</p>
    <div class="infoModal__footer">
      <button class="button" (click)="closeNSLModal()">OK</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="activate-property-modal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">Are you sure?</h4>
      <button (click)="deactivateAndCloseModal()">&#10006;</button>
    </div>
    <p class="infoModal__text">
      Are you sure you want to activate the property? It will be available to users in the search.
    </p>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="deactivateAndCloseModal()">No</button>
      <button class="button" (click)="closeActivatePropertyModal()">Yes</button>
    </div>
  </div>
</jw-modal>
