import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ModalService } from "src/app/components/_modal";
import { ORDER_TYPE } from "src/app/models/filters";
import {
  FUNDING_TRANSACTION_TYPE,
  IAdminFundingTransaction,
  TRANSACTION_STATUS,
} from "src/app/models/wallet";
import { ToastService } from "src/app/services/toast.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { AccountsService } from "src/app/services/accounts.service";

@Component({
  templateUrl: "./admin-requests.component.html",
  styleUrls: ["./admin-requests.component.scss"],
})
export class AdminRequestsComponent implements OnInit {
  TOKEN = TOKEN;
  dataSource = new MatTableDataSource<IAdminFundingTransaction>();
  displayedColumns = [
    "id",
    "date",
    "type",
    "asset",
    "currency_amount",
    "adrd_amount",
    "hash",
    "user_id",
    "status",
  ];
  sorting: string[] = [
    `order[field]=${this.displayedColumns[1]}`,
    `order[direction]=${ORDER_TYPE.DESC}`,
  ];
  selectedRequest: IAdminFundingTransaction;
  statusList = [
    { id: TRANSACTION_STATUS.PENDING, text: "Pending" },
    { id: TRANSACTION_STATUS.ACCEPTED, text: "Accepted" },
    { id: TRANSACTION_STATUS.COMPLETED, text: "Completed" },
    { id: TRANSACTION_STATUS.CANCELLED, text: "Cancelled" },
    { id: TRANSACTION_STATUS.NEW, text: "Opened" },
  ];
  statusToChange: TRANSACTION_STATUS;
  selectedRequestUserEmail: string = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;

  creditForm = new FormGroup({
    amountAdrd: new FormControl(undefined, [Validators.required]),
  });

  confirmationForm = new FormGroup({
    hash: new FormControl(undefined, [Validators.required]),
  });

  constructor(
    private walletService: WalletService,
    private modalService: ModalService,
    private toastService: ToastService,
    private accountsService: AccountsService
  ) {}

  async ngOnInit() {
    this.getRequests(0);
  }

  async getRequests(page: number) {
    const response = await this.walletService.getAdminTransactionsList(page, this.sorting);
    this.dataSource.data = response.items;
    this.paginator.length = response.pagination.perPage * response.pagination.totalPages;
    this.paginator.pageSize = response.pagination.perPage;
    this.paginator.pageIndex = page;
  }

  getStatusesList() {
    return this.statusList.map(s => s.text);
  }

  getStatusText(status: TRANSACTION_STATUS) {
    const finded = this.statusList.find(x => x.id === status);
    return finded.text;
  }

  setStatusToChange(statusName: string) {
    const finded = this.statusList.find(x => x.text === statusName);
    this.statusToChange = finded.id;
  }

  async handleSaveStatus() {
    try {
      await this.walletService.adminSetTransactionStatus(
        this.selectedRequest.id,
        this.statusToChange
      );
      this.toastService.open("Status changed!", "success");
    } catch (error) {
      throw error;
    }
  }

  renderType(type: FUNDING_TRANSACTION_TYPE) {
    switch (type) {
      default:
      case FUNDING_TRANSACTION_TYPE.DEPOSIT:
        return "Deposit";
      case FUNDING_TRANSACTION_TYPE.WITHDRAWAL:
        return "Withdrawal";
    }
  }

  onPageChange(e: PageEvent) {
    this.getRequests(e.pageIndex);
  }

  async setRequestAndOpenModal(request: IAdminFundingTransaction) {
    this.selectedRequest = request;
    this.openRequestModal();

    const userData = await this.requestAccount(request.userId);
    this.selectedRequestUserEmail = userData?.email;
  }

  openRequestModal() {
    this.modalService.open("deposit-request-modal");
  }
  closeRequestModal() {
    this.modalService.close("deposit-request-modal");
  }

  handleOpenConfirmationModal() {
    this.closeRequestModal();
    this.modalService.open("deposit-request-confirmation-modal");
  }
  handleCloseConfirmationModal() {
    this.modalService.close("deposit-request-confirmation-modal");
    this.openRequestModal();
  }

  getHashesMatch() {
    return this.selectedRequest.hash !== this.confirmationForm.value.hash;
  }

  async handleAcceptTransaction() {
    try {
      await this.walletService.adminAcceptTransaction(
        this.selectedRequest.id,
        +this.creditForm.value.amountAdrd
      );
      this.toastService.open("Transaction accepted!", "success");
      this.modalService.close("deposit-request-confirmation-modal");
      await this.getRequests(this.paginator.pageIndex);
    } catch (error) {}
  }

  onSort(sort: Sort) {
    this.sorting = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.getRequests(0);
  }

  private async requestAccount(id: string) {
    try {
      const res = await this.accountsService.getAccountById(id);
      return res;
    } catch (error) {
      return null;
    }
  }
}
