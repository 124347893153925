import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  @Input() tabs: string[];
  @Input() currentTabNumber: number;
  @Input() variant?: "normal" | "mini" = "normal";
  @Input() withQuery?: boolean = false;

  @Output() tabSelected = new EventEmitter();

  translatedTabs: string[];

  private querySubscription: Subscription;

  constructor(
    private _translate: TranslateService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.withQuery) {
      this.querySubscription = this._activatedRoute.queryParams.subscribe(q => {
        const currentTabNum = q["currentTab"] || 0;
        if (currentTabNum <= this.tabs.length && currentTabNum >= 0) {
          this.currentTabNumber = currentTabNum;
        } else {
          this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: { currentTab: 0 },
            queryParamsHandling: "merge",
          });
        }
      });
    }

    this._translate.onLangChange.subscribe(() => {
      this.prepareTabNames();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabs) {
      this.prepareTabNames();
    }
  }

  prepareTabNames() {
    const withTranslations = this.tabs.map(el => this._translate.instant(el));
    this.translatedTabs = withTranslations;
  }

  onTabSelect(tabNumber: number) {
    this.currentTabNumber = tabNumber;
    this.tabSelected.emit(tabNumber);

    if (this.withQuery) {
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: { currentTab: tabNumber },
        queryParamsHandling: "merge",
      });
    }
  }
}
