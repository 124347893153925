import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { IReferralInfo, IReferralTransfer } from "../models/referral";
import { IResponseWithPagination } from "../models/api-response";

@Injectable({
  providedIn: "root",
})
export class ReferralService {
  constructor(private _api: BaseRequestService) {}

  getReferralInfo() {
    return this._api.get<IReferralInfo>("/referral");
  }

  withdrawIncome() {
    return this._api.post("/referral/withdraw", {});
  }

  getTransfers(page: number, perPage: number = 10) {
    return this._api.get<IResponseWithPagination<IReferralTransfer>>(
      `/referral/transfers?page=${page}&perPage=${perPage}`
    );
  }

  getCanUseReferralCode(code: string) {
    return this._api.get(`/identity/check-can-use-referral/${code}`);
  }
}
