import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { IProperty, IDetailedProperty } from "src/app/models/property";
import { PropertiesService } from "src/app/services/properties.service";

@Component({
  selector: "app-buy-property",
  templateUrl: "./buy-property.component.html",
  styleUrls: ["./buy-property.component.scss"],
})
export class BuyPropertyComponent implements OnInit {
  id: string;
  propertyData: IDetailedProperty;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _propertyService: PropertiesService,
    private _router: Router
  ) {}

  async ngOnInit() {
    this.id = this._activatedRoute.snapshot.params.id;
    const resp = await this._propertyService.getPropertyById(this.id);
    this.propertyData = resp;
  }

  convertDataForCard(property: IDetailedProperty): IProperty {
    return {
      id: this.id,
      name: property.name,
      tags: property.tags,
      description: property.shortDescription,
      images: property.images,
      startInvestingFrom: property.investInfo.minInvestTokensAmount * property.tokenPrice,
      estimatedIncome: property.investInfo.estimatedAnnualIncome,
      collected: property.investInfo.collected,
      target: property.investInfo.totalPrice,
    };
  }

  goBack() {
    this._router.navigateByUrl(`/dashboard/properties/${this.id}`);
  }
}
