import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ModalService } from "src/app/components/_modal";
import { IReferralTransfer, IReferredUser } from "src/app/models/referral";
import { TRANSACTION_STATUS } from "src/app/models/wallet";
import { ReferralService } from "src/app/services/referral.service";
import { ToastService } from "src/app/services/toast.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./referrals.component.html",
  styleUrls: ["./referrals.component.scss"],
})
export class ReferralsComponent implements OnInit {
  TOKEN = TOKEN;
  refAboutData = [
    {
      icon: "assets/icons/monetization.svg",
      title: "REFERRALS.BONUS_TITLE",
      text: "REFERRALS.BONUS_TEXT",
    },
    {
      icon: "assets/icons/share.svg",
      title: "REFERRALS.INVITE_TITLE",
      text: "REFERRALS.INVITE_TEXT",
    },
    {
      icon: "assets/icons/query-stats.svg",
      title: "REFERRALS.GAIN_TITLE",
      text: "REFERRALS.GAIN_TEXT",
    },
  ];
  statsData = [
    { id: 0, name: "REFERRALS.CURRENT_INCOME", value: null as number },
    { id: 1, name: "REFERRALS.ATTRACTED_USERS", value: null as number },
    { id: 2, name: "REFERRALS.PURCHASES", value: null as number },
  ];

  refTable = new MatTableDataSource<IReferredUser>();
  refColumns = ["account", "registration_date", "first_purchase", "income"];

  historyTable = new MatTableDataSource<IReferralTransfer>();
  historyColumns = ["date", "amountAdrd", "status", "hash"];
  historyPage = 0;
  historyPageSize = 10;
  historyTotal = 0;

  myReferralCode: string = "";

  constructor(
    private _modalService: ModalService,
    private _toastService: ToastService,
    private _referralService: ReferralService
  ) {}

  ngOnInit() {
    this.requestReferralData();
    this.getWithdrawHistory();
  }

  async requestReferralData() {
    const data = await this._referralService.getReferralInfo();
    this.statsData[0].value = data.balance;
    this.statsData[1].value = data.referrations.length;
    this.statsData[2].value = this.calculateTotalPurchases(data.referrations);
    this.myReferralCode = data.code;
    this.refTable.data = data.referrations;
  }

  async getWithdrawHistory() {
    const res = await this._referralService.getTransfers(this.historyPage, this.historyPageSize);
    this.historyTable.data = res.items;
    this.historyTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  onHistoryPageChange(page: PageEvent) {
    this.historyPage = page.pageIndex;
    this.getWithdrawHistory();
  }

  returnReferralLink() {
    const appUrl = environment.serverUrl.replace("/api", "");
    return `${appUrl}/login?referralCode=${this.myReferralCode}`;
  }

  calculateTotalPurchases(users: IReferredUser[]) {
    return users.filter(r => r.firstPurchaseDate !== null).length;
  }

  openTransferModal() {
    this._modalService.open("ref-income");
  }

  closeTransferModal() {
    this._modalService.close("ref-income");
  }

  async doTransfer() {
    try {
      await this._referralService.withdrawIncome();
      this.requestReferralData();
      this._toastService.open("Successful transfer!", "success");
      this.closeTransferModal();
    } catch (error) {
      throw error;
    }
  }

  openHistoryModal() {
    this._modalService.open("ref-history");
  }

  closeHistoryModal() {
    this._modalService.close("ref-history");
  }

  renderStatus(status: TRANSACTION_STATUS) {
    switch (status) {
      default:
      case TRANSACTION_STATUS.PENDING:
        return "STATUS.PENDING";
      case TRANSACTION_STATUS.COMPLETED:
        return "STATUS.COMPLETED";
      case TRANSACTION_STATUS.CANCELLED:
        return "STATUS.CANCELLED";
      case TRANSACTION_STATUS.ACCEPTED:
        return "STATUS.ACCEPTED";
      case TRANSACTION_STATUS.NEW:
        return "STATUS.NEW";
    }
  }
}
