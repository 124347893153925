import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IP2pListObject, IP2pProperty } from "src/app/models/p2p";
import { IProperty } from "src/app/models/property";
import { PrettifyNumberPipe } from "src/app/pipes/prettify-number.pipe";
import { FavoritesService } from "src/app/services/favorites.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { Utils } from "src/app/utils/utils";
import { AREA_IN_TOKEN } from "src/app/constants/token-area.constant";

type PropertyCard = IProperty & IP2pListObject & IP2pProperty;

@Component({
  selector: "app-property-card",
  templateUrl: "./property-card.component.html",
  styleUrls: ["./property-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyCardComponent {
  @Input() data: PropertyCard;
  @Input() isUserDetails: boolean = false;
  @Input() variant: "vertical" | "horizontal" = "vertical";
  @Input() cardType:
    | "property"
    | "userDetails"
    | "p2p"
    | "p2pCreateSell"
    | "p2pCreateBuy"
    | "p2pOrderBuy"
    | "p2pOrderSell" = "property";
  @Input() showFavButton: boolean = false;
  @Input() status: null | "pending" = null;

  @Output() onClick = new EventEmitter();

  isInFavorites = false;
  TOKEN = TOKEN;
  AREA_IN_TOKEN = AREA_IN_TOKEN;

  constructor(private _favoritesService: FavoritesService, private _translate: TranslateService) {}

  ngOnInit() {
    this._favoritesService.favoriteList$.subscribe(value => {
      if (this.showFavButton) {
        this.checkIsInFav(value);
      }
    });
  }

  checkIsInFav(favorites: string[]) {
    const fIdx = favorites.findIndex(el => el === this.data?.id);
    this.isInFavorites = fIdx >= 0;
  }

  calculatePercentage(collected: number, target: number) {
    return Utils.calculatePercentage(target, collected);
  }

  onCardClick(e: any) {
    if (
      e.target.classList.contains("swiper-button-next") ||
      e.target.classList.contains("swiper-button-prev") ||
      e.target.classList.contains("swiper-button-disabled") ||
      e.target.classList.contains("swiper-pagination-bullet")
    ) {
      e.stopPropagation();
      return;
    }
    this.onClick.emit(this.data.id ?? this.data.property.id);
  }

  handleFavoriteClick(e: any) {
    e.stopPropagation();

    const id = this.data.id;
    if (this.isInFavorites) {
      this._favoritesService.deletePropertyFromFav(id);
    } else {
      this._favoritesService.addPropertyToFav(id);
    }
  }

  renderTag(tag: string, index: number) {
    switch (index) {
      case 0: // Footage
        return `${new PrettifyNumberPipe().transform(tag)} m²`;
      case 1: // Rooms count
        return `${tag} ${this.roomTagDeclension(+tag)}`;
      case 2: // Country
        return tag;
      default:
        return null;
    }
  }

  private roomTagDeclension(count: number) {
    return count > 1
      ? this._translate.instant("PROPERTY_CARD.ROOMS")
      : this._translate.instant("PROPERTY_CARD.ROOM");
  }
}
