import { Platform } from "@angular/cdk/platform";
import { NativeDateAdapter } from "@matheo/datepicker/core";
import * as dayjs from "dayjs";

export class CustomDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  override parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf(".") > -1) {
      const str = value.split(".");

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override format(date: Date, displayFormat: string): string {
    return dayjs(date).format(displayFormat);
  }
}

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: "DD.MM.YYYY",
    datetimeInput: "DD.MM.YYYY hh:mm A",
    timeInput: "hh:mm A",
    monthInput: "MM",
    yearInput: "YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    datetimeInput: "DD.MM.YYYY hh:mm A",
    timeInput: "hh:mm A",
    monthInput: "MM",
    yearInput: "YYYY",
    dateA11yLabel: "DD.MM.YYYY",
    monthLabel: "MMMM",
    monthDayLabel: "DD MMM",
    monthDayA11yLabel: "DD MMM",
    monthYearLabel: "MMM YYYY",
    monthYearA11yLabel: "MMM YYYY",
    timeLabel: "hh:mm",
  },
};
