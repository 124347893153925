import { Injectable } from "@angular/core";
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { SettingsService } from "../services/settings.service";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class IdentityResolver implements Resolve<any> {
  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private _authService: AuthService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (this._authService.isUserLogged) {
      const res = await this.settingsService.getUserIdentity();
      if (!res) {
        this.router.navigateByUrl("/login/identity");
      }
      return res;
    }
    return null;
  }
}
