export interface ITokens {
  accessToken: string;
  refreshToken: string;
}

export interface IAuthData {
  email: string;
  password: string;
}

export interface IEmailChangeData extends IAuthData {
  oldPassword: string;
}

export enum ACCOUNT_ROLE {
  BASIC_USER,
  ADMIN,
}

export interface IAccount {
  email: string;
  exp: number;
  iat: number;
  id: string;
  role: ACCOUNT_ROLE;
}

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
