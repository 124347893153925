<form class="investTab" [formGroup]="form" (ngSubmit)="submit()">
  <h3 class="title title_sub">Financial information</h3>

  <div class="investTab__costRow">
    <app-input
      caption="Object cost, EUR"
      type="number"
      [(ngModel)]="priceInEuro"
      (keyup)="convertEuroToToken()"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="form.controls.totalPrice.disabled"
      appIntegerNumber
    >
    </app-input>
    <app-input
      [caption]="'Cost in ' + TOKEN"
      type="number"
      formControlName="totalPrice"
      appIntegerNumber
    ></app-input>
    <app-input
      caption="Estimated yield, %"
      type="number"
      formControlName="profitability"
      appIntegerNumber
    ></app-input>
  </div>

  <div class="investTab__formRow">
    <app-input
      caption="Min. investment, object tokens"
      type="number"
      formControlName="minInvestAmount"
      appIntegerNumber
    ></app-input>
    <app-select
      caption="Rental demand"
      [items]="getRentalDemandList()"
      [selectedItem]="returnDemandName()"
      (pick)="setRentalDemand($event)"
      [disabled]="!editable"
    ></app-select>
  </div>

  <div class="investTab__formRow">
    <app-input
      caption="Start date of collection"
      type="date"
      formControlName="investStartAt"
    ></app-input>
    <app-input
      *ngIf="withoutEndDate === false"
      caption="End date of collection"
      type="date"
      formControlName="investEndAt"
    ></app-input>
  </div>

  <ng-template [ngIf]="editable">
    <app-switch
      caption="Indefinite"
      [(ngModel)]="withoutEndDate"
      (ngModelChange)="onToggleIndefinite($event)"
      [ngModelOptions]="{ standalone: true }"
    ></app-switch>
  </ng-template>
</form>
