import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject } from "rxjs";
import {
  ADMIN_PROPERTY_RANGE_FILTERS,
  IRangeFilter,
  P2P_BUY_RANGE_FILTERS,
  P2P_SELL_RANGE_FILTERS,
  RANGE_FILTERS,
} from "../models/filters";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  private filters: any = null;
  public filtersQuery: string[] = [];
  public filtersChange = new Subject<any[]>();

  public get filterBarFilters() {
    return this.filters;
  }

  public set filterBarFilters(value: any) {
    this.filters = value;
    const allRanges = new Set([
      ...RANGE_FILTERS,
      ...ADMIN_PROPERTY_RANGE_FILTERS,
      ...P2P_BUY_RANGE_FILTERS,
      ...P2P_SELL_RANGE_FILTERS,
    ]);
    const queries = this.createFilterQueries(value, [...allRanges]);
    this.filtersQuery = queries;
  }

  constructor(private router: Router) {
    this.filtersChange.subscribe(value => {
      this.filters = value;
    });

    // Clear the filters when leaving properties page
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (!event.url.includes("properties") && this.filters) {
        this.filtersChange.next(null);
        this.filtersQuery = [];
      }
    });
  }

  private createFilterQueries(filters: { [key: string]: any }, ranges: IRangeFilter[]) {
    if (!filters) return [];

    const queryParams: string[] = [];

    for (const [key, value] of Object.entries(filters)) {
      let query;

      if (!value || value.length === 0) {
        continue;
      }

      // Handle search filter
      if (key === "search") {
        query = `search=${value}`;
        queryParams.push(query);
      }

      // Handle sorting filter
      if (key === "sorting" && value.order) {
        const orderFieldQuery = `order[field]=${value.type}`;
        const orderDirectionQuery = `order[direction]=${value.order}`;
        queryParams.push(orderFieldQuery, orderDirectionQuery);
      }

      // Handle array filter
      if (Array.isArray(value)) {
        query = `filter[${key}]=${value.join(",")}`;
        queryParams.push(query);
      }

      // Handle range filter
      const isObject = typeof value === "object" && !Array.isArray(value);
      const rangeFilter = isObject ? ranges.find(filter => filter.name === key) : null;

      if (rangeFilter) {
        Object.entries(value).forEach(([range, rangeValue]) => {
          if (rangeFilter.type === "date") {
            rangeValue = new Date(rangeValue as string).toISOString();
          }

          const query = `filter[${key}][${range}]=${rangeValue}`;
          queryParams.push(query);
        });
      }
    }

    return queryParams;
  }
}
