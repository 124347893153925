import { Component, Output, EventEmitter } from "@angular/core";
import { IWalletBalance } from "src/app/models/wallet";
import { FavoritesService } from "src/app/services/favorites.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  TOKEN = TOKEN;

  selectedTab: string;
  favCount: number;
  walletInfo: IWalletBalance = {
    balanceRemain: 0,
    totalInvested: 0,
    balanceFrozen: 0,
  };

  constructor(private _favoritesService: FavoritesService, private _walletService: WalletService) {}

  ngOnInit() {
    this._favoritesService.favoriteList$.subscribe(value => {
      this.favCount = value.length;
    });

    this._walletService.walletBalance$.subscribe(balance => {
      this.walletInfo = balance;
    });
  }

  @Output() onDepositOpen = new EventEmitter();

  openDepositModal() {
    this.onDepositOpen.emit();
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
