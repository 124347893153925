import { Component, OnInit } from "@angular/core";
import { TRANSACTION_MANAGER_STATUS } from "src/app/models/transaction-manager";
import { TransactionsService } from "src/app/services/transactions.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.scss"],
})
export class AdminSidebarComponent implements OnInit {
  adminLinks = [
    {
      name: "Requests for input/output",
      link: "requests",
    },
    { name: "Users", link: "users" },
    { name: "Properties", link: "properties" },
    { name: "Transactions", link: "transactions" },
    { name: "Monetization", link: "monetization" },
  ];

  adminTmStatus: TRANSACTION_MANAGER_STATUS;
  clientTmStatus: TRANSACTION_MANAGER_STATUS;
  indicatorVariant: TRANSACTION_MANAGER_STATUS;

  constructor(
    private _authService: AuthService,
    private _transactionsService: TransactionsService
  ) {
    this._transactionsService.adminTmStatus$.subscribe(data => {
      this.adminTmStatus = data?.status;
    });
    this._transactionsService.clientTmStatus$.subscribe(data => {
      this.clientTmStatus = data?.status;
    });
  }

  ngOnInit(): void {
    this._transactionsService.getTransactionManagerStatus("admin");
    this._transactionsService.getTransactionManagerStatus("client");
  }

  handleLogout() {
    this._authService.adminLogout();
  }

  getTmStatus() {
    if (
      this.adminTmStatus === TRANSACTION_MANAGER_STATUS.ACTIVE &&
      this.clientTmStatus === TRANSACTION_MANAGER_STATUS.ACTIVE
    ) {
      return TRANSACTION_MANAGER_STATUS.ACTIVE;
    } else if (
      this.adminTmStatus === TRANSACTION_MANAGER_STATUS.FAILED ||
      this.clientTmStatus === TRANSACTION_MANAGER_STATUS.FAILED
    ) {
      return TRANSACTION_MANAGER_STATUS.FAILED;
    } else {
      return TRANSACTION_MANAGER_STATUS.STOPPED;
    }
  }
}
