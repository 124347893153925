<div class="sct">
  <form class="sct__form" [formGroup]="scFrom" id="scForm" #scForm="ngForm" (ngSubmit)="submit()">
    <h3 class="title title_sub">Smart-contract information</h3>
    <div class="sct__formRow">
      <app-input caption="Token name" formControlName="name"></app-input>
      <app-input caption="Ticker" formControlName="ticker" appTicker></app-input>
    </div>
    <app-input caption="Area in token, m²" formControlName="squarePerToken"></app-input>
  </form>

  <ng-template [ngIf]="isDraft === false">
    <div class="sct__info">
      <h4>Property token info</h4>
      <p>
        Number of tokens <span>{{ propertySmartContractData?.amount | prettifyNumber }}</span>
      </p>
      <p>
        Token cost <span>{{ propertySmartContractData?.price | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <p>
        Min investment <span>{{ investInfo?.minInvestAmount }}</span>
      </p>
      <p>
        Smart contract link
        <a
          [href]="'https://testnet.bscscan.com/address/' + propertySmartContractData?.scId"
          target="_blank"
        >
          {{ propertySmartContractData?.scId | shortenText }}</a
        >
      </p>

      <div class="sct__bar">
        <app-progress-bar [percentage]="calculatePercentage()"></app-progress-bar>
        <p>{{ calculatePercentage() + "%" }}</p>
      </div>

      <div class="sct__barDescr">
        <p>
          Collected <span>{{ investInfo?.collected | prettifyNumber }} {{ TOKEN }}</span>
        </p>
        <p>
          Target <span>{{ investInfo?.totalPrice | prettifyNumber }} {{ TOKEN }}</span>
        </p>
      </div>
    </div>
  </ng-template>
</div>
