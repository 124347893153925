<section class="settings">
  <app-main-header [headline]="'SETTINGS.SETTINGS' | translate"></app-main-header>

  <app-tabs
    [tabs]="tabs"
    [currentTabNumber]="activeTab"
    (tabSelected)="switchTab($event)"
  ></app-tabs>

  <ng-container *ngIf="activeTab === 0 && userSettings">
    <div class="general">
      <div class="general__userWrapper">
        <div class="general__select-wrapper">
          <span>{{ "SETTINGS.CURRENCY" | translate }}</span>
          <app-select
            *ngIf="userSettings != null && currencies != null"
            [items]="currencies"
            [img]="'assets/icons/globe.svg'"
            [selectedItem]="currencies[0]"
          ></app-select>
        </div>
        <div class="general__select-wrapper">
          <span>{{ "SETTINGS.LANGUAGE" | translate }}</span>
          <app-language-selector
            (languageChanged)="onLanguageChange($event)"
          ></app-language-selector>
        </div>
      </div>

      <button class="button" (click)="saveChanges()">
        {{ "SETTINGS.SAVE" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 1 && userIdentity">
    <div class="general">
      <div>
        <h3 class="personal__name">{{ userIdentity.firstName }} {{ userIdentity.secondName }}</h3>
        <div class="personal__phone">
          <img src="assets/icons/phone.svg" alt="phone" /> {{ userIdentity.phoneNumber }}
        </div>
      </div>

      <div class="general__select-wrapper">
        <span>{{ "SETTINGS.ADDRESS" | translate }}</span>
        <div class="personal__editWrapper">
          <label class="verification">
            <span class="general__field">{{ renderAddressString() }}</span>
          </label>
          <button class="button button_outline" (click)="openChangeAddressModal()">
            {{ "SETTINGS.CHANGE_ADDRESS" | translate }}
          </button>
        </div>
      </div>

      <div class="general__userWrapper">
        <div class="general__select-wrapper">
          <span>{{ "SETTINGS.EMAIL" | translate }}</span>
          <div class="personal__editWrapper">
            <label class="verification">
              <span class="general__field">{{ userEmail }}</span>
            </label>
            <button class="button button_outline" (click)="openChangeEmailModal()">
              {{ "SETTINGS.CHANGE_EMAIL" | translate }}
            </button>
          </div>
        </div>

        <div class="general__select-wrapper">
          <span>{{ "SETTINGS.VERIFICATION" | translate }}</span>
          <div class="personal__editWrapper">
            <label class="verification">
              <ng-container [ngSwitch]="verificationStatus">
                <ng-template [ngSwitchCase]="VerificationType.REQUIRED">
                  <img src="assets/icons/shield_empty.svg" alt="Required" />
                  <span class="general__field">{{ "SETTINGS.REQUIRED" | translate }}</span>
                </ng-template>
                <ng-template [ngSwitchCase]="VerificationType.FAILED">
                  <img src="assets/icons/shield_failed.svg" alt="Failed" />
                  <span class="general__field">{{ "SETTINGS.FAILED" | translate }}</span>
                </ng-template>
                <ng-template [ngSwitchCase]="VerificationType.RETRY">
                  <img src="assets/icons/shield_failed.svg" alt="Retry" />
                  <span class="general__field">{{ "SETTINGS.RETRY" | translate }}</span>
                </ng-template>
                <ng-template [ngSwitchCase]="VerificationType.PENDING">
                  <img src="assets/icons/shield_pending.svg" alt="Pending" />
                  <span class="general__field">{{ "SETTINGS.PENDING" | translate }}</span>
                </ng-template>
                <ng-template [ngSwitchCase]="VerificationType.COMPLETE">
                  <img src="assets/icons/shield_complete.svg" alt="Complete" />
                  <span class="general__field">{{ "SETTINGS.COMPLETE" | translate }}</span>
                </ng-template>
              </ng-container>
            </label>

            <button
              class="button"
              [disabled]="verificationStatus === VerificationType.COMPLETE"
              (click)="openVerificationOfferModal()"
            >
              {{ "SETTINGS.VERIFY_ACCOUNT" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 2">
    <div class="general">
      <h3 class="general__legalTitle">{{ "SETTINGS.DOCUMENTS" | translate }}</h3>
      <div class="general__legalDocs">
        <div class="general__legalRow">
          <h4>{{ "SETTINGS.CUSTOMER_AGREEMENT" | translate }}</h4>
          <button>
            <img src="assets/icons/download.svg" alt="download" />
          </button>
        </div>
        <div class="general__legalRow">
          <h4>{{ "SETTINGS.PAYMENT_TERMS" | translate }}</h4>
          <button>
            <img src="assets/icons/download.svg" alt="download" />
          </button>
        </div>
        <div class="general__legalRow">
          <h4>{{ "SETTINGS.AFFILIATE_PROGRAM" | translate }}</h4>
          <button>
            <img src="assets/icons/download.svg" alt="download" />
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<jw-modal class="modal" id="verificationOffer">
  <div class="voModal">
    <div class="voModal__header">
      <h3>{{ "SETTINGS.COMPLETE_VERIFICATION" | translate }}</h3>
      <button (click)="closeVerificationOfferModal()">&#10006;</button>
    </div>
    <div class="voModal__body">
      <span>{{ "SETTINGS.IT_WILL_TAKE" | translate }}</span>
      <p>{{ "SETTINGS.PLEASE_PREPARE" | translate }}</p>
    </div>
    <div class="voModal__footer">
      <button class="button button_outline" (click)="closeVerificationOfferModal()">
        {{ "SETTINGS.MAYBE_LATER" | translate }}
      </button>
      <button class="button" (click)="handleStartVerification()">
        {{ "SETTINGS.START_VERIFICATION" | translate }}
      </button>
    </div>
  </div>
</jw-modal>

<jw-modal id="change-address">
  <div class="infoModal changeAddressModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">{{ "SETTINGS.CHANGE_ADDRESS" | translate }}</h4>
      <button (click)="closeChangeAddressModal()">&#10006;</button>
    </div>
    <div class="changeAddressModal__fields" [formGroup]="changeAddressForm">
      <app-input [caption]="'SETTINGS.STREET' | translate" formControlName="street"></app-input>
      <app-input [caption]="'SETTINGS.CITY' | translate" formControlName="city"></app-input>
      <app-input
        [caption]="'SETTINGS.POSTAL_CODE' | translate"
        formControlName="postalCode"
      ></app-input>
      <div class="countrySelectWrapper">
        <span>{{ "SETTINGS.COUNTRY" | translate }}</span>
        <app-select
          [items]="getCountriesNames()"
          [selectedItem]="countryCodeToName(changeAddressForm.controls.country.value)"
          (pick)="setCountry($event)"
        ></app-select>
      </div>
    </div>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closeChangeAddressModal()">
        {{ "SETTINGS.CANCEL" | translate }}
      </button>
      <button class="button" (click)="changeAddress()">{{ "SETTINGS.SAVE" | translate }}</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="sure-to-change-email">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">{{ "SETTINGS.SURE_TO_CHANGE_EMAIL" | translate }}</h4>
      <button (click)="closeChangeEmailModal()">&#10006;</button>
    </div>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closeChangeEmailModal()">
        {{ "SETTINGS.CANCEL" | translate }}
      </button>
      <button class="button" routerLink="/email-change">
        {{ "SETTINGS.CHANGE_EMAIL" | translate }}
      </button>
    </div>
  </div>
</jw-modal>

<jw-modal id="successful-email-change">
  <div class="infoModal">
    <div class="infoModal__header">
      <h4 class="title title_sub">{{ "SETTINGS.EMAIL_CHANGED" | translate }}</h4>
      <button (click)="closeSuccessfulEmailChangeModal()">&#10006;</button>
    </div>
    <div class="infoModal__footer">
      <button class="button" (click)="closeSuccessfulEmailChangeModal()">
        {{ "P2P.CLOSE" | translate }}
      </button>
    </div>
  </div>
</jw-modal>
