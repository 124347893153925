import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import {
  IMonetizationListItem,
  MONETIZATION_TYPE,
  PERIOD_VARIANTS,
} from "src/app/models/dividends";
import { DividendsService } from "src/app/services/dividends.service";

@Component({
  selector: "app-monetization-table",
  templateUrl: "./monetization-table.component.html",
  styleUrls: ["./monetization-table.component.scss"],
})
export class MonetizationTableComponent implements OnInit {
  monetization = new MatTableDataSource<IMonetizationListItem>();
  monetizationColumns = ["ticker", "property_name", "starts_at", "planned_starts_at", "income"];
  monetizationPage = 0;
  monetizationPageSize = 10;
  monetizationTotal = 0;
  monetizationIsActiveFilter = true;
  monetizationSort: string[] = [];

  searchField: string = "";

  MonetizationType = MONETIZATION_TYPE;

  constructor(private _router: Router, private _dividendsService: DividendsService) {}

  ngOnInit(): void {
    this.requestMonetizations();
  }

  async requestMonetizations() {
    const filters = this.createFilters();
    const res = await this._dividendsService.getMonetizations(
      this.monetizationPage,
      this.monetizationPageSize,
      filters
    );
    this.monetization.data = res.items;
    this.monetizationTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  openMonetizationEditPage(id: string) {
    this._router.navigateByUrl(`/admin/monetization/${id}/edit`);
  }

  applyMonetizationFilters() {
    this.monetizationPage = 0;
    this.requestMonetizations();
  }

  onMonetizationSort(sort: Sort) {
    this.monetizationSort = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.applyMonetizationFilters();
  }

  onMonetizationPageChange(e: PageEvent) {
    this.monetizationPage = e.pageIndex;
    this.requestMonetizations();
  }

  periodToName(period: PERIOD_VARIANTS) {
    switch (period) {
      case PERIOD_VARIANTS.MONTH:
        return "mo";
      case PERIOD_VARIANTS.QUARTER:
        return "q";
      case PERIOD_VARIANTS.YEAR:
        return "year";
    }
  }

  private createFilters() {
    const filtersArray = [];

    if (this.searchField.trim()) {
      filtersArray.push(`search=${this.searchField}`);
    }

    filtersArray.push(`filter[isActive]=${this.monetizationIsActiveFilter}`);

    if (this.monetizationSort.length) {
      filtersArray.push(...this.monetizationSort);
    }

    return filtersArray;
  }
}
