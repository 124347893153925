import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { LAYOUT_VARIANT } from "../models/filters";
import { IUserIdentity, IUserSettings, LANGUAGE_TAGS } from "../models/settings";
import { BaseRequestService } from "./base-request.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  identity: IUserIdentity = undefined;
  layoutType = LAYOUT_VARIANT.GRID;
  private userSettings = new BehaviorSubject<IUserSettings>(null);

  layoutVariantChange = new Subject<LAYOUT_VARIANT>();
  identityChange = new Subject<IUserIdentity>();
  userSettings$ = this.userSettings.asObservable();

  constructor(public translate: TranslateService, private _api: BaseRequestService) {
    translate.addLangs([LANGUAGE_TAGS.ENGLISH.toLowerCase(), LANGUAGE_TAGS.CZECH.toLowerCase()]);
    translate.setDefaultLang(LANGUAGE_TAGS.ENGLISH.toLowerCase());

    this.layoutVariantChange.subscribe(value => {
      this.layoutType = value;
    });
    this.getLayoutVariant();

    this.identityChange.subscribe(value => {
      this.identity = value;
    });
  }

  async changeLanguage(lang: LANGUAGE_TAGS) {
    this.translate.use(lang.toLowerCase());
    await this._api.put("/settings/language", { language: lang.toUpperCase() });
  }

  async getUserSettings() {
    const response = await this._api.get<IUserSettings>("/settings");
    this.userSettings.next(response);
    return response;
  }

  async getUserIdentity() {
    const resp = await this._api.get<IUserIdentity>("/identity");
    this.identityChange.next(resp);
    return resp;
  }

  async updateUserIdentity(newIdentity: IUserIdentity) {
    try {
      await this._api.put("/identity", newIdentity);
      this.identityChange.next(newIdentity);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  setLayoutVariant(variant: LAYOUT_VARIANT) {
    this.layoutVariantChange.next(variant);
    localStorage.setItem("layout-variant", JSON.stringify(variant));
  }

  getLayoutVariant() {
    const variant = JSON.parse(localStorage.getItem("layout-variant"));
    if (variant) {
      this.setLayoutVariant(variant);
    }
  }
}
