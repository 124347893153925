<app-input
  type="search"
  placeholder="Search by name or ticker"
  icon="search"
  [(ngModel)]="searchField"
  (onSearchClick)="applyHistoryFilters()"
  (keyup.enter)="applyHistoryFilters()"
></app-input>

<div class="phTable">
  <table
    mat-table
    [dataSource]="payoutHistory"
    matSort
    (matSortChange)="onHistorySort($event)"
    [matSortActive]="payoutHistoryColumns[4]"
    matSortDirection="asc"
    class="matTable"
  >
    <ng-container [matColumnDef]="payoutHistoryColumns[0]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
      <td mat-cell *matCellDef="let prop">
        {{ prop.token.ticker }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="payoutHistoryColumns[1]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Object</th>
      <td mat-cell *matCellDef="let prop">{{ prop.property.name }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutHistoryColumns[2]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Investors</th>
      <td mat-cell *matCellDef="let prop">{{ prop.sharedCount | prettifyNumber }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutHistoryColumns[3]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dividends</th>
      <td mat-cell *matCellDef="let prop">{{ prop.euroAmount | prettifyNumber }} EUR</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutHistoryColumns[4]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment date</th>
      <td mat-cell *matCellDef="let prop">{{ prop.paymentDate | prettifyDate }}</td>
    </ng-container>
    <ng-container [matColumnDef]="payoutHistoryColumns[5]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let prop"
        [class.phTable__status_success]="prop.status === SnapshotStatus.COMPLETED"
        [class.phTable__status_pale]="prop.status === SnapshotStatus.NOT_STARTED"
      >
        {{ renderStatus(prop.status) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="payoutHistoryColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: payoutHistoryColumns"
      (click)="openDetailsPage(row)"
    ></tr>
  </table>

  <mat-paginator
    [pageIndex]="payoutHistoryPage"
    [pageSize]="payoutHistoryPageSize"
    [length]="payoutHistoryTotal"
    (page)="onHistoryPageChange($event)"
    aria-label="Select page"
  ></mat-paginator>
</div>
