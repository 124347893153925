<div class="container header">
  <a class="header__logo" href="/">
    <img src="../../../assets/icons/logo.svg" alt="Advanced R&D" />
  </a>
  <div class="header__right">
    <app-language-selector></app-language-selector>

    <button class="button" routerLink="/login" *ngIf="loggedIn === false; else logoutBtn">
      {{ "LOGIN.LOGIN_OR_SIGNUP" | translate }}
    </button>

    <ng-template #logoutBtn>
      <app-logout-button></app-logout-button>
    </ng-template>
  </div>
</div>
