import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { IP2pListObject, P2pRequestType } from "src/app/models/p2p";
import { FiltersService } from "src/app/services/filters.service";
import { P2pService } from "src/app/services/p2p.service";

@Component({
  templateUrl: "./p2p.component.html",
  styleUrls: ["./p2p.component.scss"],
})
export class P2pComponent implements OnInit {
  tabs = ["P2P.TAB_BUY", "P2P.TAB_SELL"];
  currentTabNumber = 0;
  currentTabChange = new Subject<typeof this.currentTabNumber>();

  buyFragments: IP2pListObject[] = [];
  buyPage = 0;
  isBuyPagesLimit = false;
  isBuyLoading = true;

  sellFragments: IP2pListObject[] = [];
  sellPage = 0;
  isSellPagesLimit = false;
  isSellLoading = true;

  private querySubscription: Subscription;

  constructor(
    private _router: Router,
    private _p2pService: P2pService,
    private _filtersService: FiltersService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentTabChange.subscribe(tab => {
      this.currentTabNumber = tab;

      if (this.currentTabNumber == 0) {
        this.applyBuyFilters();
      } else if (this.currentTabNumber == 1) {
        this.applySellFilters();
      }
    });

    this.querySubscription = this._activatedRoute.queryParams.subscribe(q => {
      const currentTabNum = q["currentTab"] || 0;
      if (currentTabNum <= this.tabs.length && currentTabNum >= 0) {
        this.currentTabChange.next(currentTabNum);
      }
    });
  }

  async getBuyFragments() {
    const items = await this.getFragments(this.buyPage, "sell", "isBuyPagesLimit", "isBuyLoading");
    this.buyFragments.push(...items);
  }

  async getSellFragments() {
    const items = await this.getFragments(
      this.sellPage,
      "buy",
      "isSellPagesLimit",
      "isSellLoading"
    );
    this.sellFragments.push(...items);
  }

  switchTab(tabNumber: number) {
    this._filtersService.filterBarFilters = null;
    this._filtersService.filtersQuery = [];
  }

  openBuyPage(id: string) {
    this._router.navigateByUrl(`/dashboard/p2p/${id}/buy`);
  }

  openSellPage(id: string) {
    this._router.navigateByUrl(`/dashboard/p2p/${id}/sell`);
  }

  applyBuyFilters() {
    this.buyFragments = [];
    this.buyPage = 0;
    this.isBuyLoading = true;
    this.getBuyFragments();
  }

  buyLoadMore() {
    this.buyPage++;
    this.getBuyFragments();
  }

  applySellFilters() {
    this.sellFragments = [];
    this.sellPage = 0;
    this.isSellLoading = true;
    this.getSellFragments();
  }

  sellLoadMore() {
    this.sellPage++;
    this.getSellFragments();
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  private async getFragments(
    page: number,
    type: P2pRequestType,
    pageLimitName: string,
    isLoadingName: string
  ) {
    try {
      const res = await this._p2pService.getListing(page, type, this._filtersService.filtersQuery);
      const isLastPage = page + 1 === res.pagination.totalPages || res.pagination.totalPages === 0;
      // @ts-ignore
      this[pageLimitName] = isLastPage;
      return res.items;
    } catch (error) {
      return [];
    } finally {
      // @ts-ignore
      this[isLoadingName] = false;
    }
  }
}
