import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AREA_IN_TOKEN } from "src/app/constants/token-area.constant";

@Component({
  selector: "app-tokens-area-input",
  templateUrl: "./tokens-area-input.component.html",
  styleUrls: ["./tokens-area-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TokensAreaInputComponent),
      multi: true,
    },
  ],
})
export class TokensAreaInputComponent implements OnInit {
  @Input() minTokens: number;
  @Input() maxTokens: number;
  @Input() tokenName: string;
  @Input() minSqm: number;
  @Input() maxSqm: number;
  @Input() sqmInToken: number = AREA_IN_TOKEN;
  @Input() errorTokens: string = "";

  value: number = null;
  sqm: number;

  constructor() {}

  ngOnInit(): void {}

  private onChange: any = (_: any) => {};
  private onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: number): void {
    this.value = outsideValue;

    if (this.value) {
      this.convertTokensToSqm();
    }
  }

  updateValue(insideValue: number) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }

  convertTokensToSqm() {
    this.sqm = +(this.value * this.sqmInToken).toFixed(2);
  }

  convertSqmToTokens() {
    const newValue = +(this.sqm / this.sqmInToken).toFixed(0);
    this.updateValue(newValue);
  }

  handleOnlyPositiveNumbers(event: any) {
    return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  getPxWidthOfString(text: string) {
    const testFontSize = "2rem";
    const testFontFamily = "Roboto";

    const testElement = document.createElement("span");
    testElement.style.fontSize = testFontSize;
    testElement.style.fontFamily = testFontFamily;
    testElement.innerHTML = text;

    document.body.appendChild(testElement);

    const wordWidth = testElement.offsetWidth;

    document.body.removeChild(testElement);

    return `${wordWidth}px`;
  }

  setMax() {
    this.updateValue(this.maxTokens);
    this.convertTokensToSqm();
  }
}
