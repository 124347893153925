import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appIntegerNumber]",
})
export class IntegerNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"])
  onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    const withoutZeroesValue = value.replace(/^0+/, "");
    const integerValue = withoutZeroesValue.replace(/[^0-9]/g, "");

    input.value = integerValue;
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === "." || event.key === ",") {
      event.preventDefault();
    }
  }
}
