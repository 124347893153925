import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BaseRequestService } from "./base-request.service";
import { IResponseWithPagination } from "../models/api-response";
import { IProperty } from "../models/property";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  private favoriteList = new BehaviorSubject<string[]>([]);
  public favoriteList$ = this.favoriteList.asObservable();

  constructor(private _api: BaseRequestService) {}

  async getFavorites(page: number = 0) {
    const res = await this._api.get<IResponseWithPagination<IProperty>>(
      `/properties/favorites?page=${page}&perPage=200`
    );
    const ids = res.items.map(i => i.id);
    const newFavs = new Set([...this.favoriteList.getValue(), ...ids]);
    this.favoriteList.next([...newFavs]);
    return res;
  }

  async addPropertyToFav(propertyId: string) {
    await this._api.post("/properties/favorites", { ids: [propertyId] });

    const uniques = new Set(this.favoriteList.getValue());
    uniques.add(propertyId);
    this.favoriteList.next([...uniques]);
  }

  async deletePropertyFromFav(propertyId: string) {
    await this._api.delete("/properties/favorites", { ids: [propertyId] });

    const newArr = [...this.favoriteList.getValue()];
    const fIdx = newArr.findIndex(el => el === propertyId);
    newArr.splice(fIdx, 1);
    this.favoriteList.next(newArr);
  }

  clearLocalFavoritesList() {
    this.favoriteList.next([]);
  }
}
