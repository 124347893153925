<main class="portfolio">
  <app-main-header [headline]="'PORTFOLIO.TITLE' | translate"></app-main-header>

  <section class="portfolio__info">
    <div class="portfolio__infoItem">
      <p>{{ "PORTFOLIO.INCOME" | translate }}</p>
      <h4 *ngIf="portfolioResponse">
        {{ portfolioResponse.meta.totalStats.totalIncome | prettifyNumber }} {{ TOKEN }}
      </h4>
      <ngx-skeleton-loader
        *ngIf="!portfolioResponse"
        [theme]="{ height: '1.5rem', margin: 0 }"
      ></ngx-skeleton-loader>
    </div>
    <div class="portfolio__infoItem">
      <p>{{ "PORTFOLIO.AMOUNT_INVESTED" | translate }}</p>
      <h4 *ngIf="portfolioResponse">
        {{ portfolioResponse.meta.totalStats.totalInvested | prettifyNumber }} {{ TOKEN }}
      </h4>
      <ngx-skeleton-loader
        *ngIf="!portfolioResponse"
        [theme]="{ height: '1.5rem', margin: 0 }"
      ></ngx-skeleton-loader>
    </div>
    <div class="portfolio__infoItem">
      <p>{{ "PORTFOLIO.PROPERTIES_INVESTED" | translate }}</p>
      <h4 *ngIf="portfolioResponse">
        {{ portfolioResponse.meta.totalStats.propertiesAmount | prettifyNumber }}
      </h4>
      <ngx-skeleton-loader
        *ngIf="!portfolioResponse"
        [theme]="{ height: '1.5rem', margin: 0 }"
      ></ngx-skeleton-loader>
    </div>
    <div class="portfolio__infoItem">
      <p>{{ "PORTFOLIO.PURCHASED_QUANTITY" | translate }}</p>
      <h4 *ngIf="portfolioResponse">
        {{ portfolioResponse.meta.totalStats.totalQuantity | prettifyNumber }} m²
      </h4>
      <ngx-skeleton-loader
        *ngIf="!portfolioResponse"
        [theme]="{ height: '1.5rem', margin: 0 }"
      ></ngx-skeleton-loader>
    </div>
  </section>

  <app-tabs
    [tabs]="tabs"
    [currentTabNumber]="currentTab"
    (tabSelected)="switchTab($event)"
  ></app-tabs>

  <ng-container *ngIf="currentTab === 0">
    <div class="portfolio__filterBlock">
      <app-input
        type="search"
        icon="search"
        placeholder="Search"
        [(ngModel)]="propertySearch"
        (onSearchClick)="performSearchProperty()"
        (keyup.enter)="performSearchProperty()"
      ></app-input>
      <app-select
        [items]="getPropertySortingNames()"
        [selectedItem]="currentPropertySort.name"
        (pick)="performPropertySort($event)"
      ></app-select>
    </div>

    <ng-container *ngIf="!isPropertiesLoading; else propertyLoader">
      <div *ngIf="pendingProperties.length > 0 || ownedProperties.length > 0; else emptyList">
        <div class="portfolio__properties">
          <app-property-card
            *ngFor="let item of pendingProperties"
            [data]="item"
            [isUserDetails]="true"
            cardType="userDetails"
            (onClick)="openPropertyPage(item.id)"
            status="pending"
          >
          </app-property-card>
        </div>

        <hr
          *ngIf="pendingProperties.length > 0 && ownedProperties.length > 0"
          class="portfolio__divider"
        />

        <div class="portfolio__properties">
          <app-property-card
            *ngFor="let item of ownedProperties"
            [data]="item"
            [isUserDetails]="true"
            cardType="userDetails"
            (onClick)="openPropertyPage(item.id)"
          >
          </app-property-card>
        </div>

        <button
          *ngIf="ownedHasMorePages"
          class="button button_light activities__loadBtn"
          (click)="loadMoreProperties()"
        >
          <img src="assets/icons/refresh.svg" alt="refresh" />
          {{ "PROPERTIES.LOAD_MORE" | translate }}
        </button>
      </div>
    </ng-container>

    <ng-template #propertyLoader>
      <div class="portfolio__properties">
        <app-card-skeleton></app-card-skeleton>
        <app-card-skeleton></app-card-skeleton>
        <app-card-skeleton></app-card-skeleton>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="currentTab === 1">
    <app-input
      type="search"
      placeholder="Search"
      icon="search"
      [(ngModel)]="activitySearch"
      (onSearchClick)="performActivitySearch()"
      (keyup.enter)="performActivitySearch()"
    ></app-input>

    <app-loader *ngIf="isActivityLoading"></app-loader>

    <ng-container *ngIf="!isActivityLoading">
      <div *ngIf="userActivities.data.length > 0; else emptyList" class="activities__table">
        <table
          mat-table
          [dataSource]="userActivities"
          matSort
          class="matTable"
          (matSortChange)="onActivitySort($event)"
        >
          <ng-container [matColumnDef]="activitiesColumns[0]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "PROPERTY_ACTIVITY.DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let prop" [title]="prop.createdAt | prettifyDate : 'full'">
              {{ prop.createdAt | prettifyDate }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="activitiesColumns[1]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "PROPERTY_ACTIVITY.PROPERTY" | translate }}
            </th>
            <td mat-cell *matCellDef="let prop">{{ prop.propertyName }}</td>
          </ng-container>
          <ng-container [matColumnDef]="activitiesColumns[2]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "PROPERTY_ACTIVITY.TYPE" | translate }}
            </th>
            <td mat-cell *matCellDef="let prop">
              {{ renderTransactionType(prop.type) }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="activitiesColumns[3]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "PROPERTY_ACTIVITY.PURCHASED_AREA" | translate }}
            </th>
            <td mat-cell *matCellDef="let prop">
              {{ prop.tokensAmount }} {{ prop.tokenTicker }} ({{
                calculateAreaFromTokens(prop.tokensAmount)
              }}
              m²)
            </td>
          </ng-container>
          <ng-container [matColumnDef]="activitiesColumns[4]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "PROPERTY_ACTIVITY.PURCHASED_AMOUNT" | translate }}
            </th>
            <td mat-cell *matCellDef="let prop">
              {{ prop.adrdAmount | prettifyNumber }} {{ TOKEN }}
            </td>
          </ng-container>

          <ng-container [matColumnDef]="activitiesColumns[5]">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let prop">
              <button class="activities__eyeButton" (click)="openTransactionModal(prop)">
                <img src="assets/icons/eye.svg" alt="show" />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="activitiesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: activitiesColumns"></tr>
        </table>

        <button
          class="button button_light activities__loadBtn"
          (click)="loadMoreTransactions()"
          *ngIf="!isTransactionsLimitReached"
        >
          <img src="assets/icons/refresh.svg" alt="refresh" />
          {{ "PROPERTIES.LOAD_MORE" | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #emptyList>
    <div class="no-properties">
      <img src="assets/icons/sad-smile.svg" alt="" />
      <p>{{ "PORTFOLIO.NO_PROPERTIES" | translate }}</p>
      <button class="button" routerLink="/dashboard/properties">
        {{ "PORTFOLIO.BUY_PROPERTY" | translate }}
      </button>
    </div>
  </ng-template>
</main>

<jw-modal id="transaction-details-modal">
  <div class="infoModal tdm" *ngIf="selectedActivity">
    <div class="infoModal__header">
      <h4 class="title title_sub">{{ "PORTFOLIO.TRANSACTION_DETAILS" | translate }}</h4>
      <button (click)="closeTransactionModal()">&#10006;</button>
    </div>
    <div class="tdm__fields">
      <p>
        {{ "PROPERTY_ACTIVITY.DATE" | translate }}
        <span>{{ selectedActivity.createdAt | prettifyDate : "full" }}</span>
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.PROPERTY" | translate }}
        <span>{{ selectedActivity.propertyName }}</span>
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.PROPERTY" | translate }} ID
        <span>{{ selectedActivity.propertyId }}</span>
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.PURCHASED_AREA" | translate }}
        <span
          >{{ selectedActivity.tokensAmount }} {{ selectedActivity.tokenTicker }} ({{
            calculateAreaFromTokens(selectedActivity.tokensAmount)
          }}
          m²)</span
        >
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.PURCHASED_AMOUNT" | translate }}
        <span>{{ selectedActivity.adrdAmount }} {{ TOKEN }}</span>
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.STATUS" | translate }}
        <span>{{ renderActivityStatus(selectedActivity.status) }}</span>
      </p>
      <p>
        {{ "PROPERTY_ACTIVITY.TYPE" | translate }}
        <span>{{ renderTransactionType(selectedActivity.type) }}</span>
      </p>
      <p></p>
    </div>
  </div>
</jw-modal>
