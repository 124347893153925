<section class="adminProp">
  <div class="adminProp__header">
    <h2 class="title">Properties</h2>
    <a class="button" routerLink="/admin/properties/create">Add property</a>
  </div>

  <app-filter-bar
    [showSorting]="false"
    [showLayoutButtons]="false"
    usedIn="adminProperties"
    (onSearch)="onSearchApply()"
    (onFiltersApply)="onFiltersApply()"
  ></app-filter-bar>

  <div class="adminProp__table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table"
      matSort
      (matSortChange)="onTableSorting($event)"
    >
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
        <td mat-cell *matCellDef="let prop">{{ prop.ticker ?? "-" }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.name">{{ prop.name }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.investStartsAt ? (prop.investStartsAt | prettifyDate) : "-" }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.investEndAt ? (prop.investEndAt | prettifyDate) : "-" }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Target</th>
        <td mat-cell *matCellDef="let prop">{{ prop.totalPrice | prettifyNumber }} {{ TOKEN }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Collected</th>
        <td mat-cell *matCellDef="let prop">{{ prop.collected | prettifyNumber }} {{ TOKEN }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[6]">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by progress"
        >
          Progress
        </th>
        <td mat-cell *matCellDef="let prop">{{ getProgress(prop.progress) | prettifyNumber }}%</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[7]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td
          mat-cell
          *matCellDef="let prop"
          [ngClass]="{
            'mat-column-status_collected': prop.status === 2,
            'mat-column-status_collecting': prop.status === 1,
            'mat-column-status_failed': prop.status === 3
          }"
        >
          {{ renderStatus(prop.status) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="'/admin/properties/' + row.id"
      ></tr>
    </table>

    <mat-paginator aria-label="Select page" (page)="onPageChange($event)"></mat-paginator>
  </div>
</section>
