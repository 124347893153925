<section class="users">
  <div class="users__header">
    <h2 class="title">Users</h2>
  </div>

  <app-input
    type="search"
    icon="search"
    placeholder="Search by name"
    [(ngModel)]="searchField"
    (onSearchClick)="applyFilters()"
    (keyup.enter)="applyFilters()"
  ></app-input>

  <div class="users__table">
    <table
      mat-table
      [dataSource]="users"
      matSort
      (matSortChange)="handleSort($event)"
      [matSortActive]="usersColumns[6]"
      matSortDirection="desc"
      class="matTable"
    >
      <ng-container [matColumnDef]="usersColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.email }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verification</th>
        <td mat-cell *matCellDef="let prop">
          {{ renderVerificationStatus(prop.sumsub.verificationStatus) }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Wallet balance</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.wallet.balance | prettifyNumber }} {{ TOKEN }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>In investments</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.totalInvested | prettifyNumber }} {{ TOKEN }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Portfolio balance</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.totalBalance | prettifyNumber }} {{ TOKEN }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchased objects</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.propertiesCount | prettifyNumber }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="usersColumns[6]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Registration date</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.createdAt | prettifyDate : 'full'">
          {{ prop.createdAt | prettifyDate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: usersColumns" (click)="openUserPage(row.id)"></tr>
    </table>

    <mat-paginator
      [pageIndex]="usersPage"
      [pageSize]="usersPageSize"
      [length]="usersTotal"
      (page)="handlePageChange($event)"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</section>
