<div class="customInput">
  <div *ngIf="caption || note" class="customInput__top">
    <span *ngIf="caption" class="customInput__caption">{{ caption }}</span>
    <span *ngIf="note" class="customInput__note">{{ note }}</span>
    <img
      *ngIf="tooltip"
      class="customInput__tooltip"
      [matTooltip]="tooltip"
      src="assets/icons/help.svg"
      alt="info"
    />
  </div>

  <label
    class="customInput__inputWrapper"
    [ngClass]="{
      customInput__inputWrapper_withCopyButton: withCopyButton,
      customInput__inputWrapper_withSendButton: withSendButton,
      customInput__inputWrapper_error: error
    }"
  >
    <img *ngIf="icon === 'search'" src="assets/icons/search.svg" alt="search" />
    <img *ngIf="icon === 'mail'" src="assets/icons/mail.svg" alt="email" />

    <ng-template [ngIf]="tag === 'input'">
      <ng-container *ngIf="type !== 'date' && type !== 'datetime'; else dateInput">
        <input
          #inputElement
          [type]="type === 'search' ? 'text' : type"
          [placeholder]="placeholder"
          [disabled]="disabled"
          [ngModel]="value"
          (ngModelChange)="updateValue($event)"
          maxlength="255"
        />
      </ng-container>
      <ng-template #dateInput>
        <input
          #dateInputElement
          matInput
          [matDatepicker]="datetimePicker"
          [disabled]="disabled"
          [ngModel]="value"
          (ngModelChange)="updateValue($event)"
          appDateMask
        />
        <mat-datepicker-toggle [for]="datetimePicker" disableRipple>
          <img matDatepickerToggleIcon src="assets/icons/calendar.svg" alt="calendar" />
        </mat-datepicker-toggle>
        <mat-datepicker [type]="type" [twelveHour]="false" #datetimePicker></mat-datepicker>
      </ng-template>
    </ng-template>

    <ng-template [ngIf]="tag === 'textarea'">
      <textarea
        [placeholder]="placeholder"
        [disabled]="disabled"
        [ngModel]="value"
        (ngModelChange)="updateValue($event)"
        [rows]="rows"
        [maxlength]="maxSymbols ?? 255"
        (input)="autoResizeTextarea($event)"
      ></textarea>
    </ng-template>

    <button
      *ngIf="type === 'search'"
      class="button button_outline customInput__searchBtn"
      (click)="handleSearchClick()"
    >
      {{ "FILTERS.SEARCH" | translate }}
    </button>

    <app-copy-button *ngIf="withCopyButton" [value]="value"></app-copy-button>

    <span *ngIf="maxSymbols" class="customInput__maxSymbols">
      {{ value?.length ?? 0 }}/{{ maxSymbols }}
    </span>

    <button
      id="sendCodeButton"
      *ngIf="withSendButton"
      class="customInput__sendCodeBtn"
      [disabled]="resendTimer > 0"
      (click)="handleSendClick()"
    >
      {{
        resendTimer > 0 ? "Resend in 00:" + resendTimer.toString().padStart(2, "0") : "Send code"
      }}
    </button>

    <small *ngIf="error" class="customInput__error">
      {{ error }}
    </small>
  </label>
</div>
