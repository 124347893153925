<app-header></app-header>
<section class="login dialogue">
  <h2>{{ "LOGIN.EMAIL_VERIFICATION" | translate }}</h2>
  <h4>
    {{ "LOGIN.EMAIL_VERIFICATION_DESCR" | translate : { userEmail } }}
  </h4>

  <app-input
    type="number"
    [caption]="'LOGIN.EMAIL_VERIFICATION_CODE' | translate"
    [(ngModel)]="code"
    (input)="sendVerificationCode()"
    icon="mail"
    [placeholder]="'LOGIN.ENTER_CODE_HERE' | translate"
    [error]="invalidCode && 'LOGIN.INVALID_CODE' | translate"
    [withSendButton]="true"
    (onSendClick)="resendCode()"
    [shouldFocus]="true"
  ></app-input>

  <button class="button button_light" routerLink="../">
    {{ "LOGIN.CHANGE_EMAIL" | translate }}
  </button>
</section>
<app-footer></app-footer>
