<section class="tm">
  <div class="tm__header">
    <h2 class="title">Transaction manager</h2>
  </div>

  <div class="tm__main box">
    <div class="tm__inner" *ngIf="adminStatus">
      <h3 class="title title_sub">Admin transaction manager</h3>
      <h4 class="tm__status">Status: {{ renderStatusText(adminStatus) }}</h4>
      <p *ngIf="adminStatus.failReason" class="tm__error">Error: {{ adminStatus.failReason }}</p>
      <button
        class="button"
        [ngClass]="{ button_outline: adminStatus.status === 0 }"
        (click)="handleAdminTm(adminStatus.status === 0 ? 'stop' : 'activate')"
      >
        {{ adminStatus.status === 0 ? "Stop" : "Resume" }} Admin Transaction Manager
      </button>
    </div>
    <span></span>
    <div class="tm__inner" *ngIf="clientStatus">
      <h3 class="title title_sub">Client transaction manager</h3>
      <h4 class="tm__status">Status: {{ renderStatusText(clientStatus) }}</h4>
      <p *ngIf="clientStatus.failReason" class="tm__error">Error: {{ clientStatus.failReason }}</p>
      <button
        class="button"
        [ngClass]="{ button_outline: clientStatus.status === 0 }"
        (click)="handleClientTm(clientStatus.status === 0 ? 'stop' : 'activate')"
      >
        {{ clientStatus.status === 0 ? "Stop" : "Resume" }} Client Transaction Manager
      </button>
    </div>
  </div>
</section>
