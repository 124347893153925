import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IP2pProperty, IP2pRequest, P2P_REQUEST_TYPE_ENUM } from "src/app/models/p2p";
import { PrettifyNumberPipe } from "src/app/pipes/prettify-number.pipe";
import { P2pService } from "src/app/services/p2p.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { environment } from "src/environments/environment";
import { AREA_IN_TOKEN } from "src/app/constants/token-area.constant";

enum ORDER_STEPS {
  SELECTION,
  INPUT,
  CONFIRMING,
  SUCCESS,
}

@Component({
  selector: "app-order-creation",
  templateUrl: "./order-creation.component.html",
  styleUrls: ["./order-creation.component.scss"],
})
export class OrderCreationComponent implements OnInit {
  TOKEN = TOKEN;
  baseUrl = environment.serverUrl + "/";

  myProperties: IP2pProperty[];
  propertiesToBuy: IP2pProperty[];
  OrderType = P2P_REQUEST_TYPE_ENUM;
  orderTypeTabs = [
    { type: P2P_REQUEST_TYPE_ENUM.BUY, text: "P2P.ORDER_BUY_TAB" },
    { type: P2P_REQUEST_TYPE_ENUM.SELL, text: "P2P.ORDER_SELL_TAB" },
  ];
  selectedOrderTab = 0;

  selectedPropertyToSell: IP2pProperty;
  selectedPropertyToBuy: IP2pProperty;

  OrderSteps = ORDER_STEPS;
  currentStep = this.OrderSteps.SELECTION;
  orderFor: P2P_REQUEST_TYPE_ENUM;

  orderForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private p2pService: P2pService,
    private walletService: WalletService
  ) {}

  ngOnInit() {
    this.orderForm = this.fb.group({
      tokensAmount: [null, [Validators.required, Validators.min(1)]],
      tokenPrice: [null, [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]*$/)]],
      endsAt: [undefined],
    });

    this.getPropertiesToSell();
    this.getPropertiesToBuy();
  }

  async getPropertiesToSell() {
    this.myProperties = await this.p2pService.getAvailableProperties("sell");
  }

  async getPropertiesToBuy() {
    this.propertiesToBuy = await this.p2pService.getAvailableProperties("buy");
  }

  returnOrderTypeTabs() {
    return this.orderTypeTabs.map(t => t.text);
  }

  switchOrderType(tabNumber: number) {
    this.selectedOrderTab = tabNumber;
  }

  setPropertyToSell(item: IP2pProperty) {
    this.selectedPropertyToSell = item;
  }

  setPropertyToBuy(item: IP2pProperty) {
    this.selectedPropertyToBuy = item;
  }

  startCreation() {
    if (this.selectedPropertyToSell || this.selectedPropertyToBuy) {
      this.orderForm.controls.tokenPrice.setValue(null);
      this.orderForm.controls.tokensAmount.setValue(null);
      this.currentStep = this.OrderSteps.INPUT;
      this.orderFor = this.orderTypeTabs[this.selectedOrderTab].type;

      const maxTokens = this.getMaxTokens(this.orderFor);
      this.orderForm.controls.tokensAmount.addValidators(Validators.max(maxTokens));
      this.orderForm.controls.tokensAmount.updateValueAndValidity();
    }
  }

  getMaxTokens(orderType: P2P_REQUEST_TYPE_ENUM): number {
    let maxTokens: number;
    if (orderType === P2P_REQUEST_TYPE_ENUM.SELL) {
      maxTokens = this.getMaxTokensToSell();
    } else {
      maxTokens = this.getMaxTokensToBuy();
    }
    return maxTokens;
  }

  getMaxTokensToSell(): number {
    const max =
      this.selectedPropertyToSell.owned.quantity / this.selectedPropertyToSell.token.squarePerToken;
    return parseFloat(max.toFixed(0));
  }

  getMaxTokensToBuy(): number {
    const max =
      this.selectedPropertyToBuy.investInfo.totalPrice / this.selectedPropertyToBuy.token.price;
    return parseFloat(max.toFixed(0));
  }

  nextStep() {
    this.currentStep++;
  }
  prevStep() {
    this.currentStep--;
  }
  backToListing() {
    this.currentStep = ORDER_STEPS.SELECTION;
    this.orderForm.reset();
    this.orderForm.get("tokensAmount").setValidators([Validators.required, Validators.min(1)]);
    this.orderForm.get("tokensAmount").updateValueAndValidity();
  }

  calculateOrderAmount() {
    return new PrettifyNumberPipe().transform(
      this.orderForm.value.tokensAmount * +this.orderForm.value.tokenPrice
    );
  }

  prepareOrder() {
    this.nextStep();
  }

  async createOrder() {
    let propertyId =
      this.orderFor === P2P_REQUEST_TYPE_ENUM.BUY
        ? this.selectedPropertyToBuy.property.id
        : this.selectedPropertyToSell.property.id;

    const order: IP2pRequest = {
      ...this.orderForm.value,
      tokenPrice: +this.orderForm.value.tokenPrice,
      type: this.orderFor,
    };
    await this.p2pService.createOrder(propertyId, order);
    this.walletService.getWalletInfo();
    this.nextStep();
  }

  onDoneClick() {
    this.currentStep = this.OrderSteps.SELECTION;
    this.setPropertyToSell(null);
    this.setPropertyToBuy(null);

    this.getPropertiesToSell();
    this.getPropertiesToBuy();
  }

  getIsCanStartCreation() {
    const orderType = this.orderTypeTabs[this.selectedOrderTab].type;
    if (orderType === P2P_REQUEST_TYPE_ENUM.SELL && !this.selectedPropertyToSell) {
      return false;
    }
    if (orderType === P2P_REQUEST_TYPE_ENUM.BUY && !this.selectedPropertyToBuy) {
      return false;
    }
    return true;
  }

  convertTokensToArea(tokens: number) {
    return tokens * AREA_IN_TOKEN;
  }

  renderTokensError() {
    const tokensControl = this.orderForm.controls.tokensAmount;

    if (tokensControl.touched) {
      if (tokensControl.errors?.required) {
        return this.translate.instant("BUY_PROPERTY.ERROR_REQUIRED");
      }
      if (tokensControl.errors?.max) {
        return this.translate.instant("BUY_PROPERTY.ERROR_MAX");
      }
      if (tokensControl.errors?.min) {
        return this.translate.instant("BUY_PROPERTY.ERROR_MIN");
      }

      return "";
    }
  }
}
