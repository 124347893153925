import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return `0 pages`;
    }

    return `Page: ${page + 1} of ${Math.ceil(length / pageSize)}`;
  };

  return customPaginatorIntl;
}
