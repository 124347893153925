import { ITokens } from "./../models/auth";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IAPIResponse } from "./../models/api-response";
import { ToastService } from "./toast.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BaseRequestService {
  constructor(public http: HttpClient, private _toastService: ToastService) {}

  async get<T>(url: string, headersObj: any = {}, useDataWrapper = true): Promise<T> {
    return this.request(url, "GET", headersObj, null, useDataWrapper);
  }

  async post<T>(url: string, data: any, useDataWrapper = true, headersObj?: any): Promise<T> {
    return this.request(url, "POST", headersObj, data, useDataWrapper);
  }

  async delete<T>(url: string, data?: any, useDataWrapper = false, headersObj?: any): Promise<T> {
    return this.request(url, "DELETE", headersObj, data, useDataWrapper);
  }

  async patch<T>(url: string, data: any, useDataWrapper = true, headersObj?: any): Promise<T> {
    return this.request(url, "PATCH", null, data, useDataWrapper);
  }

  async put<T>(url: string, data: any, useDataWrapper = true, headersObj?: any): Promise<T> {
    return this.request(url, "PUT", null, data, useDataWrapper);
  }

  private async request<T>(
    url: string,
    method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT",
    headersObj: any = {},
    data: any = null,
    useDataWrapper = true
  ): Promise<T> {
    let tokens: ITokens;
    const basicTokens = JSON.parse(localStorage.getItem("ardr-tokens")) as ITokens;
    const adminTokens = JSON.parse(localStorage.getItem("admin-tokens")) as ITokens;
    if (adminTokens) {
      tokens = adminTokens;
    } else {
      tokens = basicTokens;
    }

    let body;

    const headers = {
      ...headersObj,
    };
    if (tokens?.accessToken) {
      headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    if (data) {
      headers["Content-Type"] = "application/json";
      body = data;
    }
    try {
      if (useDataWrapper) {
        const response: any = await this.http
          .request<IAPIResponse<T>>(method, environment.serverUrl + url, {
            headers,
            body,
          })
          .toPromise();

        return response.data;
      } else {
        const response = await this.http
          .request<T>(method, environment.serverUrl + url, {
            headers,
            body,
          })
          .toPromise();

        return response;
      }
    } catch (e) {
      const errorObject = e as HttpErrorResponse;
      const errorData = errorObject.error as IAPIResponse<any>;
      if (errorData != null && errorData.errorMessage != null && errorData.errorMessage !== "") {
        console.log(errorData);
      }
      if (errorData?.error) {
        this._toastService.open(errorData.error, "error");
      } else {
        this._toastService.open("Server error", "error");
      }

      throw e;
    }
  }

  refreshTokens() {
    const localTokens = JSON.parse(localStorage.getItem("ardr-tokens")) as ITokens;
    return this.http.post<IAPIResponse<ITokens>>(`${environment.serverUrl}/auth/refresh-token`, {
      refresh_token: localTokens?.refreshToken,
    });
  }
}
