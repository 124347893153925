import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "prettifyNumber",
})
export class PrettifyNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    const rounded = Number(value).toFixed(2);
    return +value > 0 ? Number(rounded).toLocaleString("cs-CZ") : "0";
  }
}
