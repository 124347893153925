<section class="pd">
  <div class="pd__header">
    <button class="backArrowButton" (click)="goBack()"></button>
    <h2 class="title">
      <!-- {{ usedIn === "pay-dividends" ? "Pay dividends" : "" }} -->
      Dividend details
    </h2>
  </div>

  <div class="pd__content" *ngIf="data">
    <div class="box pd__table">
      <table mat-table [dataSource]="dividends" matSort class="matTable">
        <ng-container [matColumnDef]="dividendsColumns[0]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Investor</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.accountIdentity.firstName }} {{ prop.accountIdentity.secondName }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="dividendsColumns[1]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tokens</th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.adrdAmount | prettifyNumber }} {{ TOKEN }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="dividendsColumns[2]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount of dividends</th>
          <td mat-cell *matCellDef="let prop">
            {{ calculateEuroAmount(prop.tokensAmount) | prettifyNumber }} EUR
          </td>
        </ng-container>
        <ng-container [matColumnDef]="dividendsColumns[3]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td
            mat-cell
            *matCellDef="let prop"
            class="pd__status"
            [class.pd__status_success]="prop.transaction?.status === 1"
            [class.pd__status_error]="prop.transaction?.status === 2"
          >
            {{ renderDividendStatus(prop.transaction?.status) }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dividendsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dividendsColumns"></tr>
      </table>
    </div>

    <div class="pd__right">
      <div class="pd__info">
        <h4>Property</h4>
        <p>
          Ticker <span>{{ data.token.ticker }}</span>
        </p>
        <p>
          Property <span>{{ data.property.name }}</span>
        </p>
        <p>
          Investors <span>{{ data.shared.length | prettifyNumber }}</span>
        </p>
        <p>
          Dividends <span>{{ data.euroAmount | prettifyNumber }} EUR</span>
        </p>
        <p>
          Payout date <span>{{ data.paymentDate | prettifyDate }}</span>
        </p>
      </div>
      <div *ngIf="checkCanPay()" class="pd__buttons">
        <button class="button button_outline" (click)="goBack()">Cancel</button>
        <button class="button" (click)="doPayout()" [disabled]="payoutPressed">Payout</button>
      </div>
    </div>
  </div>
</section>
