<div class="activity">
  <h3 class="title title_sub">{{ "PROPERTY_ACTIVITY.PROPERTY_ACTIVITY_TITLE" | translate }}</h3>

  <div class="activity__tableWrapper" *ngIf="dataSource.data.length > 0; else noActivity">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="onTableSorting($event)"
      class="matTable"
    >
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PROPERTY_ACTIVITY.DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop" [title]="prop.createdAt | prettifyDate : 'full'">
          {{ prop.createdAt | prettifyDate }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PROPERTY_ACTIVITY.TYPE" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop">{{ renderActivityType(prop.type) }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PROPERTY_ACTIVITY.AREA" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.tokensAmount }} {{ prop.tokenTicker }} ({{
            prop.squarePerToken * prop.tokensAmount
          }}
          m²)
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PROPERTY_ACTIVITY.AMOUNT" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop">{{ prop.adrdAmount }} {{ TOKEN }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.activity__faded]="row.status !== Status.COMPLETED"
      ></tr>
    </table>
  </div>

  <button class="button button_light" (click)="onLoadMore()" *ngIf="!getIsLastPage()">
    <img src="assets/icons/refresh.svg" alt="refresh" />
    {{ "PROPERTIES.LOAD_MORE" | translate }}
  </button>

  <ng-template #noActivity>
    <div>{{ "PROPERTY_ACTIVITY.NO_ACTIVITIES" | translate }}</div>
  </ng-template>
</div>
