<section class="creation">
  <div class="creation__header">
    <a class="backArrowButton" routerLink="/admin/properties"></a>
    <h2 class="title">Create a property</h2>
  </div>

  <app-admin-property [editable]="true" (onSubmit)="handleFormSubmit($event)"></app-admin-property>

  <div class="creation__footer">
    <button type="submit" form="propertyForm" class="button" [disabled]="isPending">
      Create a draft
    </button>
  </div>
</section>
