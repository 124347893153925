<swiper
  [slidesPerView]="1"
  [spaceBetween]="10"
  [navigation]="true"
  [pagination]="{ clickable: true }"
  class="carousel"
>
  <ng-template swiperSlide *ngFor="let pic of images">
    <img swiperSlide [src]="baseUrl + pic" alt="picture" />
  </ng-template>
</swiper>
