import { Injectable, EventEmitter } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import {
  IP2pListObject,
  IP2pListingRanges,
  IP2pMyRequest,
  IP2pOrder,
  IP2pProperty,
  IP2pRequest,
  IP2pTransaction,
  P2pRequestType,
} from "src/app/models/p2p";
import { Utils } from "src/app/utils/utils";
import { IResponseWithPagination } from "src/app/models/api-response";

@Injectable({
  providedIn: "root",
})
export class P2pService {
  onOrderUpdate = new EventEmitter();

  constructor(private readonly _api: BaseRequestService) {}

  async createOrder(propertyId: string, order: IP2pRequest) {
    try {
      const response = await this._api.post<void>(`/p2p-requests/properties/${propertyId}`, order);
      this.onOrderUpdate.emit();
      return response;
    } catch (error) {
      throw error;
    }
  }

  getAvailableProperties(type: P2pRequestType) {
    return this._api.get<IP2pProperty[]>(`/p2p-requests/properties/${type}/available`);
  }

  getListing(page: number, type: P2pRequestType, filters: string[] = undefined) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IP2pListObject>>(
      `/p2p-requests/properties/info/${type}?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
  }

  getListingRanges(type: P2pRequestType) {
    return this._api.get<IP2pListingRanges>(`/p2p-requests/properties/info/${type}/filter-ranges`);
  }

  getMyOrders(page: number, type: "active" | "history", filters: string[] = undefined) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IP2pMyRequest>>(
      `/p2p-requests/properties/my/${type}?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
  }

  async deleteOrder(requestId: string) {
    try {
      const response = await this._api.delete<void>(`/p2p-requests/${requestId}`);
      this.onOrderUpdate.emit();
      return response;
    } catch (error) {
      throw error;
    }
  }

  getPropertyOrders(
    propertyId: string,
    type: P2pRequestType,
    page: number,
    filters: string[] = undefined
  ) {
    const joinedFilters = Utils.mergeQueryParams(filters);

    return this._api.get<IResponseWithPagination<IP2pOrder>>(
      `/p2p-requests/properties/${propertyId}/${type}?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
  }

  getPropertyInfo(propertyId: string, type: P2pRequestType) {
    return this._api.get<IP2pProperty>(`/p2p-requests/properties/${propertyId}/info/${type}`);
  }

  transferOrder(requestId: string, amount: number) {
    return this._api.post<void>(`/p2p-requests/${requestId}/transfer`, { amount });
  }

  getOrderTransactionsList(requestId: string) {
    return this._api.get<IP2pTransaction[]>(`/p2p-requests/${requestId}/transfers`);
  }
}
