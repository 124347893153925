import { AuthGuard } from "./guards/auth.guard";
import { PropertyComponent } from "./views/property/property.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./views/login/login/login.component";
import { PasswordComponent } from "./views/login/password/password.component";
import { MainComponent } from "./components/main/main.component";
import { SettingsComponent } from "./views/settings/settings.component";
import { PropertiesComponent } from "./views/properties/properties.component";
import { PortfolioComponent } from "./views/portfolio/portfolio.component";
import { WalletComponent } from "./views/wallet/wallet.component";
import { BuyPropertyComponent } from "./views/property/buy-property/buy-property.component";
import { IdentityComponent } from "./views/login/identity/identity.component";
import { LoginGuard } from "./guards/login.guard";
import { AdminComponent } from "./views/admin/admin.component";
import { AdminPropertiesComponent } from "./views/admin/admin-properties/admin-properties.component";
import { AdminLoginComponent } from "./views/admin/admin-login/admin-login.component";
import { AdminGuard } from "./guards/admin.guard";
import { PropertiesCreateComponent } from "./views/admin/properties-create/properties-create.component";
import { VerificationComponent } from "./views/settings/verification/verification.component";
import { AdminPropertyPageComponent } from "./views/admin/admin-property-page/admin-property-page.component";
import { WithdrawalComponent } from "./views/wallet/withdrawal/withdrawal.component";
import { P2pComponent } from "./views/p2p/p2p.component";
import { BuyP2pComponent } from "./views/p2p/buy-p2p/buy-p2p.component";
import { SellP2pComponent } from "./views/p2p/sell-p2p/sell-p2p.component";
import { FavoritesComponent } from "./views/favorites/favorites.component";
import { AdminRequestsComponent } from "./views/admin/admin-requests/admin-requests.component";
import { IdentityResolver } from "./resolvers/identity.resolver";
import { IdentityGuard } from "./guards/identity.guard";
import { EmailChangeComponent } from "./views/email-change/email-change.component";
import { TmComponent } from "./views/admin/tm/tm.component";
import { MonetizationComponent } from "./views/admin/monetization/monetization.component";
import { DividendsPageComponent } from "./views/admin/monetization/dividends-page/dividends-page.component";
import { MonetizationPageComponent } from "./views/admin/monetization/monetization-page/monetization-page.component";
import { ReferralsComponent } from "./views/referrals/referrals.component";
import { BlockedComponent } from "./views/login/blocked/blocked.component";
import { TransactionsComponent } from "./views/admin/transactions/transactions.component";
import { UsersComponent } from "./views/admin/users/users.component";
import { UserPageComponent } from "./views/admin/users/user-page/user-page.component";
import { MyOrdersComponent } from "./views/p2p/my-orders/my-orders.component";
import { CreateP2pComponent } from "./views/p2p/create-p2p/create-p2p.component";

const loginChildren: Routes = [
  { path: "", component: LoginComponent, canActivate: [LoginGuard] },
  { path: "verification", component: PasswordComponent },
  {
    path: "identity",
    component: IdentityComponent,
    canActivate: [IdentityGuard],
  },
  { path: "blocked", component: BlockedComponent },
];

const propertiesChildren: Routes = [
  { path: "", component: PropertiesComponent },
  { path: ":id", component: PropertyComponent },
  { path: ":id/buy", component: BuyPropertyComponent },
];

const dashboardChildren: Routes = [
  {
    path: "settings",
    children: [
      { path: "", component: SettingsComponent },
      { path: "verification", component: VerificationComponent },
    ],
  },
  { path: "portfolio", component: PortfolioComponent },
  { path: "favorites", component: FavoritesComponent },
  {
    path: "wallet",
    children: [
      { path: "", component: WalletComponent },
      { path: "withdrawal", component: WithdrawalComponent },
    ],
  },
  { path: "referrals", component: ReferralsComponent },
  {
    path: "p2p",
    children: [
      { path: "", component: P2pComponent },
      { path: ":id/buy", component: BuyP2pComponent },
      { path: ":id/sell", component: SellP2pComponent },
      { path: "create", component: CreateP2pComponent },
      { path: "my-orders", component: MyOrdersComponent },
    ],
  },
  { path: "", redirectTo: "properties", pathMatch: "full" },
  { path: "properties", children: propertiesChildren },
];

const adminChildren: Routes = [
  { path: "", redirectTo: "properties", pathMatch: "full" },
  { path: "properties", component: AdminPropertiesComponent },
  { path: "properties/create", component: PropertiesCreateComponent },
  { path: "properties/:id", component: AdminPropertyPageComponent },
  { path: "requests", component: AdminRequestsComponent },
  {
    path: "monetization",
    children: [
      { path: "", pathMatch: "full", component: MonetizationComponent },
      { path: "create", component: MonetizationPageComponent },
      { path: ":id/edit", component: MonetizationPageComponent },
      { path: ":id/pay-dividends", component: DividendsPageComponent },
      { path: ":id/dividends-details", component: DividendsPageComponent },
    ],
  },
  { path: "transactions", component: TransactionsComponent },
  {
    path: "users",
    children: [
      { path: "", pathMatch: "full", component: UsersComponent },
      { path: ":id", component: UserPageComponent },
    ],
  },
  { path: "tm", component: TmComponent },
];

const routes: Routes = [
  { path: "login", children: loginChildren },
  { path: "email-change", component: EmailChangeComponent },
  {
    path: "dashboard",
    component: MainComponent,
    children: dashboardChildren,
    canActivate: [AuthGuard],
    resolve: {
      identity: IdentityResolver,
    },
  },
  { path: "admin", component: AdminComponent, children: adminChildren, canActivate: [AdminGuard] },
  { path: "admin/login", component: AdminLoginComponent, pathMatch: "full" },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  { path: "**", redirectTo: "dashboard" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
