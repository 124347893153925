import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IInvestInfo, IToken, SMART_CONTRACT_STATUS } from "src/app/models/property";
import { TOKEN } from "src/app/constants/token-name.constant";
import { Utils } from "src/app/utils/utils";
import { AREA_IN_TOKEN } from "src/app/constants/token-area.constant";

@Component({
  selector: "app-sc-tab",
  templateUrl: "./sc-tab.component.html",
  styleUrls: ["./sc-tab.component.scss"],
})
export class ScTabComponent implements OnInit {
  TOKEN = TOKEN;
  @Input() editable: boolean;
  @Input() propertySmartContractData: IToken;
  @Input() investInfo: IInvestInfo;
  @Output() onSubmit = new EventEmitter();

  isDraft = true;

  scFrom = new FormGroup({
    name: new FormControl({ value: "", disabled: this.isDraft === false }),
    ticker: new FormControl({ value: "", disabled: this.isDraft === false }, [
      Validators.pattern(/^[A-z]{1,5}$/),
    ]),
    squarePerToken: new FormControl({ value: AREA_IN_TOKEN, disabled: true }),
  });

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propertySmartContractData?.currentValue) {
      const data = changes.propertySmartContractData.currentValue;
      this.distributeValuesToForm(data);
      this.isDraft = data.scStatus === SMART_CONTRACT_STATUS.NOT_STARTED;
      this.disableFormFields();
      this.setValidatorsToForm();
    }

    if (changes.editable?.currentValue === true) {
      this.scFrom.enable();
      this.scFrom.controls.squarePerToken.disable();
      this.disableFormFields();
      this.setValidatorsToForm();
    } else if (changes.editable?.currentValue === false) {
      this.scFrom.disable();
    }
  }

  disableFormFields() {
    if (!this.isDraft) {
      this.scFrom.controls.ticker.disable();
      this.scFrom.controls.name.disable();
    }
  }

  distributeValuesToForm(data: IToken) {
    this.scFrom.patchValue(data);
  }

  setValidatorsToForm() {
    if (!this.isDraft) {
      Object.keys(this.scFrom.controls).forEach(key => {
        this.scFrom.controls[key].addValidators(Validators.required);
      });
    }
    this.scFrom.updateValueAndValidity();
  }

  submit() {
    if (this.scFrom.invalid) return;

    const newScData = this.scFrom.value;
    newScData.squarePerToken = AREA_IN_TOKEN;

    this.onSubmit.emit(newScData);
  }

  calculatePercentage() {
    return Utils.calculatePercentage(this.investInfo?.totalPrice, this.investInfo?.collected);
  }
}
