import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import SwiperCore, { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: "app-image-carousel",
  templateUrl: "./image-carousel.component.html",
  styleUrls: ["./image-carousel.component.scss"],
})
export class ImageCarouselComponent implements OnInit {
  @Input() images: string[];

  baseUrl = environment.serverUrl + "/";

  constructor() {}

  ngOnInit(): void {}
}
