import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private durationInSeconds = 3;

  constructor(private _snackBar: MatSnackBar) {}

  public open(message: string, variant: "info" | "success" | "error" = "info") {
    this._snackBar.open(message, null, {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [
        "toast",
        variant === "success" && "toast_success",
        variant === "error" && "toast_error",
      ],
    });
  }
}
