import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  DEPOSIT_ETH_WALLET,
  ICryptoCurrency,
  INewDeposit,
  ITransaction,
  TRANSACTION_STATUS,
} from "src/app/models/wallet";
import { ToastService } from "src/app/services/toast.service";
import { DEPOSIT_STEP, WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  selector: "app-deposit-modal",
  templateUrl: "./deposit-modal.component.html",
  styleUrls: ["./deposit-modal.component.scss"],
})
export class DepositModalComponent implements OnInit {
  @Input() openOnConfirmation: boolean = false;

  step = DEPOSIT_STEP.CREATION;
  currenciesList: ICryptoCurrency[] = [];
  selectedCurrency: ICryptoCurrency = null;
  transactionId: string;
  depositEthWallet = DEPOSIT_ETH_WALLET;
  isPending = false;
  transactionData: ITransaction;
  TOKEN = TOKEN;
  tokenPrice: number;

  depositForm = new FormGroup({
    amountCurrency: new FormControl(undefined, [Validators.required]),
    amountEuro: new FormControl(undefined, [Validators.required]),
  });

  transactionForm = new FormGroup({
    hash: new FormControl(undefined, [Validators.required]),
    comment: new FormControl(undefined),
  });

  constructor(private walletService: WalletService, private toastService: ToastService) {
    this.walletService.appTokenPrice$.subscribe(price => {
      this.tokenPrice = price;
    });
  }

  ngOnInit(): void {
    this.getCurrenciesList();
    this.step = this.walletService.depositStep;
    this.walletService.depositStepChange.subscribe(val => {
      this.step = val;
      if (this.step === DEPOSIT_STEP.CONFIRMATION) {
        this.transactionId = this.walletService.currentTransactionId;
        this.getTransactionData(this.transactionId);
      }
    });
  }

  async getCurrenciesList() {
    this.currenciesList = await this.walletService.getCryptoCurrencies();
    this.selectedCurrency = this.currenciesList[0];
  }

  async getTransactionData(id: string) {
    const res = await this.walletService.getTransactionById(id);
    this.transactionData = res;
    this.selectedCurrency = this.currenciesList.find(c => c.symbol === res.currency);

    this.transactionForm.patchValue(res);
    if (res.status !== TRANSACTION_STATUS.NEW && res.status !== TRANSACTION_STATUS.PENDING) {
      this.transactionForm.disable();
    } else {
      this.transactionForm.enable();
    }
  }

  renderCurrenciesList() {
    return this.currenciesList.map(c => c.name);
  }

  pickCurrency(currName: string) {
    this.selectedCurrency = this.currenciesList.find(c => c.name === currName);
    if (this.depositForm.value.amountEuro) {
      this.convertEuroToCurrency();
    }
  }

  closeDepositModal() {
    this.walletService.closeDepositModal();
  }

  calculateTokensAmount(amountInEuro: number) {
    return amountInEuro / this.tokenPrice;
  }

  async createDepositRequest() {
    this.isPending = true;

    const newDeposit: INewDeposit = {
      amountAdrd: this.calculateTokensAmount(this.depositForm.value.amountEuro),
      amountCurrency: +this.depositForm.value.amountCurrency,
      currency: this.selectedCurrency.symbol,
    };

    try {
      this.transactionId = await this.walletService.depositRequest(newDeposit);
      this.toastService.open("Deposit request created!", "success");
      // this.step++;
      this.walletService.currentTransactionId = this.transactionId;
      this.walletService.depositStepChange.next(DEPOSIT_STEP.CONFIRMATION);
    } catch (error) {
    } finally {
      this.isPending = false;
    }
  }

  async completeTransaction() {
    this.isPending = true;

    try {
      await this.walletService.completeDepositRequest(
        this.transactionId,
        this.transactionForm.value
      );
      this.toastService.open("Your transaction will be checked!", "success");
      this.closeDepositModal();
    } catch (error) {
    } finally {
      this.isPending = false;
    }
  }

  convertCurrencyToEuro() {
    const currency = this.depositForm.value.amountCurrency;
    const amountInEuro = (currency * this.selectedCurrency.priceInEuro).toFixed(2);
    this.depositForm.controls.amountEuro.setValue(amountInEuro);
  }

  convertEuroToCurrency() {
    const euro = this.depositForm.value.amountEuro;
    const amountInCurrency = (euro / this.selectedCurrency.priceInEuro).toFixed(4);
    this.depositForm.controls.amountCurrency.setValue(amountInCurrency);
  }
}
