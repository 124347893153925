import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";

@Directive({
  selector: "[appIntersectionObserver]",
})
export class IntersectionObserverDirective {
  @Input() options: IntersectionObserverInit = { threshold: 1 };
  @Output() intersectionObserver: EventEmitter<boolean> = new EventEmitter();

  private observer: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.observer = new IntersectionObserver(entries => {
      const isIntersecting = entries[0].isIntersecting;
      this.intersectionObserver.emit(isIntersecting);
    }, this.options);

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
