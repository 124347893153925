export enum P2P_REQUEST_STATUS {
  ACTIVE,
  COMPLETED,
  CANCELLED,
  EXPIRED,
}

export enum P2P_REQUEST_TYPE_ENUM {
  BUY,
  SELL,
}

export type P2pRequestType = "buy" | "sell";

export interface IP2pTransaction {
  createdAt: string;
  tokensAmount: number;
  adrdAmount: number;
  transactionHash: string;
}

export interface IP2pRequest {
  tokensAmount: number;
  tokenPrice: number;
  endsAt: string;
  type: P2P_REQUEST_TYPE_ENUM;
}

export interface IP2pProperty {
  property: {
    id: string;
    name: string;
    roomsAmount: number;
    totalSpace: number;
    description: string;
  };
  country: string;
  investInfo: {
    totalPrice: number;
    profitability: number;
  };
  token: {
    ticker: string;
    price: number;
    squarePerToken: number;
  };
  images: string[];
  owned?: {
    quantity: number;
    frozenQuantity: number;
  };
  isAvailable: boolean;
}

export interface IP2pListObject {
  property: {
    id: string;
    name: string;
    type: string;
    roomsAmount: number;
    totalSpace: number;
  };
  country: string;
  token: {
    ticker: string;
    squarePerToken: number;
    amount: number;
  };
  images: any[];
  minPrice: number;
  maxPrice: number;
  totalRequestsArea: number;
  totalRequests: number;
  profitability: number;
  totalOrdersAmount: number;
  totalRequestsTokens: number;
}

export interface IP2pListingRanges {
  availableArea: {
    from: number;
    to: number;
  };
  estimatedIncome?: {
    from: number;
    to: number;
  };
  tokenPrice: {
    from: number;
    to: number;
  };
}

export interface IP2pMyRequest {
  property: {
    id: string;
    name: string;
  };
  request: {
    id: string;
    type: P2P_REQUEST_TYPE_ENUM;
    tokensAmount: number;
    tokensFullfilled: number;
    tokenPrice: number;
    tokensFrozen: number;
    endsAt: string;
    status: P2P_REQUEST_STATUS;
    createdAt: string;
  };
  token: {
    ticker: string;
    squarePerToken: number;
  };
}

export interface IP2pOrder {
  id: string;
  endsAt: string | null;
  status: number;
  tokenPrice: number;
  tokensAmount: number;
  tokensFullfilled: number;
  tokensFrozen: number;
  type: P2P_REQUEST_TYPE_ENUM;
  ownerId: string;
  numericId: number;
}
