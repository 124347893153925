<div id="loader" class="loader">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="display: block"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#0066ff"
      stroke-width="5"
      r="25"
      stroke-dasharray="120 50"
    ></circle>
  </svg>
</div>
