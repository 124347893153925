import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { IMonetization, IMonetizationListItem, ISnapshot } from "../models/dividends";
import { Utils } from "../utils/utils";
import { IResponseWithPagination } from "../models/api-response";

@Injectable({
  providedIn: "root",
})
export class DividendsService {
  constructor(private _api: BaseRequestService) {}

  createMonetization(monetization: IMonetization) {
    return this._api.post(`/dividend/property/schedules`, monetization);
  }

  updateMonetization(dividendId: string, monetization: IMonetization) {
    return this._api.put(`/dividend/property/schedules/${dividendId}`, monetization);
  }

  getMonetizations(page: number, perPage: number, filters?: string[]) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IMonetizationListItem>>(
      `/dividend/property/schedules?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  genMonetizationById(dividendId: string) {
    return this._api.get<IMonetizationListItem>(`/dividend/property/schedules/${dividendId}`);
  }

  getFuturePayouts(page: number, perPage: number, filters?: string[]) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<ISnapshot>>(
      `/dividend/property/snapshots/future?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  getPayoutsHistory(page: number, perPage: number, filters?: string[]) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<ISnapshot>>(
      `/dividend/property/snapshots?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  getPayoutById(snapshotId: string) {
    return this._api.get<ISnapshot>(`/dividend/property/snapshots/${snapshotId}`);
  }

  paySnapshot(snapshotId: string) {
    return this._api.post<void>(`/dividend/property/snapshots/${snapshotId}/pay`, {});
  }
}
