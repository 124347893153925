import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { IUser, IUserPropertyTransaction } from "src/app/models/account";
import { TRANSACTION_TYPE } from "src/app/models/property";
import { COUNTRIES_LIST } from "src/app/models/settings";
import { AccountsService } from "src/app/services/accounts.service";
import { SUMSUB_STATUS } from "src/app/services/sumsub.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.scss"],
})
export class UserPageComponent implements OnInit {
  TOKEN = TOKEN;

  userData: IUser;
  userId: string;

  userTrans = new MatTableDataSource<IUserPropertyTransaction>();
  userTransColumns = [
    "id",
    "transaction_type",
    "created_at",
    "token_ticker",
    "tokens_amount",
    "adrd_amount",
    "property_name",
  ];
  userTransPage = 0;
  userTransPageSize = 10;
  userTransTotal = 0;
  userTransSort: string[] = [];
  searchField: string = "";

  constructor(
    private _location: Location,
    private _accountService: AccountsService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.params.subscribe(p => {
      this.userId = p.id;
    });
  }

  async ngOnInit() {
    this.userData = await this._accountService.getAccountById(this.userId);
    this.requestUserTransactions();
  }

  goBack() {
    this._location.back();
  }

  onTransPageChange(page: PageEvent) {
    this.userTransPage = page.pageIndex;
    this.requestUserTransactions();
  }

  applyTransactionsFilters() {
    this.userTransPage = 0;
    this.requestUserTransactions();
  }

  onTransSort(sort: Sort) {
    this.userTransSort = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.applyTransactionsFilters();
  }

  renderVerificationStatus(status: SUMSUB_STATUS) {
    switch (status) {
      case SUMSUB_STATUS.COMPLETE:
        return "Verified";
      case SUMSUB_STATUS.FAILED:
        return "Failed";
      case SUMSUB_STATUS.PENDING:
        return "Pending";
      case SUMSUB_STATUS.REQUIRED:
        return "Required";
      case SUMSUB_STATUS.RETRY:
        return "Retry";
    }
  }

  renderCountryName(countryCode: string) {
    return COUNTRIES_LIST.find(c => c.iso === countryCode?.toLowerCase())?.name;
  }

  renderTransactionType(type: TRANSACTION_TYPE) {
    switch (type) {
      case TRANSACTION_TYPE.BUY:
        return "Buy";
      case TRANSACTION_TYPE.SELL:
        return "Sell";
      case TRANSACTION_TYPE.P2P:
        return "P2P";
    }
  }

  private async requestUserTransactions() {
    const filters = this.createTransFilters();
    const res = await this._accountService.getAccountTransactions(
      this.userId,
      this.userTransPage,
      this.userTransPageSize,
      filters
    );
    this.userTrans.data = res.items;
    this.userTransTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  private createTransFilters() {
    const filtersArray = [];

    if (this.searchField.trim()) {
      filtersArray.push(`search=${this.searchField}`);
    }

    if (this.userTransSort.length) {
      filtersArray.push(...this.userTransSort);
    }

    return filtersArray;
  }
}
