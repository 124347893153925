<main class="p2p">
  <app-main-header headline="Peer-to-peer"></app-main-header>

  <div class="p2p__nav">
    <app-tabs
      [tabs]="tabs"
      [currentTabNumber]="currentTabNumber"
      (tabSelected)="switchTab($event)"
      [withQuery]="true"
    ></app-tabs>

    <div class="p2p__navRight">
      <a routerLink="/dashboard/p2p/my-orders" class="button button_light">{{
        "P2P.MY_ORDERS" | translate
      }}</a>
      <a routerLink="/dashboard/p2p/create" class="button">{{ "P2P.TAB_CREATE" | translate }}</a>
    </div>
  </div>

  <div [ngSwitch]="currentTabNumber" class="p2p__rows">
    <ng-template ngSwitchCase="0">
      <app-filter-bar
        [showLayoutButtons]="false"
        usedIn="p2pBuy"
        (onFiltersApply)="applyBuyFilters()"
        (onSortingChange)="applyBuyFilters()"
        (onSearch)="applyBuyFilters()"
        [searchPlaceholder]="'FILTERS.FIND_A_PROPERTY' | translate"
      ></app-filter-bar>

      <ng-container *ngIf="!isBuyLoading; else buyLoader">
        <section class="p2p__rows p2p__listing" *ngIf="buyFragments.length > 0; else noBuy">
          <app-property-card
            *ngFor="let item of buyFragments"
            [data]="item"
            variant="horizontal"
            cardType="p2pOrderBuy"
            (onClick)="openBuyPage($event)"
          ></app-property-card>
        </section>
        <button class="button button_light" (click)="buyLoadMore()" *ngIf="!isBuyPagesLimit">
          {{ "P2P.LOAD_MORE" | translate }}
        </button>
        <ng-template #noBuy>
          <div class="p2p__nothing">
            <p>{{ "P2P.NOTHING_TO_BUY" | translate }}</p>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #buyLoader>
        <app-card-skeleton variant="horizontal"></app-card-skeleton>
      </ng-template>
    </ng-template>

    <ng-template ngSwitchCase="1">
      <app-filter-bar
        [showLayoutButtons]="false"
        usedIn="p2pSell"
        (onFiltersApply)="applySellFilters()"
        (onSortingChange)="applySellFilters()"
        (onSearch)="applySellFilters()"
        [searchPlaceholder]="'FILTERS.FIND_A_PROPERTY' | translate"
      ></app-filter-bar>

      <ng-container *ngIf="!isSellLoading; else sellLoader">
        <section class="p2p__rows p2p__listing" *ngIf="sellFragments.length > 0; else noSell">
          <app-property-card
            *ngFor="let item of sellFragments"
            [data]="item"
            variant="horizontal"
            cardType="p2pOrderSell"
            (onClick)="openSellPage($event)"
          ></app-property-card>
        </section>
        <button class="button button_light" (click)="sellLoadMore()" *ngIf="!isSellPagesLimit">
          Load more
        </button>
        <ng-template #noSell>
          <div class="p2p__nothing">
            <p>{{ "P2P.NOTHING_FOR_SALE" | translate }}</p>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #sellLoader>
        <app-card-skeleton variant="horizontal"></app-card-skeleton>
      </ng-template>
    </ng-template>
  </div>
</main>
