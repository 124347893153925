<main class="ref">
  <app-main-header [headline]="'REFERRALS.TITLE' | translate"></app-main-header>

  <section class="ref__info">
    <div class="ref__about">
      <div class="ref__aboutItem" *ngFor="let item of refAboutData">
        <div class="ref__aboutItemIcon">
          <img [src]="item.icon" alt="icon" />
        </div>
        <div class="ref__aboutItemDesc">
          <h4 translate>{{ item.title }}</h4>
          <p translate [translateParams]="{ token: TOKEN }">{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="ref__connect">
      <div class="ref__connectRow">
        <app-input
          [caption]="'REFERRALS.REFERRAL_ID' | translate"
          [disabled]="true"
          [withCopyButton]="true"
          [ngModel]="myReferralCode"
        ></app-input>
        <app-input
          [caption]="'REFERRALS.REFERRAL_LINK' | translate"
          [disabled]="true"
          [withCopyButton]="true"
          [ngModel]="returnReferralLink()"
        ></app-input>
      </div>
    </div>
  </section>

  <section class="ref__my myRef">
    <div class="myRef__header">
      <h3>{{ "REFERRALS.MY_REFERRALS" | translate }}</h3>
      <div class="myRef__headerButtons">
        <button class="button button_outline" (click)="openHistoryModal()">
          {{ "REFERRALS.HISTORY" | translate }}
        </button>
        <button class="button" (click)="openTransferModal()" [disabled]="!statsData[0].value">
          {{ "REFERRALS.TRANSFER" | translate }}
        </button>
      </div>
    </div>

    <div class="myRef__stats">
      <div class="myRef__statItem" *ngFor="let stat of statsData">
        <p translate>{{ stat.name }}</p>
        <span>{{ stat.value | prettifyNumber }} {{ stat.id === 0 ? TOKEN : "" }}</span>
      </div>
    </div>

    <table mat-table [dataSource]="refTable" class="matTable">
      <ng-container [matColumnDef]="refColumns[0]">
        <th mat-header-cell *matHeaderCellDef>
          {{ "REFERRALS.USER" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop" [title]="prop.invitedAccountId">
          {{ prop.invitedAccountId | shortenText }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="refColumns[1]">
        <th mat-header-cell *matHeaderCellDef>
          {{ "REFERRALS.REGISTRATION_DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop" [title]="prop.registrationDate | prettifyDate : 'full'">
          {{ prop.registrationDate | prettifyDate }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="refColumns[2]">
        <th mat-header-cell *matHeaderCellDef>
          {{ "REFERRALS.FIRST_PURCHASE_DATE" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let prop"
          [title]="prop.firstPurchaseDate | prettifyDate : 'full'"
        >
          {{ prop.firstPurchaseDate ? (prop.firstPurchaseDate | prettifyDate) : "-" }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="refColumns[3]">
        <th mat-header-cell *matHeaderCellDef>
          {{ "REFERRALS.INCOME" | translate }}
        </th>
        <td mat-cell *matCellDef="let prop">{{ prop.reward | prettifyNumber }} {{ TOKEN }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="refColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: refColumns"></tr>
    </table>

    <div *ngIf="!refTable.data.length" class="ref__noData">
      {{ "REFERRALS.NO_USERS" | translate }}
    </div>
  </section>

  <p
    class="ref__rulesLink"
    [innerHtml]="'REFERRALS.REFERRAL_RULES' | translate : { link: '/' }"
  ></p>
</main>

<jw-modal id="ref-income">
  <div class="infoModal refModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">{{ "REFERRALS.ARE_YOU_SURE" | translate }}</h3>
      <button (click)="closeTransferModal()">&#10006;</button>
    </div>
    <div class="infoModal__footer">
      <button class="button button_outline" (click)="closeTransferModal()">
        {{ "REFERRALS.DECLINE" | translate }}
      </button>
      <button class="button" (click)="doTransfer()">{{ "REFERRALS.PROCEED" | translate }}</button>
    </div>
  </div>
</jw-modal>

<jw-modal id="ref-history">
  <div class="infoModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">{{ "REFERRALS.HISTORY" | translate }}</h3>
      <button (click)="closeHistoryModal()">&#10006;</button>
    </div>

    <div *ngIf="historyTable.data.length; else noHistory">
      <table mat-table [dataSource]="historyTable" class="matTable">
        <ng-container [matColumnDef]="historyColumns[0]">
          <th mat-header-cell *matHeaderCellDef>
            {{ "REFERRALS.DATE" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop" [title]="prop.date | prettifyDate : 'full'">
            {{ prop.date | prettifyDate }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="historyColumns[1]">
          <th mat-header-cell *matHeaderCellDef>
            {{ "REFERRALS.AMOUNT" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.amountAdrd | prettifyNumber }} {{ TOKEN }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="historyColumns[2]">
          <th mat-header-cell *matHeaderCellDef>
            {{ "REFERRALS.STATUS" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop">
            {{ renderStatus(prop.status) | translate }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="historyColumns[3]">
          <th mat-header-cell *matHeaderCellDef>
            {{ "REFERRALS.HASH" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop" [title]="prop.hash">
            {{ prop.hash | shortenText }} <app-copy-button [value]="prop.hash"></app-copy-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
      </table>
      <mat-paginator
        [pageIndex]="historyPage"
        [pageSize]="historyPageSize"
        [length]="historyTotal"
        (page)="onHistoryPageChange($event)"
        aria-label="Select page"
      ></mat-paginator>
    </div>

    <ng-template #noHistory>
      <div class="ref__noData">{{ "REFERRALS.NO_HISTORY" | translate }}</div>
    </ng-template>
  </div>
</jw-modal>
