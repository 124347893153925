<div class="tabs" [ngClass]="variant === 'mini' && 'tabs_mini'">
  <button
    *ngFor="let tab of translatedTabs; index as i"
    class="tabs__item"
    [ngClass]="{ tabs__item_selected: currentTabNumber == i, tabs__item_mini: variant === 'mini' }"
    (click)="onTabSelect(i)"
    type="button"
  >
    {{ tab }}
  </button>
</div>
