import { Component, OnInit, HostListener } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EMAIL_REGEX } from "src/app/models/auth";
import { AuthService } from "src/app/services/auth.service";

@Component({
  templateUrl: "./email-change.component.html",
  styleUrls: ["./email-change.component.scss"],
})
export class EmailChangeComponent implements OnInit {
  @HostListener("window:keydown.enter", ["$event"])
  handleKeyPress() {
    if (this.step === "email" && this.newEmail.valid) {
      this.goToVerification();
    }
  }

  code: number;
  isCodeValid: boolean = true;
  userEmail: string;
  step: "code" | "email" = "code";
  isPending = false;

  newEmail = new FormControl(undefined, [
    Validators.required,
    Validators.email,
    Validators.pattern(EMAIL_REGEX),
  ]);

  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this.userEmail = this._authService.accountData?.email;
    if (!this.userEmail) {
      this._router.navigateByUrl("/login");
    } else {
      this.resendCode();
    }
  }

  async sendVerificationCode() {
    if (String(this.code).length !== 6) {
      this.isCodeValid = false;
      return;
    } else {
      this.isCodeValid = true;
    }

    this.step = "email";
  }

  resendCode() {
    this._authService.sendOtpToEmail(this.userEmail);
  }

  async goToVerification() {
    this.isPending = true;
    try {
      const newEmail = this.newEmail.value;
      await this._authService.sendOtpToEmail(newEmail);
      sessionStorage.setItem("email", newEmail);
      sessionStorage.setItem("oldPassword", JSON.stringify(this.code));

      this._router.navigateByUrl("/login/verification");
    } catch (error) {
      throw error;
    } finally {
      this.isPending = false;
    }
  }
}
