import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { COUNTRIES_LIST, IUserIdentity } from "src/app/models/settings";
import { ReferralService } from "src/app/services/referral.service";
import { SettingsService } from "src/app/services/settings.service";

@Component({
  selector: "app-identity",
  templateUrl: "./identity.component.html",
  styleUrls: ["./identity.component.scss", "../login/login.component.scss"],
})
export class IdentityComponent implements OnInit {
  verificationStep: 1 | 2 | 3 | 4 = 1;

  firstForm = new FormGroup({
    firstName: new FormControl(undefined, [Validators.required]),
    secondName: new FormControl(undefined, [Validators.required]),
    birthDate: new FormControl(undefined, [Validators.required]),
    referralCode: new FormControl(undefined, [Validators.pattern(/^\S{1,12}$/)]),
    areTermsAccepted: new FormControl(false, [Validators.requiredTrue]),
  });
  showRefInput: boolean = false;

  countriesList = COUNTRIES_LIST;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  phoneSelectedCountryISO = CountryISO.CzechRepublic;
  PhoneNumberFormat = PhoneNumberFormat;
  secondForm = new FormGroup({
    phoneNumber: new FormControl(undefined, [Validators.required]),
    street: new FormControl(undefined, [Validators.required, Validators.pattern(/^.{1,30}$/)]),
    city: new FormControl(undefined, [Validators.required, Validators.pattern(/^.{1,30}$/)]),
    postalCode: new FormControl(undefined, [Validators.required, Validators.pattern(/^\w+$/)]),
    country: new FormControl(this.countriesList[0].name),
  });

  isPending = false;

  constructor(
    private _router: Router,
    private _settingsService: SettingsService,
    private _referralService: ReferralService
  ) {}

  ngOnInit(): void {
    const referralCode = JSON.parse(localStorage.getItem("referralCode"));
    if (referralCode) {
      this.showRefInput = true;
      this.firstForm.controls.referralCode.setValue(referralCode);
    }

    this.getValueFromLs();

    this.firstForm.valueChanges.subscribe(() => {
      const lsIdentity = JSON.parse(localStorage.getItem("identity"));
      const updated = { ...lsIdentity, firstForm: this.firstForm.value };
      localStorage.setItem("identity", JSON.stringify(updated));
    });

    this.secondForm.valueChanges.subscribe(() => {
      const lsIdentity = JSON.parse(localStorage.getItem("identity"));
      const updated = {
        ...lsIdentity,
        secondForm: this.secondForm.value,
      };
      localStorage.setItem("identity", JSON.stringify(updated));
    });
  }

  getCountriesNames() {
    return this.countriesList.map(c => c.name);
  }

  setCountry(country: string) {
    this.secondForm.controls.country.setValue(country);
  }

  async handleFormSubmit(currentStep: number) {
    switch (currentStep) {
      case 1:
        await this.checkRefCode();
        this.verificationStep = 2;
        break;
      case 2:
        await this.sendIdentity();
        this.verificationStep = 3;
        break;
      case 3:
        this.verificationStep = 4;
        break;
    }

    const lsIdentity = JSON.parse(localStorage.getItem("identity"));
    localStorage.setItem(
      "identity",
      JSON.stringify({ ...lsIdentity, verificationStep: this.verificationStep })
    );
  }

  skipSumSub() {
    this._router.navigateByUrl("/dashboard/properties");
  }

  renderControlErrorMessage(control: FormControl) {
    const { touched, errors } = control;

    if (!touched || !errors) {
      return "";
    }

    if (errors.required) {
      return "IDENTITY.PLEASE_FILL";
    }
    if (errors.pattern) {
      return "IDENTITY.CHECK_INPUT";
    }
    if (errors.validatePhoneNumber?.valid === false) {
      return "IDENTITY.CHECK_PHONE";
    }
    return "";
  }

  private getCountryIso(countryName: string) {
    return this.countriesList.find(c => c.name === countryName).iso;
  }

  private getValueFromLs() {
    const lsData = JSON.parse(localStorage.getItem("identity"));
    if (lsData) {
      this.firstForm.patchValue(lsData.firstForm);
      this.secondForm.patchValue(lsData.secondForm);
      this.phoneSelectedCountryISO =
        lsData.secondForm?.phoneNumber?.countryCode ?? CountryISO.CzechRepublic;
      this.verificationStep = lsData.verificationStep ?? 1;
    }
  }

  private async checkRefCode() {
    if (this.showRefInput && this.firstForm.value.referralCode) {
      this.isPending = true;
      try {
        await this._referralService.getCanUseReferralCode(this.firstForm.value.referralCode);
      } catch (error) {
        throw error;
      } finally {
        this.isPending = false;
      }
    }
  }

  private async sendIdentity() {
    this.isPending = true;

    const newIdentity: IUserIdentity = {
      firstName: this.firstForm.value.firstName,
      secondName: this.firstForm.value.secondName,
      birthDate: this.firstForm.value.birthDate,
      phoneNumber: this.secondForm.value.phoneNumber.e164Number,
      city: this.secondForm.value.city,
      street: this.secondForm.value.street,
      country: this.getCountryIso(this.secondForm.value.country).toUpperCase(),
      postalCode: this.secondForm.value.postalCode.toString(),
      referralCode: this.firstForm.value.referralCode,
    };

    try {
      await this._settingsService.updateUserIdentity(newIdentity);
      localStorage.removeItem("referralCode");
      localStorage.removeItem("identity");
    } catch (error) {
      throw error;
    } finally {
      this.isPending = false;
    }
  }
}
