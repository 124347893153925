<div class="bigInput">
  <div class="bigInput__top">
    {{ caption }}
    <span>{{ textConditions }}</span>
  </div>

  <label class="bigInput__inputWrapper" [ngClass]="{ bigInput__inputWrapper_error: !!error }">
    <input
      type="number"
      name="big-input"
      placeholder="0"
      [value]="value"
      [disabled]="disabled"
      (input)="onChange($event.target.value)"
      (blur)="onTouched()"
    />
    <div class="bigInput__border"></div>
    <small *ngIf="!!error">{{ error }}</small>
    <span>{{ ticker }}</span>
  </label>
</div>
