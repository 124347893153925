import { Component, OnInit } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";

import { ModalService } from "src/app/components/_modal";
import { ORDER_TYPE } from "src/app/models/filters";
import {
  IWalletActivity,
  IWalletBalance,
  ITransaction,
  FUNDING_TRANSACTION_TYPE,
  TRANSACTION_STATUS,
} from "src/app/models/wallet";
import { SumsubService, SUMSUB_STATUS } from "src/app/services/sumsub.service";
import { DEPOSIT_STEP, WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

enum WALLET_SORTING_FIELD {
  ID,
  DATE,
  TYPE,
  AMOUNT,
  STATUS,
}

@Component({
  selector: "app-wallet",
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.scss"],
})
export class WalletComponent implements OnInit {
  TOKEN = TOKEN;
  page = 0;
  activityList: IWalletActivity[] = [];
  transactionData: ITransaction;
  dataSource = new MatTableDataSource<IWalletActivity>();
  displayedColumns = ["date", "type", "amountAdrd", "status", "action"];
  sortingColumnEnum = new Map([
    [this.displayedColumns[0], WALLET_SORTING_FIELD.ID],
    [this.displayedColumns[1], WALLET_SORTING_FIELD.DATE],
    [this.displayedColumns[2], WALLET_SORTING_FIELD.TYPE],
    [this.displayedColumns[3], WALLET_SORTING_FIELD.AMOUNT],
    [this.displayedColumns[4], WALLET_SORTING_FIELD.STATUS],
  ]);
  sorting: string[] = [];
  isLimitReached = false;
  isLoading = true;

  walletInfo: IWalletBalance = {
    balanceRemain: 0,
    totalInvested: 0,
    balanceFrozen: 0,
  };

  selectedTransactionId: string = "";
  verificationStatus: SUMSUB_STATUS;

  constructor(
    private readonly _walletService: WalletService,
    private readonly _modalService: ModalService,
    private readonly _sumsubService: SumsubService,
    private readonly router: Router
  ) {}

  async ngOnInit() {
    this._walletService.walletBalance$.subscribe(balance => {
      this.walletInfo = balance;
    });

    const res = await this.getActivityList(this.page);
    this.dataSource.data = res;

    this._walletService.onDepositCreated.subscribe(async () => {
      this.page = 0;
      const activityList = await this.getActivityList(this.page);
      this.dataSource.data = activityList;
    });

    this.verificationStatus = this._sumsubService.verificationStatus;
    this._sumsubService.verificationStatusChange.subscribe(status => {
      this.verificationStatus = status;
    });
  }

  async getActivityList(page: number) {
    try {
      const resp = await this._walletService.getTransactionsList(page, 10, this.sorting);
      this.isLimitReached = this.page + 1 >= resp.pagination.totalPages;
      return resp.items;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async onTableSorting(sort: Sort) {
    const fieldEnum = this.sortingColumnEnum.get(sort.active);
    const dir =
      sort.direction === "asc"
        ? ORDER_TYPE.ASC
        : sort.direction === "desc"
        ? ORDER_TYPE.DESC
        : null;
    if (!dir) {
      this.sorting = [];
      this.page = 0;
      const res = await this.getActivityList(this.page);
      this.dataSource.data = res;
      return;
    }
    const orderFieldQuery = `order[field]=${fieldEnum}`;
    const orderDirectionQuery = `order[direction]=${dir}`;
    this.sorting = [orderFieldQuery, orderDirectionQuery];
    this.page = 0;
    const res = await this.getActivityList(this.page);
    this.dataSource.data = res;
  }

  async loadMore() {
    this.page++;
    const newActivities = await this.getActivityList(this.page);
    this.dataSource.data = [...this.dataSource.data, ...newActivities];
  }

  async openTransactionInfoModal(transactionId: string) {
    this._walletService.openDepositModal(DEPOSIT_STEP.CONFIRMATION, transactionId);
  }

  renderTransactionType(type: FUNDING_TRANSACTION_TYPE) {
    switch (type) {
      case FUNDING_TRANSACTION_TYPE.WITHDRAWAL:
        return "WALLET.WITHDRAWAL";
      case FUNDING_TRANSACTION_TYPE.DEPOSIT:
        return "WALLET.DEPOSIT";
      case FUNDING_TRANSACTION_TYPE.DIVIDENDS:
        return "WALLET.DIVIDENDS";
      case FUNDING_TRANSACTION_TYPE.BONUS:
        return "WALLET.BONUS";
      case FUNDING_TRANSACTION_TYPE.REFERRAL_WITHDRAW:
        return "WALLET.REFERRAL_WITHDRAW";
    }
  }

  renderStatus(status: TRANSACTION_STATUS) {
    switch (status) {
      default:
      case TRANSACTION_STATUS.PENDING:
        return "STATUS.PENDING";
      case TRANSACTION_STATUS.COMPLETED:
        return "STATUS.COMPLETED";
      case TRANSACTION_STATUS.CANCELLED:
        return "STATUS.CANCELLED";
      case TRANSACTION_STATUS.ACCEPTED:
        return "STATUS.ACCEPTED";
      case TRANSACTION_STATUS.NEW:
        return "STATUS.NEW";
    }
  }

  openModalAboutVerification() {
    this._modalService.open("verification-req-modal");
  }

  closeModalAboutVerification() {
    this._modalService.close("verification-req-modal");
  }

  checkCanWithdraw() {
    if (this.verificationStatus !== SUMSUB_STATUS.COMPLETE) {
      this.openModalAboutVerification();
    } else {
      this.router.navigateByUrl("/dashboard/wallet/withdrawal");
    }
  }

  openDepositModal() {
    this._walletService.openDepositModal(DEPOSIT_STEP.CREATION);
  }
}
