<div class="slider" [formGroup]="range">
  <span class="slider__caption">{{ caption }}</span>
  <div class="slider__inputsWrapper">
    <label class="slider__inputBox">
      <span>{{ "FILTERS.FROM" | translate }}</span>
      <input
        type="number"
        formControlName="from"
        (input)="handleInput($event)"
        [step]="sliderOptions.step"
        [value]="range.value.from"
        name="min"
      />
      <div class="slider__inputTicker">
        <span>{{ range.value.from }}</span> {{ ticker }}
      </div>
    </label>
    <label class="slider__inputBox">
      <span>{{ "FILTERS.TO" | translate }}</span>
      <input
        type="number"
        formControlName="to"
        (input)="handleInput($event)"
        [step]="sliderOptions.step"
        [value]="range.value.to"
        name="max"
      />
      <div class="slider__inputTicker">
        <span>{{ range.value.to }}</span> {{ ticker }}
      </div>
    </label>
  </div>
  <ngx-slider
    [options]="sliderOptions"
    [value]="range.value.from"
    [highValue]="range.value.to"
    (userChange)="onRangeChange($event)"
    class="slider__range"
  ></ngx-slider>
</div>
