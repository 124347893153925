import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from "rxjs";
import { ITokens } from "../models/auth";
import { IAPIResponse } from "../models/api-response";
import { BaseRequestService } from "../services/base-request.service";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _api: BaseRequestService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = request.clone();

    return next.handle(req).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes("login") &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        if (error.status === 400 && req.url.includes("refresh-token")) {
          this._authService.logout();
        }

        if (
          error instanceof HttpErrorResponse &&
          error.status === 403 &&
          error.error.error === "Failed sumsub ident"
        ) {
          this._router.navigateByUrl("/login/blocked");
          return throwError(() => error);
        }

        return throwError(() => error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._api.refreshTokens().pipe(
        switchMap(data => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(data);
          localStorage.setItem("ardr-tokens", JSON.stringify(data.data));
          return next.handle(this.addToken(request, data.data.accessToken));
        }),
        catchError(err => {
          this.isRefreshing = false;
          return throwError(() => err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(data => data != null),
        take(1),
        switchMap((data: IAPIResponse<ITokens>) => {
          localStorage.setItem("ardr-tokens", JSON.stringify(data.data));
          return next.handle(this.addToken(request, data.data.accessToken));
        })
      );
    }
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
