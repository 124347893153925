<section class="mc">
  <div class="mc__header">
    <button class="backArrowButton" (click)="goBack()"></button>
    <h2 class="title">{{ isEditMode ? "Monetization editing" : "Creation of monetization" }}</h2>
  </div>

  <div class="mc__content" [formGroup]="monetForm">
    <app-switch caption="Monetization started" formControlName="isActive"></app-switch>

    <div class="mc__typesWrapper">
      Type of monetization
      <div class="mc__types">
        <button
          *ngFor="let type of types"
          (click)="selectType(type)"
          class="mc__typeBtn"
          [class.mc__typeBtn_active]="monetForm.value.type === type.type"
        >
          {{ type.name }}
        </button>
      </div>
    </div>

    <div class="mc__row">
      <app-select
        caption="Object"
        [items]="getPropertiesNames()"
        [selectedItem]="monetForm.value.object?.name"
        (pick)="setProperty($event)"
        [disabled]="isEditMode"
      ></app-select>
      <app-input
        caption="Planned start date for monetization"
        type="date"
        formControlName="plannedStartsAt"
      ></app-input>
    </div>

    <div class="mc__row">
      <app-input caption="Payout date" type="date" formControlName="startsAt"></app-input>
      <app-input
        caption="Next payout date"
        *ngIf="getSelectedType() === 1"
        [ngModel]="payoutDate"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="true"
      ></app-input>
    </div>

    <div class="mc__row">
      <app-select
        *ngIf="getSelectedType() === 1"
        caption="Choose period"
        [items]="getPeriodNames()"
        [selectedItem]="getSinglePeriodName(monetForm.controls.period.value)"
        (pick)="setPeriod($event)"
      ></app-select>
      <app-input
        type="number"
        [caption]="
          getSelectedType() === 1
            ? 'Profit per period (EUR/' + getSinglePeriodName(monetForm.controls.period.value) + ')'
            : 'Profit (EUR)'
        "
        formControlName="euroAmount"
      ></app-input>
      <app-input
        caption="Dividends for tokens"
        [disabled]="true"
        [ngModel]="returnDividendsPerToken()"
        [ngModelOptions]="{ standalone: true }"
      >
      </app-input>
    </div>

    <div class="mc__buttons">
      <button class="button button_outline" (click)="goBack()">Cancel</button>
      <button class="button" [disabled]="monetForm.invalid" (click)="handleSubmit()">
        {{ isEditMode ? "Save" : "Create" }}
      </button>
    </div>
  </div>
</section>
