<main class="fav">
  <app-main-header [headline]="'FAVORITES.TITLE' | translate"></app-main-header>

  <ng-container *ngIf="!isLoading; else loader">
    <section class="fav__wrapper" *ngIf="favorites.length > 0; else noFav">
      <app-property-card
        *ngFor="let item of favorites"
        [data]="item"
        variant="vertical"
        cardType="property"
        [showFavButton]="true"
        (onClick)="openPropertyPage($event)"
      ></app-property-card>
    </section>

    <button *ngIf="hasMorePages" class="button" (click)="loadMore()">
      {{ "FAVORITES.LOAD_MORE" | translate }}
    </button>

    <ng-template #noFav>
      <div class="fav__noFav">
        <img src="assets/icons/sad-smile.svg" alt="Nothing in favorites" />
        <p>{{ "FAVORITES.NOTHING_IN_FAV" | translate }}</p>
        <button class="button" routerLink="/dashboard/properties">
          {{ "FAVORITES.GO_TO_PROPERTIES" | translate }}
        </button>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loader>
    <app-card-skeleton></app-card-skeleton>
  </ng-template>
</main>
