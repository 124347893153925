import { ITokens, IAuthData, IAccount, IEmailChangeData } from "./../models/auth";
import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { Router } from "@angular/router";
import jwtDecode from "jwt-decode";
import { BehaviorSubject } from "rxjs";
import { FavoritesService } from "./favorites.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private localStorageTag = "ardr-tokens";
  isUserLogged = false;
  private tokens = new BehaviorSubject<ITokens>(null);
  tokens$ = this.tokens.asObservable();
  accountData: IAccount;

  constructor(
    private readonly _api: BaseRequestService,
    private readonly _router: Router,
    private _favoritesService: FavoritesService
  ) {
    const tokens = JSON.parse(localStorage.getItem(this.localStorageTag)) as ITokens;
    this.tokens.next(tokens);

    this.tokens$.subscribe(tokens => {
      if (!tokens || !tokens.accessToken) {
        this.accountData = null;
        this.isUserLogged = false;
        localStorage.removeItem(this.localStorageTag);
      } else {
        const decoded = jwtDecode(tokens.accessToken) as any;
        localStorage.setItem(this.localStorageTag, JSON.stringify(tokens));
        this.accountData = decoded;
        this.isUserLogged = true;
      }
    });
  }

  async signIn(dataToSend: IAuthData) {
    const response = await this._api.post<ITokens>(`/auth`, dataToSend);
    this.tokens.next(response);
  }

  async sendOtpToEmail(userEmail: string) {
    const dataToSend = {
      email: userEmail,
    };
    await this._api.post("/auth/otp", dataToSend);
  }

  logout() {
    this.tokens.next(null);
    this._favoritesService.clearLocalFavoritesList();
    localStorage.removeItem("identity");
    sessionStorage.clear();
    this._router.navigateByUrl("/login");
  }

  async adminSignIn(authData: IAuthData) {
    try {
      const response = await this._api.post<ITokens>("/auth/email-password", authData);
      this.tokens.next(response);
      this._router.navigateByUrl("/admin");
    } catch (error: any) {
      throw new Error(error);
    }
  }

  adminLogout() {
    this.tokens.next(null);
    this._router.navigateByUrl("/admin/login");
  }

  async changeEmail(changeEmailData: IEmailChangeData) {
    const response = await this._api.put<ITokens>(`/auth/change/email`, changeEmailData);
    this.tokens.next(response);
  }
}
