import { Component, OnInit } from "@angular/core";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  templateUrl: "./my-orders.component.html",
  styleUrls: ["./my-orders.component.scss"],
})
export class MyOrdersComponent implements OnInit {
  TOKEN = TOKEN;
  frozenFunds: number = 0;

  constructor(private _walletService: WalletService) {}

  ngOnInit(): void {
    this._walletService.walletBalance$.subscribe(balance => {
      this.frozenFunds = balance?.balanceFrozen;
    });
  }
}
