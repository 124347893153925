export class Utils {
  static calculatePercentage(target: number, collected: number): number {
    const calculated = (100 * collected) / target;
    const rounded = Number(calculated.toFixed(1));
    return Math.min(rounded, 100);
  }

  static mergeQueryParams(filters: string[]): string {
    return filters?.length > 0 ? "&" + filters.join("&") : "";
  }
}
