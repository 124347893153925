import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ISnapshot, SNAPSHOT_STATUS } from "src/app/models/dividends";
import { ORDER_TYPE } from "src/app/models/filters";
import { DividendsService } from "src/app/services/dividends.service";

@Component({
  selector: "app-payout-history-table",
  templateUrl: "./payout-history-table.component.html",
  styleUrls: ["./payout-history-table.component.scss"],
})
export class PayoutHistoryTableComponent implements OnInit {
  searchField: string = "";

  payoutHistory = new MatTableDataSource<ISnapshot>();
  payoutHistoryColumns = ["ticker", "object", "investors", "dividends", "payment_date", "status"];
  payoutHistoryPage = 0;
  payoutHistoryPageSize = 10;
  payoutHistoryTotal = 0;
  payoutHistorySort: string[] = [
    `order[field]=${this.payoutHistoryColumns[4]}`,
    `order[direction]=${ORDER_TYPE.ASC}`,
  ];

  SnapshotStatus = SNAPSHOT_STATUS;

  constructor(private _dividendsService: DividendsService, private _router: Router) {}

  ngOnInit(): void {
    this.requestHistory();
  }

  async requestHistory() {
    const filters = this.createFilters();

    const res = await this._dividendsService.getPayoutsHistory(
      this.payoutHistoryPage,
      this.payoutHistoryPageSize,
      filters
    );
    this.payoutHistory.data = res.items;
    this.payoutHistoryTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  applyHistoryFilters() {
    this.payoutHistoryPage = 0;
    this.requestHistory();
  }

  onHistorySort(sort: Sort) {
    this.payoutHistorySort = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.applyHistoryFilters();
  }

  onHistoryPageChange(e: PageEvent) {
    this.payoutHistoryPage = e.pageIndex;
    this.requestHistory();
  }

  openDetailsPage(item: ISnapshot) {
    if (item.isExists) {
      this._router.navigateByUrl(`/admin/monetization/${item.id}/dividends-details`);
    }
  }

  renderStatus(status: SNAPSHOT_STATUS) {
    switch (status) {
      case SNAPSHOT_STATUS.PENDING:
        return "Pending";
      case SNAPSHOT_STATUS.COMPLETED:
        return "Completed";
      case SNAPSHOT_STATUS.NOT_STARTED:
        return "Not started";
      default:
        return "Unknown";
    }
  }

  private createFilters() {
    const filtersArray = [];

    if (this.searchField.trim()) {
      filtersArray.push(`search=${this.searchField}`);
    }

    if (this.payoutHistorySort.length) {
      filtersArray.push(...this.payoutHistorySort);
    }

    return filtersArray;
  }
}
