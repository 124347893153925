<app-header></app-header>

<section class="wrapper dialogue">
  <ng-container *ngIf="step === 'code'">
    <h2>{{ "EMAIL_CHANGE.TITLE" | translate }}</h2>
    <h4>
      {{ "EMAIL_CHANGE.DESCRIPTION" | translate : { userEmail } }}
    </h4>
    <app-input
      type="number"
      [caption]="'EMAIL_CHANGE.EMAIL_VERIFICATION_CODE' | translate"
      [(ngModel)]="code"
      (input)="sendVerificationCode()"
      icon="mail"
      [placeholder]="'EMAIL_CHANGE.ENTER_CODE_HERE' | translate"
      [error]="!isCodeValid && 'EMAIL_CHANGE.INVALID_CODE' | translate"
      [withSendButton]="true"
      (onSendClick)="resendCode()"
    ></app-input>
    <button class="button button_light" routerLink="/dashboard/settings">
      {{ "EMAIL_CHANGE.CANCEL" | translate }}
    </button>
  </ng-container>

  <ng-container *ngIf="step === 'email'">
    <h2>{{ "EMAIL_CHANGE.TITLE" | translate }}</h2>
    <h4>{{ "EMAIL_CHANGE.PLEASE_ENTER_NEW_EMAIL" | translate }}</h4>
    <app-input
      type="email"
      caption="Email"
      icon="mail"
      [placeholder]="'EMAIL_CHANGE.NEW_EMAIL' | translate"
      [formControl]="newEmail"
      [error]="newEmail.touched && newEmail.invalid && 'EMAIL_CHANGE.INVALID_EMAIL' | translate"
    ></app-input>
    <button class="button" [disabled]="newEmail.invalid || isPending" (click)="goToVerification()">
      {{ "EMAIL_CHANGE.CONTINUE" | translate }}
    </button>
  </ng-container>
</section>

<app-footer></app-footer>
