import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { SettingsService } from "../services/settings.service";

@Injectable({
  providedIn: "root",
})
export class IdentityGuard implements CanActivate {
  constructor(private settingsService: SettingsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.settingsService.identity === undefined) {
      this.settingsService.getUserIdentity().then(res => {
        if (res) {
          this.router.navigateByUrl("/");
          return false;
        } else {
          return true;
        }
      });
    }

    return true;
  }
}
