<section class="trans">
  <div class="trans__header">
    <h2 class="title">Transactions</h2>
  </div>

  <app-tabs
    [tabs]="tabs"
    [currentTabNumber]="selectedTabIndex"
    (tabSelected)="onTabChange($event)"
  ></app-tabs>

  <div [ngSwitch]="selectedTabIndex">
    <ng-template ngSwitchCase="0">
      <app-filter-bar
        [showSorting]="false"
        [showLayoutButtons]="false"
        usedIn="transactions"
        searchPlaceholder="Search by initializer email"
        (onFiltersApply)="onAdminFiltersApply($event)"
        (onSearch)="onAdminFiltersApply($event)"
      ></app-filter-bar>

      <div class="trans__table">
        <table
          mat-table
          [dataSource]="adminTxs"
          matSort
          (matSortChange)="handleSort('admin', $event)"
          class="matTable"
        >
          <ng-container [matColumnDef]="adminTxsColumns[0]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.id">
              {{ prop.id | shortenText }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="adminTxsColumns[1]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hash</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.hash">
              {{ prop.hash | shortenText }}
              <app-copy-button variant="outlined" [value]="prop.hash"></app-copy-button>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="adminTxsColumns[2]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Initializer</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.initializerEmail">
              {{ prop.initializerEmail ?? "-" }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="adminTxsColumns[3]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let prop">{{ renderType(prop.type) }}</td>
          </ng-container>
          <ng-container [matColumnDef]="adminTxsColumns[4]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td
              mat-cell
              *matCellDef="let prop"
              [ngClass]="{
                'mat-column-status_success': prop.status === 1,
                'mat-column-status_error': prop.status === 2,
                'mat-column-status_accent': prop.status === 0
              }"
            >
              {{ renderStatus(prop.status) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="adminTxsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: adminTxsColumns"
            (click)="openTransactionModal(row.id)"
          ></tr>
        </table>

        <mat-paginator
          [pageIndex]="adminTxsPage"
          [pageSize]="adminTxsPageSize"
          [length]="adminTxsTotal"
          (page)="handlePageChange('admin', $event)"
          aria-label="Select page"
        ></mat-paginator>
      </div>
    </ng-template>

    <ng-template ngSwitchCase="1">
      <app-filter-bar
        [showSorting]="false"
        [showLayoutButtons]="false"
        usedIn="transactions"
        searchPlaceholder="Search by initializer email"
        (onFiltersApply)="onClientFiltersApply($event)"
        (onSearch)="onClientFiltersApply($event)"
      ></app-filter-bar>

      <div class="trans__table">
        <table
          mat-table
          [dataSource]="clientTxs"
          matSort
          (matSortChange)="handleSort('client', $event)"
          class="matTable"
        >
          <ng-container [matColumnDef]="clientTxsColumns[0]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.id">
              {{ prop.id | shortenText }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="clientTxsColumns[1]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hash</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.hash">
              {{ prop.hash | shortenText }}
              <app-copy-button variant="outlined" [value]="prop.hash"></app-copy-button>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="clientTxsColumns[2]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Initializer</th>
            <td mat-cell *matCellDef="let prop" [title]="prop.initializerEmail">
              {{ prop.initializerEmail ?? "-" }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="clientTxsColumns[3]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let prop">{{ renderType(prop.type) }}</td>
          </ng-container>
          <ng-container [matColumnDef]="clientTxsColumns[4]">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td
              mat-cell
              *matCellDef="let prop"
              [ngClass]="{
                'mat-column-status_success': prop.status === 1,
                'mat-column-status_error': prop.status === 2,
                'mat-column-status_accent': prop.status === 0
              }"
            >
              {{ renderStatus(prop.status) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="clientTxsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: clientTxsColumns"
            (click)="openTransactionModal(row.id)"
          ></tr>
        </table>

        <mat-paginator
          [pageIndex]="clientTxsPage"
          [pageSize]="clientTxsPageSize"
          [length]="clientTxsTotal"
          (page)="handlePageChange('client', $event)"
          aria-label="Select page"
        ></mat-paginator>
      </div>
    </ng-template>
  </div>
</section>

<jw-modal id="tx-modal">
  <div class="infoModal">
    <div class="infoModal__header">
      <h3 class="title title_sub">Transaction details</h3>
      <button (click)="closeTransactionModal()">&#10006;</button>
    </div>
    <ng-container *ngIf="!txDataLoading; else loader">
      <div class="trans__modalFields">
        <p class="trans__modalRow">
          ID: <span>{{ txData.id }}</span>
        </p>
        <p class="trans__modalRow">
          Hash:
          <span
            >{{ txData.hash | shortenText }}
            <app-copy-button [value]="txData.hash"></app-copy-button
          ></span>
        </p>
        <p class="trans__modalRow">
          Type: <span>{{ renderType(txData.type) }}</span>
        </p>
        <p class="trans__modalRow">
          Status: <span>{{ renderStatus(txData.status) }}</span>
        </p>
        <p class="trans__modalRow" *ngIf="txData.failReason">
          Fail reason: <span>{{ renderFailReason(txData.failReason) }}</span>
        </p>
        <p class="trans__modalRow">
          Created: <span>{{ txData.createdAt | prettifyDate : "full" }}</span>
        </p>
        <p class="trans__modalRow">
          Updated: <span>{{ txData.updatedAt | prettifyDate : "full" }}</span>
        </p>
        <p class="trans__modalRow">
          Completed:
          <span>{{ txData.completedAt ? (txData.completedAt | prettifyDate : "full") : "-" }}</span>
        </p>
        <p class="trans__modalRow">
          Initializer email: <span>{{ txData.initializerEmail ?? "-" }}</span>
        </p>
        <p class="trans__modalRow">
          Canceller email: <span>{{ txData.cancellerEmail ?? "-" }}</span>
        </p>
        <ng-container *ngIf="txData.decodedData">
          <p class="trans__modalRow">Transaction data:</p>
          <pre class="trans__data">{{ txData.decodedData | json }}</pre>
        </ng-container>
      </div>

      <div class="infoModal__footer">
        <button
          class="button"
          *ngIf="txData.isRestartable"
          (click)="restartTransaction()"
          [disabled]="txDataButtonsDisabled"
        >
          Restart
        </button>
        <button
          class="button button_warning"
          *ngIf="txData.isCancellable"
          (click)="cancelTransaction()"
          [disabled]="txDataButtonsDisabled"
        >
          Cancel
        </button>
      </div>
    </ng-container>

    <ng-template #loader>
      <app-loader></app-loader>
    </ng-template>
  </div>
</jw-modal>
