import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ORDER_TYPE } from "src/app/models/filters";
import { ADMIN_PROPERTY_STATUS } from "src/app/models/property";
import { FiltersService } from "src/app/services/filters.service";
import { PropertiesService } from "src/app/services/properties.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { Utils } from "src/app/utils/utils";

@Component({
  templateUrl: "./admin-properties.component.html",
  styleUrls: ["./admin-properties.component.scss"],
})
export class AdminPropertiesComponent implements OnInit {
  TOKEN = TOKEN;
  Utils = Utils;
  dataSource = new MatTableDataSource();
  displayedColumns = [
    "ticker",
    "name",
    "start_at",
    "end_at",
    "target",
    "collected",
    "progress",
    "status",
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _router: Router,
    private _propertiesService: PropertiesService,
    private _filtersService: FiltersService
  ) {}

  async ngOnInit() {
    await this.getProperties(0);
  }

  async getProperties(page: number) {
    const res = await this._propertiesService.getAdminPropertiesList(
      page,
      this._filtersService.filtersQuery
    );
    this.dataSource.data = res.items;
    this.paginator.length = res.pagination.perPage * res.pagination.totalPages;
    this.paginator.pageSize = res.pagination.perPage;
    this.paginator.pageIndex = page;
    return res;
  }

  renderStatus(status: ADMIN_PROPERTY_STATUS) {
    switch (status) {
      default:
      case ADMIN_PROPERTY_STATUS.DRAFT:
        return "Not started";
      case ADMIN_PROPERTY_STATUS.STARTED:
        return "Collecting";
      case ADMIN_PROPERTY_STATUS.COMPLETED:
        return "Collected";
      case ADMIN_PROPERTY_STATUS.OUTDATED:
        return "Ended";
      case ADMIN_PROPERTY_STATUS.FAILED:
        return "Failed";
    }
  }

  navigateToCreate() {
    this._router.navigateByUrl("admin/properties/create");
  }

  onSearchApply() {
    this.getProperties(0);
  }

  onTableSorting(sort: Sort) {
    let dir;
    if (sort.direction === "asc") {
      dir = ORDER_TYPE.ASC;
    } else if (sort.direction === "desc") {
      dir = ORDER_TYPE.DESC;
    } else {
      dir = null;
    }

    const filter = {
      type: sort.active,
      order: dir,
    };

    this._filtersService.filterBarFilters = {
      ...this._filtersService.filterBarFilters,
      sorting: filter,
    };
    this.getProperties(0);
  }

  onFiltersApply() {
    this.getProperties(0);
  }

  onPageChange(e: PageEvent) {
    this.getProperties(e.pageIndex);
  }

  getProgress(progress: number) {
    return Math.min(progress, 100);
  }
}
