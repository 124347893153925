<main class="p2p">
  <app-main-header
    [headline]="
      selectedOrder
        ? 'Order #' + (selectedOrder.numericId | leadingZeroes)
        : ('P2P.SELL_TITLE' | translate)
    "
    [subtitle]="
      selectedOrder ? ('P2P.COMPLETE_SELL' | translate) : ('P2P.SELL_SUBTITLE' | translate)
    "
  ></app-main-header>

  <a class="button" routerLink="/dashboard/p2p" [queryParams]="{ currentTab: 1 }">{{
    "P2P.BACK_LINK" | translate
  }}</a>

  <section class="p2p__wrapper" *ngIf="propertyData && orders.data.length; else loader">
    <div class="box" [ngSwitch]="step">
      <ng-template ngSwitchCase="0">
        <div>
          <table
            mat-table
            [dataSource]="orders"
            matSort
            (matSortChange)="onSort($event)"
            class="matTable"
          >
            <ng-container [matColumnDef]="displayedColumns[0]">
              <th mat-header-cell *matHeaderCellDef>
                {{ "P2P.ORDER_ID" | translate }}
              </th>
              <td mat-cell *matCellDef="let prop">
                {{ prop.numericId | leadingZeroes }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="displayedColumns[1]">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "P2P.AVAILABLE_AREA" | translate }}
              </th>
              <td mat-cell *matCellDef="let prop">
                {{ prop.tokensAmount - prop.tokensFullfilled | prettifyNumber }}
                {{ propertyData.token.ticker }} ({{
                  (prop.tokensAmount - prop.tokensFullfilled) * propertyData.token.squarePerToken
                    | prettifyNumber
                }}
                m²)
              </td>
            </ng-container>
            <ng-container [matColumnDef]="displayedColumns[2]">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "PROPERTY.TOKEN_PRICE" | translate }}
              </th>
              <td mat-cell *matCellDef="let prop">
                {{ prop.tokenPrice | prettifyNumber }} {{ TOKEN }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="displayedColumns[3]">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "P2P.TOTAL_AMOUNT" | translate }}
              </th>
              <td mat-cell *matCellDef="let prop">
                {{ prop.tokensAmount * prop.tokenPrice | prettifyNumber }}
                {{ TOKEN }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="displayedColumns[4]">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let prop">
                <button
                  class="button button_error p2p__actionButton"
                  (click)="startSellSequence(prop.id)"
                >
                  {{ "PROPERTY.SELL" | translate }}
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            [pageIndex]="page"
            [pageSize]="10"
            [length]="ordersCount"
            aria-label="Select page"
            (page)="onPageChange($event)"
          ></mat-paginator>
        </div>
      </ng-template>

      <ng-template ngSwitchCase="1">
        <app-buy-sell-form
          type="sell"
          usedIn="p2p"
          [propertyData]="propertyData"
          [p2pOrderData]="selectedOrder"
          [ownedAmount]="ownedAmount"
          (onCancel)="goBackToListing()"
        ></app-buy-sell-form>
      </ng-template>
    </div>

    <app-property-card cardType="p2p" [data]="propertyData"></app-property-card>
  </section>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</main>
