import { Component } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { WalletService } from "./services/wallet.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "invest-front";
  loading: boolean = true;

  constructor(public translate: TranslateService, private router: Router) {
    translate.addLangs(["en"]);
    translate.setDefaultLang("en");

    this.router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof ResolveStart) {
      this.loading = true;
    }

    if (routerEvent instanceof ResolveEnd) {
      this.loading = false;
    }
  }
}
