<app-header></app-header>

<section class="login dialogue">
  <ng-template [ngIf]="verificationStep === 1">
    <h2>{{ "IDENTITY.FIRST_STEP_TITLE" | translate }}</h2>
    <h4>{{ "IDENTITY.FIRST_STEP_DESCR" | translate }}</h4>

    <form class="form" [formGroup]="firstForm" (ngSubmit)="handleFormSubmit(verificationStep)">
      <app-input
        [caption]="'IDENTITY.FIRST_NAME' | translate"
        formControlName="firstName"
        [error]="renderControlErrorMessage(firstForm.controls.firstName) | translate"
        [shouldFocus]="true"
      ></app-input>
      <app-input
        [caption]="'IDENTITY.LAST_NAME' | translate"
        formControlName="secondName"
        [error]="renderControlErrorMessage(firstForm.controls.secondName) | translate"
      ></app-input>
      <app-input
        type="date"
        [caption]="'IDENTITY.DATE_OF_BIRTH' | translate"
        formControlName="birthDate"
        [error]="renderControlErrorMessage(firstForm.controls.birthDate) | translate"
      ></app-input>

      <div>
        <button type="button" class="referralBtn" (click)="showRefInput = !showRefInput">
          {{ "IDENTITY.ENTER_REFERRAL_ID" | translate }}
        </button>
        <ng-template [ngIf]="showRefInput">
          <app-input
            formControlName="referralCode"
            [error]="
              firstForm.controls.referralCode.invalid && ('IDENTITY.WRONG_REFERRAL' | translate)
            "
          ></app-input>
        </ng-template>
      </div>

      <app-switch
        [caption]="'IDENTITY.AGREE_TO_TERMS' | translate : { terms: '/', privacy: '/' }"
        formControlName="areTermsAccepted"
      ></app-switch>

      <button type="submit" class="button" [disabled]="firstForm.invalid || isPending">
        {{ "IDENTITY.CONTINUE" | translate }}
      </button>
    </form>
  </ng-template>

  <ng-template [ngIf]="verificationStep === 2">
    <h2>{{ "IDENTITY.SECOND_STEP_TITLE" | translate }}</h2>
    <h4>{{ "IDENTITY.SECOND_STEP_DESCR" | translate }}</h4>

    <form class="form" [formGroup]="secondForm" (ngSubmit)="handleFormSubmit(verificationStep)">
      <div class="form__input">
        <span class="caption">{{ "IDENTITY.PHONE" | translate }}</span>
        <ngx-intl-tel-input
          [cssClass]="'input'"
          [enableAutoCountrySelect]="false"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="phoneSelectedCountryISO"
          [maxLength]="15"
          [phoneValidation]="true"
          [numberFormat]="PhoneNumberFormat.National"
          name="phoneNumber"
          formControlName="phoneNumber"
        >
        </ngx-intl-tel-input>
        <small
          *ngIf="secondForm.controls.phoneNumber.touched && secondForm.controls.phoneNumber.invalid"
          class="inputError"
        >
          {{ renderControlErrorMessage(secondForm.controls.phoneNumber) | translate }}
        </small>
      </div>
      <app-input
        [caption]="'IDENTITY.STREET' | translate"
        formControlName="street"
        [error]="renderControlErrorMessage(secondForm.controls.street) | translate"
      ></app-input>
      <app-input
        [caption]="'IDENTITY.CITY' | translate"
        formControlName="city"
        [error]="renderControlErrorMessage(secondForm.controls.city) | translate"
      ></app-input>

      <div class="twoColsWrapper">
        <app-input
          type="number"
          [caption]="'IDENTITY.POSTAL_CODE' | translate"
          formControlName="postalCode"
          [error]="renderControlErrorMessage(secondForm.controls.postalCode) | translate"
        ></app-input>
        <div class="countrySelectWrapper">
          <span>{{ "IDENTITY.COUNTRY" | translate }}</span>
          <app-select
            *ngIf="countriesList != null"
            [items]="getCountriesNames()"
            [selectedItem]="secondForm.controls.country.value"
            (pick)="setCountry($event)"
          ></app-select>
        </div>
      </div>

      <button type="submit" class="button" [disabled]="secondForm.invalid || isPending">
        {{ "IDENTITY.CONTINUE" | translate }}
      </button>
    </form>
  </ng-template>

  <ng-template [ngIf]="verificationStep === 3">
    <h2>{{ "IDENTITY.THIRD_STEP_TITLE" | translate }}</h2>
    <h4>
      {{ "IDENTITY.THIRD_STEP_DESCR" | translate }}
    </h4>
    <h4>{{ "SETTINGS.COMPLETE_VERIFICATION" | translate }}</h4>
    <div class="twoColsWrapper">
      <button class="button button_light" (click)="skipSumSub()">
        {{ "SETTINGS.MAYBE_LATER" | translate }}
      </button>
      <button class="button" (click)="handleFormSubmit(verificationStep)">
        {{ "SETTINGS.START_VERIFICATION" | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template [ngIf]="verificationStep === 4">
    <app-sumsub-container></app-sumsub-container>
  </ng-template>
</section>

<app-footer></app-footer>
