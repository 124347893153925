import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IPropertyInfo } from "src/app/models/property";
import { PropertiesService } from "src/app/services/properties.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./properties-create.component.html",
  styleUrls: ["./properties-create.component.scss"],
})
export class PropertiesCreateComponent implements OnInit {
  isPending = false;

  constructor(
    private propertiesService: PropertiesService,
    private router: Router,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {}

  async handleFormSubmit(data: IPropertyInfo) {
    this.isPending = true;

    try {
      const asFormData = this.getFormData(data);
      const newPropertyId: any = await this.propertiesService.createProperty(asFormData);
      this._toastService.open("Property created!", "success");
      this.router.navigateByUrl(`/admin/properties/${newPropertyId.data}`);
    } catch (error) {
      this._toastService.open("Please check the fields", "error");
      throw error;
    } finally {
      this.isPending = false;
    }
  }

  private getFormData(object: IPropertyInfo) {
    const formData = new FormData();
    const {
      images,
      name,
      shortDescription,
      fullDescription,
      address,
      propertyType,
      rentType,
      totalSpace,
      livingSpace,
      roomsAmount,
    } = object;

    if (images.length > 0) {
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        formData.append("images", element);
      }
    }

    for (let i = 0; i < name.length; i++) {
      formData.append(`name[${i}][language]`, name[i].language);
      formData.append(`name[${i}][title]`, name[i].title);
    }

    for (let i = 0; i < shortDescription.length; i++) {
      formData.append(`shortDescription[${i}][language]`, shortDescription[i].language);
      formData.append(`shortDescription[${i}][title]`, shortDescription[i].title);
    }

    for (let i = 0; i < fullDescription.length; i++) {
      formData.append(`fullDescription[${i}][language]`, fullDescription[i].language);
      formData.append(`fullDescription[${i}][title]`, fullDescription[i].title);
    }

    formData.append("propertyType", String(propertyType));
    formData.append("rentType", String(rentType));
    formData.append("totalSpace", String(totalSpace));
    formData.append("livingSpace", String(livingSpace));
    formData.append("roomsAmount", String(roomsAmount));

    formData.append("address[address]", address.address);
    formData.append("address[district]", address.district);
    formData.append("address[city]", address.city);
    formData.append("address[country]", address.country);

    return formData;
  }
}
