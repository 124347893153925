import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-logout-button",
  templateUrl: "./logout-button.component.html",
  styleUrls: ["./logout-button.component.scss"],
})
export class LogoutButtonComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {}

  logout() {
    this._authService.logout();
  }
}
