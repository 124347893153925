import { IPagination } from "./api-response";
import { ILanguageField } from "./settings";

export interface IProperty {
  id: string;
  name: string;
  tags: string[];
  description: string;
  images: string[];
  startInvestingFrom: number;
  estimatedIncome: number;
  collected: number;
  target: number;
}

export interface IDetailedProperty {
  id: string;
  name: string;
  images: string[];
  tags: string[];
  fullDescription: string;
  shortDescription: string;
  tokenPrice: number;
  address: {
    address: string;
    city: string;
    district: string;
    country: string;
  };
  housingInfo: {
    totalArea: number;
    livingSpace: number;
    rooms: number;
    objectType: string;
    rentType: string;
  };
  investInfo: {
    totalPrice: number;
    collected: number;
    estimatedAnnualIncome: number;
    minInvestTokensAmount: number;
    earned: number;
    invested: number;
    firstPurchaseDate: string;
  };
  token: {
    ticker: string;
    price: number;
    squarePerToken: number;
    amount: number;
    left: number;
  };
  totalInvestors: number;
  rentalDemand: RENTAL_DEMAND;
  fundraisingStartDate: string;
  isP2PEnabled: boolean;
  canBeBought: boolean;
  dividend: {
    daysToNextPayment: string;
    income: number;
  };
}

export interface IPortfolioResponse {
  items: IProperty[];
  meta: {
    pendingProperties: IProperty[];
    totalStats: {
      propertiesAmount: number;
      totalIncome: number;
      totalInvested: number;
      totalQuantity: number;
    };
  };
  pagination: IPagination;
}

export enum RENTAL_DEMAND {
  AVERAGE,
  HIGH,
}

export enum PROPERTY_TYPE {
  FULL,
  ROOM,
  HOSTEL,
  CAPSULE,
}

export enum RENT_TYPE {
  HOUSE,
  FLAT,
  TOWNHOUSE,
  COMMERCIAL,
}

export interface IPropertyInfo {
  name: ILanguageField[];
  shortDescription: ILanguageField[];
  fullDescription: ILanguageField[];
  propertyType: PROPERTY_TYPE;
  totalSpace: number;
  livingSpace: number;
  roomsAmount: number;
  rentType: RENT_TYPE;
  address: {
    country: string;
    city: string;
    district: string;
    address: string;
  };
  isVisible?: boolean;
  isP2PEnabled?: boolean;
  images?: any;
  imagesIds?: any[];
  status: ADMIN_PROPERTY_STATUS;
}

export enum ADMIN_PROPERTY_STATUS {
  DRAFT,
  STARTED,
  COMPLETED,
  FAILED,
  OUTDATED,
}

export interface IRawProperty extends IPropertyInfo {
  token: IToken;
  investInfo: IInvestInfo;
}

export interface IInvestInfo {
  demand: number;
  investEndAt: string;
  investStartAt: string;
  minInvestAmount: number;
  profitability: number;
  totalPrice: number;
  target?: number;
  collected?: number;
}

export enum SMART_CONTRACT_STATUS {
  NOT_STARTED,
  DEPLOYING,
  DEPLOYED,
}

export interface IToken {
  name: string;
  squarePerToken: number;
  ticker: string;
  scStatus: SMART_CONTRACT_STATUS;
  amount: number;
  price: string;
  scId: string;
  transaction: {
    id: string;
    hash: string;
    status: SC_TRANSACTION_STATUS;
    updatedAt: string;
  };
}

export interface IAdminPropertyItem {
  id: string;
  ticker: string;
  name: string;
  investStartsAt: string;
  investEndAt: string;
  totalPrice: number;
  collected: number;
  progress: number;
  status: ADMIN_PROPERTY_STATUS;
}

export interface IPropertyRangeFiltersResponse {
  targetPrice: {
    from: number;
    to: number;
  };
  estimatedAnnualIncome: {
    from: number;
    to: number;
  };
  minPurchaseAmount: {
    from: number;
    to: number;
  };
}

export interface IAdminPropertiesRangeFiltersResponse {
  investStartAt: {
    from: string;
    to: string;
  };
  investEndAt: {
    from: string;
    to: string;
  };
  target: {
    from: number;
    to: number;
  };
  collected: {
    from: number;
    to: number;
  };
}

export interface IImageData {
  id: string;
  path: string;
  order: number;
}

export enum TRANSACTION_TYPE {
  BUY,
  SELL,
  P2P,
}

export interface IPropertyTransaction {
  adrdAmount: string;
  createdAt: string;
  fromId: string;
  id: string;
  propertyId: string;
  propertyName: string;
  squarePerToken: string;
  status: SC_TRANSACTION_STATUS;
  toId: string;
  tokenTicker: string;
  tokensAmount: number;
  type: TRANSACTION_TYPE;
}

export enum SC_TRANSACTION_STATUS {
  PENDING,
  COMPLETED,
  FAILED,
  NOT_STARTED,
  CANCELLED,
}

export interface IOwnedPropertyAmount {
  tokens: number;
  quantity: number;
}
