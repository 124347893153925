import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "leadingZeroes",
})
export class LeadingZeroesPipe implements PipeTransform {
  transform(value: number): string {
    let strValue = String(value);

    return strValue.padStart(8, "0");
  }
}
