import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ADMIN_PROPERTY_RANGE_FILTERS,
  FUNDING_STATUS,
  IRangeFilter,
  ISortingField,
  LAYOUT_VARIANT,
  LOCATIONS,
  P2P_BUY_RANGE_FILTERS,
  P2P_BUY_SORTING_LIST,
  P2P_SELL_RANGE_FILTERS,
  P2P_SELL_SORTING_LIST,
  PROPERTIES_SORTING_LIST,
  RANGE_FILTERS,
  TRANSACTION_STATUS_FILTER,
  TRANSACTION_TYPE_FILTER,
} from "src/app/models/filters";
import { FiltersService } from "src/app/services/filters.service";
import { PropertiesService } from "src/app/services/properties.service";
import { SettingsService } from "src/app/services/settings.service";
import { ModalService } from "../_modal";
import { P2pService } from "src/app/services/p2p.service";

interface IAppliedFilter {
  elementIndex?: number;
  field: string;
  content: string;
}

type ChipFormFields = "statuses" | "types";

@Component({
  selector: "app-filter-bar",
  templateUrl: "./filter-bar.component.html",
  styleUrls: ["./filter-bar.component.scss"],
})
export class FilterBarComponent implements OnInit {
  @Input() showSorting: boolean = true;
  @Input() showLayoutButtons: boolean = true;
  @Input() usedIn: "properties" | "p2pBuy" | "p2pSell" | "adminProperties" | "transactions";
  @Input() searchPlaceholder: string = "Find a property";

  @Output() onSearch = new EventEmitter();
  @Output() onChangeLayout = new EventEmitter();
  @Output() onFiltersApply = new EventEmitter();
  @Output() onSortingChange = new EventEmitter();

  searchValue: string = "";

  sortingList: ISortingField[] = [];
  currentSorting: ISortingField = null;

  fundingStatuses: typeof FUNDING_STATUS;
  transactionStatuses: typeof TRANSACTION_STATUS_FILTER;
  transactionTypes: typeof TRANSACTION_TYPE_FILTER;

  rangeFilters: IRangeFilter[] = null;

  LayoutVariant = LAYOUT_VARIANT;
  selectedLayout = this._settingsService.layoutType;
  appliedFilters: IAppliedFilter[] = [];

  filterForm: FormGroup;

  constructor(
    private _modalService: ModalService,
    private formBuilder: FormBuilder,
    private _settingsService: SettingsService,
    private _filtersService: FiltersService,
    private router: Router,
    private _propertiesService: PropertiesService,
    private _p2pService: P2pService
  ) {}

  async ngOnInit() {
    this.filterForm = this.formBuilder.group({});
    await this.getAndDistributeRanges(this.usedIn);

    switch (this.usedIn) {
      case "properties":
        this.sortingList = PROPERTIES_SORTING_LIST;
        this.filterForm.addControl("statuses", this.formBuilder.control(null));
        this.fundingStatuses = [FUNDING_STATUS[1], FUNDING_STATUS[2]];
        break;
      case "adminProperties":
        this.sortingList = PROPERTIES_SORTING_LIST;
        this.filterForm.addControl("statuses", this.formBuilder.control(null));
        this.fundingStatuses = FUNDING_STATUS;
        break;
      case "p2pBuy":
        this.sortingList = P2P_BUY_SORTING_LIST;
        break;
      case "p2pSell":
        this.sortingList = P2P_SELL_SORTING_LIST;
        break;
      case "transactions":
        this.filterForm.addControl("types", this.formBuilder.control(null));
        this.filterForm.addControl("statuses", this.formBuilder.control(null));
        this.transactionStatuses = TRANSACTION_STATUS_FILTER;
        this.transactionTypes = TRANSACTION_TYPE_FILTER;
        break;
      default:
        break;
    }

    if (this.rangeFilters) {
      for (const slider of this.rangeFilters) {
        if (slider.type === "number") {
          this.filterForm.addControl(
            slider.name,
            this.formBuilder.control({
              from: +slider.from,
              to: +slider.to,
            })
          );
        } else if (slider.type === "date") {
          this.filterForm.addControl(
            slider.name,
            this.formBuilder.group({ from: slider.from, to: slider.to })
          );
        }
        this.filterForm.controls[slider.name].setValidators(rangeValidator());
        this.filterForm.updateValueAndValidity();
      }
    }

    this._settingsService.layoutVariantChange.subscribe((value: LAYOUT_VARIANT) => {
      this.selectedLayout = value;
    });

    if (this._filtersService.filterBarFilters && this.router.url.includes("properties")) {
      const filters = this._filtersService.filterBarFilters;
      this.searchValue = filters.search;
      this.filterForm.patchValue(this._filtersService.filterBarFilters);
    }
  }

  async getAndDistributeRanges(usedIn: typeof this.usedIn) {
    let filters: IRangeFilter[];
    let response: any;

    switch (usedIn) {
      case "properties":
        filters = [...RANGE_FILTERS];
        response = await this._propertiesService.getPropertyRanges();
        break;
      case "adminProperties":
        filters = [...ADMIN_PROPERTY_RANGE_FILTERS];
        response = await this._propertiesService.getAdminPropertyRanges();
        break;
      case "p2pBuy":
        filters = [...P2P_BUY_RANGE_FILTERS];
        response = await this._p2pService.getListingRanges("sell");
        break;
      case "p2pSell":
        filters = [...P2P_SELL_RANGE_FILTERS];
        response = await this._p2pService.getListingRanges("buy");
        break;
    }

    if (!response) {
      return;
    }

    for (const name in response) {
      const founded = filters.find(f => f.name === name);
      if (founded) {
        founded.from = response[name].from;
        founded.to = response[name].to;
      }
    }

    this.rangeFilters = filters;
  }

  onSearchClick() {
    this._filtersService.filterBarFilters = {
      ...this._filtersService.filterBarFilters,
      search: this.searchValue,
    };
    this.onSearch.emit();
  }

  getSortingVariants() {
    if (this._filtersService.filterBarFilters?.sorting) {
      this.currentSorting = this._filtersService.filterBarFilters.sorting;
    } else {
      this.currentSorting = this.sortingList[0];
    }
    return this.sortingList.map(s => s.name);
  }

  setSortingVariant(sortBy: string) {
    const filter = this.sortingList.find(item => sortBy === item.name);
    this.currentSorting = filter;

    this._filtersService.filterBarFilters = {
      ...this._filtersService.filterBarFilters,
      sorting: filter,
    };

    this.onSortingChange.emit();
  }

  setSelectedLayout(layout: LAYOUT_VARIANT) {
    this._settingsService.setLayoutVariant(layout);
  }

  openFiltersModal() {
    this._modalService.open("filtersModal");
  }
  closeFiltersModal() {
    this._modalService.close("filtersModal");
  }

  onFilterSubmit() {
    const gathered = { ...this._filtersService.filterBarFilters, ...this.filterForm.value };
    this._filtersService.filterBarFilters = gathered;

    this.onFiltersApply.emit();
    this.closeFiltersModal();
  }

  onFilterReset() {
    this.resetForm();

    const empties: any = {};
    Object.keys(this.filterForm.value).forEach(key => {
      empties[key] = null;
    });

    const gathered = { ...this._filtersService.filterBarFilters, ...empties };
    this._filtersService.filterBarFilters = gathered;

    this.onFiltersApply.emit();
    this.closeFiltersModal();
  }

  handleChipClick(idx: number, fieldName: ChipFormFields) {
    if (this.filterForm.value[fieldName]?.length > 0) {
      const newArr = [...this.filterForm.value[fieldName]];

      if (newArr.includes(idx)) {
        const editableIdx = newArr.findIndex(el => el === idx);
        newArr.splice(editableIdx, 1);
      } else {
        newArr.push(idx);
      }

      this.filterForm.controls[fieldName].setValue(newArr);
    } else {
      this.filterForm.controls[fieldName].setValue([idx]);
    }
  }

  getChipIsSelected(idx: number, fieldName: ChipFormFields) {
    return this.filterForm.value[fieldName]?.includes(idx);
  }

  resetForm() {
    for (const filterName in this.filterForm.value) {
      const rangeFilter = this.rangeFilters?.find(r => r.name === filterName);
      if (rangeFilter) {
        this.filterForm.controls[filterName].setValue({
          from: rangeFilter.from,
          to: rangeFilter.to,
        });
      } else {
        this.filterForm.controls[filterName].reset();
      }
    }
  }
}

function rangeValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value.from == null || control.value.to === null) {
      return { rangeError: true };
    } else {
      return null;
    }
  };
}
