<section class="adminReq">
  <div class="adminReq__header">
    <h2 class="title">Requests</h2>
  </div>

  <!-- TODO just search input -->
  <!-- <app-filter-bar
    [showSorting]="false"
    [showLayoutButtons]="false"
    usedIn="adminRequests"
  ></app-filter-bar> -->

  <div class="adminReq__table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table"
      matSort
      (matSortChange)="onSort($event)"
      [matSortActive]="displayedColumns[1]"
      matSortDirection="desc"
    >
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.id">{{ prop.id | shortenText }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.date | prettifyDate : 'full'">
          {{ prop.date | prettifyDate }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let prop">
          {{ renderType(prop.type) }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset</th>
        <td mat-cell *matCellDef="let prop">
          {{ prop.currency | uppercase }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let prop">{{ prop.amountCurrency | prettifyNumber }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount ({{ TOKEN }})</th>
        <td mat-cell *matCellDef="let prop">{{ prop.amountAdrd | prettifyNumber }}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[6]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hash</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.hash">
          {{ (prop.hash | shortenText) ?? "-" }}
          <app-copy-button
            *ngIf="prop.hash"
            [value]="prop.hash"
            variant="outlined"
          ></app-copy-button>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[7]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let prop" [title]="prop.userId">
          {{ prop.userId && (prop.userId | shortenText) }}
          <app-copy-button [value]="prop.userId" variant="outlined"></app-copy-button>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[8]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td
          mat-cell
          *matCellDef="let prop"
          [ngClass]="{
            'mat-column-status_success': prop.status === 1,
            'mat-column-status_error': prop.status === 2,
            'mat-column-status_accent': prop.status === 0
          }"
        >
          {{ getStatusText(prop.status) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="setRequestAndOpenModal(row)"
      ></tr>
    </table>

    <mat-paginator aria-label="Select page" (page)="onPageChange($event)"></mat-paginator>
  </div>
</section>

<jw-modal id="deposit-request-modal">
  <div *ngIf="selectedRequest" class="reqModal" [formGroup]="creditForm">
    <div class="reqModal__header">
      <h3 class="title title_sub">Deposit request</h3>
      <button (click)="closeRequestModal()">&#10006;</button>
    </div>
    <p class="reqModal__row">
      Deposit ID
      <span>
        {{ selectedRequest.id | shortenText }}
        <app-copy-button [value]="selectedRequest.id" variant="outlined"></app-copy-button>
      </span>
    </p>
    <div class="reqModal__summary">
      <h4>Summary:</h4>
      <div class="reqModal__fields">
        <p class="reqModal__row">
          Date <span>{{ selectedRequest.date | prettifyDate : "full" }}</span>
        </p>
        <p class="reqModal__row">
          You pay in crypto
          <span
            >{{ selectedRequest.amountCurrency }} {{ selectedRequest.currency | uppercase }}</span
          >
        </p>
        <p class="reqModal__row">
          User email
          <span>
            {{ selectedRequestUserEmail }}
            <app-copy-button
              [value]="selectedRequestUserEmail"
              variant="outlined"
            ></app-copy-button>
          </span>
        </p>
        <p class="reqModal__row">
          Comment
          <span>{{ selectedRequest.comment ? selectedRequest.comment : "-" }}</span>
        </p>
        <p class="reqModal__row">
          You receive in {{ TOKEN }} <span>{{ selectedRequest.amountAdrd }} {{ TOKEN }}</span>
        </p>
        <p class="reqModal__row">
          EUR equivalent
          <span
            >{{
              selectedRequest.currencyPrice * selectedRequest.amountCurrency | prettifyNumber
            }}
            EUR</span
          >
        </p>
        <p class="reqModal__row">
          Rate ({{ selectedRequest.currency | uppercase }}/EUR)
          <span>{{ selectedRequest.currencyPrice | prettifyNumber }} EUR</span>
        </p>
        <p class="reqModal__row">
          Status
          <span class="reqModal__statusText">{{ getStatusText(selectedRequest.status) }}</span>
        </p>
      </div>
    </div>
    <app-input
      tag="textarea"
      caption="Transaction hash"
      placeholder="None"
      [withCopyButton]="true"
      [disabled]="true"
      [ngModel]="selectedRequest.hash"
      [ngModelOptions]="{ standalone: true }"
    ></app-input>
    <div class="reqModal__inputBlock" *ngIf="selectedRequest.status === 0">
      <app-input
        type="number"
        caption="Credit the balance"
        [placeholder]="selectedRequest.amountAdrd + ' ' + TOKEN"
        formControlName="amountAdrd"
      ></app-input>
      <button
        class="button"
        [disabled]="creditForm.invalid || !selectedRequest.hash"
        (click)="handleOpenConfirmationModal()"
      >
        Credit
      </button>
    </div>

    <button class="button" (click)="closeRequestModal()">Close</button>
  </div>
</jw-modal>

<jw-modal id="deposit-request-confirmation-modal">
  <div class="reqModal" [formGroup]="confirmationForm">
    <div class="reqModal__header">
      <h3 class="title title_sub">
        Deposit {{ creditForm.value.amountAdrd }} {{ TOKEN }} to user account
      </h3>
      <button (click)="handleCloseConfirmationModal()">&#10006;</button>
    </div>
    <app-input
      tag="textarea"
      caption="Enter the transaction hash"
      formControlName="hash"
    ></app-input>
    <div class="reqModal__buttons">
      <button class="button button_outline" (click)="handleCloseConfirmationModal()">Cancel</button>
      <button
        class="button"
        [disabled]="confirmationForm.invalid || getHashesMatch()"
        (click)="handleAcceptTransaction()"
      >
        Confirm
      </button>
    </div>
  </div>
</jw-modal>
