import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { UserListItem } from "src/app/models/account";
import { AccountsService } from "src/app/services/accounts.service";
import { SUMSUB_STATUS } from "src/app/services/sumsub.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { ORDER_TYPE } from "src/app/models/filters";

@Component({
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  TOKEN = TOKEN;
  users = new MatTableDataSource<UserListItem>();
  usersColumns = [
    "email",
    "verification",
    "wallet_balance",
    "invested",
    "total_balance",
    "properties_count",
    "created_at",
  ];
  usersPage = 0;
  usersPageSize = 10;
  usersTotal = 0;
  usersSort: string[] = [
    `order[field]=${this.usersColumns[6]}`,
    `order[direction]=${ORDER_TYPE.DESC}`,
  ];
  searchField: string = "";

  constructor(private _router: Router, private _accountsService: AccountsService) {}

  ngOnInit(): void {
    this.requestUsers();
  }

  async requestUsers() {
    const filters = this.createFilters();
    const res = await this._accountsService.getAccounts(
      this.usersPage,
      this.usersPageSize,
      filters
    );
    this.users.data = res.items;
    this.usersTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  handleSort(sort: Sort) {
    this.usersSort = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.applyFilters();
  }

  handlePageChange(page: PageEvent) {
    this.usersPage = page.pageIndex;
    this.requestUsers();
  }

  applyFilters() {
    this.usersPage = 0;
    this.requestUsers();
  }

  openUserPage(userId: string) {
    this._router.navigate(["/admin/users", userId]);
  }

  renderVerificationStatus(status: SUMSUB_STATUS) {
    switch (status) {
      case SUMSUB_STATUS.COMPLETE:
        return "Completed";
      case SUMSUB_STATUS.FAILED:
        return "Failed";
      case SUMSUB_STATUS.PENDING:
        return "Pending";
      case SUMSUB_STATUS.REQUIRED:
        return "Required";
      case SUMSUB_STATUS.RETRY:
        return "Retry";
    }
  }

  private createFilters() {
    const filtersArray = [];

    if (this.searchField.trim()) {
      filtersArray.push(`search=${this.searchField}`);
    }

    if (this.usersSort.length) {
      filtersArray.push(...this.usersSort);
    }

    return filtersArray;
  }
}
