<section class="withdraw">
  <app-main-header
    [headline]="'WITHDRAWAL.TITLE' | translate"
    [subtitle]="'WITHDRAWAL.SUBTITLE' | translate"
  ></app-main-header>

  <form
    class="withdraw__box"
    [formGroup]="withdrawalForm"
    (ngSubmit)="nextStep()"
    *ngIf="step === 0"
  >
    <h3 class="title title_sub">{{ "WITHDRAWAL.ENTER_AMOUNT" | translate }}</h3>
    <app-big-input
      [caption]="'WITHDRAWAL.YOU_WITHDRAW' | translate"
      [textConditions]="'WITHDRAWAL.CONDITIONS' | translate : { min: 20, max: 100 }"
      ticker="EURD"
      formControlName="amount"
      [error]="getAmountError()"
    ></app-big-input>
    <div class="withdraw__doubleColumn">
      <p class="withdraw__infoItem">
        {{ "WITHDRAWAL.YOU_RECEIVE" | translate }}
        <span>{{ renderReceiveAmount() | prettifyNumber }} EUR</span>
      </p>
      <p class="withdraw__infoItem">
        {{ "WITHDRAWAL.COMMISSION" | translate }} <span>{{ commissionPercent }}%</span>
      </p>
    </div>
    <app-switch
      formControlName="isTermsAccepted"
      [caption]="'AGREE_TO_TERMS' | translate : { link: '/' }"
    ></app-switch>
    <div class="withdraw__doubleColumn">
      <a routerLink="/dashboard/wallet" class="button button_outline">
        {{ "WITHDRAWAL.CANCEL" | translate }}
      </a>
      <button type="submit" class="button" [disabled]="withdrawalForm.invalid">
        {{ "WITHDRAWAL.PROCEED" | translate }}
      </button>
    </div>
  </form>

  <form class="withdraw__box" [formGroup]="bankForm" (ngSubmit)="nextStep()" *ngIf="step === 1">
    <h4 class="title title_sub">{{ "WITHDRAWAL.ENTER_YOUR_BANK" | translate }}</h4>
    <div class="withdraw__doubleColumn">
      <app-input caption="IBAN" type="number" formControlName="iban"></app-input>
      <app-input caption="BIC" type="number" formControlName="bic"></app-input>
    </div>
    <app-input
      [caption]="'WITHDRAWAL.RECIPIENT_NAME' | translate"
      formControlName="recipientName"
    ></app-input>
    <app-switch
      [caption]="'WITHDRAWAL.CONFIRM_BANK_ACCOUNT' | translate"
      formControlName="isAccountConfirmation"
    ></app-switch>
    <div class="withdraw__doubleColumn">
      <button type="button" class="button button_outline" (click)="prevStep()">
        {{ "WITHDRAWAL.BACK" | translate }}
      </button>
      <button type="submit" class="button" [disabled]="bankForm.invalid">
        {{ "WITHDRAWAL.PROCEED" | translate }}
      </button>
    </div>
  </form>

  <div class="withdraw__box" *ngIf="step === 2">
    <h4 class="title title_sub">{{ "WITHDRAWAL.CONFIRM_WITHDRAWAL" | translate }}</h4>
    <div class="withdraw__doubleColumn">
      <p class="withdraw__infoItem">
        {{ "WITHDRAWAL.YOU_WITHDRAW" | translate }}
        <span>{{ withdrawalForm.value.amount }} EURD</span>
      </p>
      <p class="withdraw__infoItem">
        {{ "WITHDRAWAL.YOU_RECEIVE" | translate }}
        <span>{{ renderReceiveAmount() | prettifyNumber }} EUR</span>
      </p>
    </div>
    <hr class="withdraw__divider" />
    <p class="withdraw__infoItem">
      IBAN <span>{{ bankForm.value.iban }}</span>
    </p>
    <div class="withdraw__doubleColumn">
      <p class="withdraw__infoItem">
        BIC <span>{{ bankForm.value.bic }}</span>
      </p>
      <p class="withdraw__infoItem">
        {{ "WITHDRAWAL.RECIPIENT_NAME" | translate }}
        <span>{{ bankForm.value.recipientName }}</span>
      </p>
    </div>
    <hr class="withdraw__divider" />
    <app-input
      [caption]="'WITHDRAWAL.VERIFICATION_CODE' | translate"
      [note]="'WITHDRAWAL.VERIFICATION_NOTE' | translate : { email: '***@***.com' }"
      type="number"
      [withSendButton]="true"
      [(ngModel)]="confirmationCode"
      (onSendClick)="requestCode($event)"
    ></app-input>
    <div class="withdraw__doubleColumn">
      <button type="button" class="button button_outline" (click)="prevStep()">
        {{ "WITHDRAWAL.BACK" | translate }}
      </button>
      <button class="button" (click)="confirmRequest()" [disabled]="!confirmationCode">
        {{ "WITHDRAWAL.CONFIRM_REQUEST" | translate }}
      </button>
    </div>
  </div>

  <div class="withdraw__box" *ngIf="step === 3">
    <h4 class="title title_sub">{{ "WITHDRAWAL.CONFIRM_TITLE" | translate }}</h4>
    <p class="withdraw__text">
      {{ "WITHDRAWAL.CONFIRM_TEXT" | translate }}
    </p>
    <a class="button" routerLink="/dashboard/wallet">Go to Wallet</a>
  </div>
</section>
