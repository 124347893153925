<form class="filters">
  <div class="filters__inputs">
    <app-input
      [placeholder]="searchPlaceholder"
      type="search"
      [(ngModel)]="searchValue"
      name="search"
      icon="search"
      (onSearchClick)="onSearchClick()"
    ></app-input>

    <button class="button button_light filters__button" (click)="openFiltersModal()">
      <img src="assets/icons/filters.svg" alt="filters" />
      {{ "FILTERS.TITLE" | translate }}
    </button>

    <app-select
      *ngIf="showSorting"
      [items]="getSortingVariants()"
      [selectedItem]="currentSorting?.name"
      (pick)="setSortingVariant($event)"
    ></app-select>

    <div class="filters__layoutTabs" *ngIf="showLayoutButtons">
      <button
        class="filters__layoutTabBtn"
        [ngClass]="selectedLayout === LayoutVariant.GRID && 'filters__layoutTabBtn_active'"
        (click)="setSelectedLayout(LayoutVariant.GRID)"
      >
        <img src="assets/icons/grid.svg" alt="grid view" />
      </button>
      <button
        class="filters__layoutTabBtn"
        [ngClass]="selectedLayout === LayoutVariant.ROW && 'filters__layoutTabBtn_active'"
        (click)="setSelectedLayout(LayoutVariant.ROW)"
      >
        <img src="assets/icons/list.svg" alt="list view" />
      </button>
    </div>
  </div>

  <!-- <mat-chip-list role="list" class="filters__chips">
    <mat-chip *ngFor="let item of appliedFilters" disableRipple>
      {{ item.content }}
      <button
        matChipRemove
        [attr.aria-label]="item + '-remove'"
        (click)="removeFilterFromChips(item)"
      >
        &#9587;
      </button>
    </mat-chip>
  </mat-chip-list> -->
</form>

<jw-modal id="filtersModal">
  <form class="infoModal filtersModal" [formGroup]="filterForm" (ngSubmit)="onFilterSubmit()">
    <div class="infoModal__header">
      <h3 class="title title_sub">{{ "FILTERS.TITLE" | translate }}</h3>
      <button type="button" (click)="closeFiltersModal()">&#10006;</button>
    </div>

    <label *ngIf="filterForm.controls.statuses" class="filtersModal__fieldWrapper">
      <span>{{ "FILTERS.PROPERTY_CROWDFUNDING_STATUS" | translate }}</span>
      <mat-chip-list multiple>
        <mat-chip
          *ngFor="let status of fundingStatuses; index as i"
          (click)="handleChipClick(status.id, 'statuses')"
          disableRipple
          [selected]="getChipIsSelected(status.id, 'statuses')"
        >
          {{ status.text }}
        </mat-chip>
      </mat-chip-list>
    </label>
    <label *ngIf="transactionTypes" class="filtersModal__fieldWrapper">
      <span>Transaction type</span>
      <mat-chip-list multiple>
        <mat-chip
          *ngFor="let el of transactionTypes; index as i"
          (click)="handleChipClick(el.id, 'types')"
          disableRipple
          [selected]="getChipIsSelected(el.id, 'types')"
        >
          {{ el.text }}
        </mat-chip>
      </mat-chip-list>
    </label>
    <label *ngIf="transactionStatuses" class="filtersModal__fieldWrapper">
      <span>Transaction status</span>
      <mat-chip-list multiple>
        <mat-chip
          *ngFor="let el of transactionStatuses; index as i"
          (click)="handleChipClick(el.id, 'statuses')"
          disableRipple
          [selected]="getChipIsSelected(el.id, 'statuses')"
        >
          {{ el.text }}
        </mat-chip>
      </mat-chip-list>
    </label>

    <ng-container *ngFor="let slider of rangeFilters">
      <app-input-slider
        *ngIf="slider.type === 'number'"
        [caption]="slider.text"
        [minValue]="slider.from"
        [maxValue]="slider.to"
        [formControlName]="slider.name"
        [ticker]="slider.ticker"
      ></app-input-slider>

      <label *ngIf="slider.type === 'date'" class="dateRange">
        <span>{{ slider.text }}</span>
        <mat-date-range-input [rangePicker]="rangeDate" [formGroupName]="slider.name">
          <input
            matStartDate
            [placeholder]="'FILTERS.START_DATE' | translate"
            formControlName="from"
            appDateMask
          />
          <input
            matEndDate
            [placeholder]="'FILTERS.END_DATE' | translate"
            formControlName="to"
            appDateMask
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangeDate"></mat-datepicker-toggle>
        <mat-date-range-picker #rangeDate></mat-date-range-picker>
      </label>
    </ng-container>

    <div class="infoModal__footer">
      <button type="button" class="button button_outline" (click)="onFilterReset()">
        {{ "FILTERS.RESET" | translate }}
      </button>
      <button type="submit" class="button" [disabled]="filterForm.invalid">
        {{ "FILTERS.APPLY" | translate }}
      </button>
    </div>
  </form>
</jw-modal>
