import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { ISharedHolder, ISnapshot } from "src/app/models/dividends";
import { SC_TRANSACTION_STATUS } from "src/app/models/property";
import { DividendsService } from "src/app/services/dividends.service";
import { ToastService } from "src/app/services/toast.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  templateUrl: "./dividends-page.component.html",
  styleUrls: ["./dividends-page.component.scss"],
})
export class DividendsPageComponent implements OnInit {
  TOKEN = TOKEN;
  tokenPice: number;

  dividendId: string;
  data: ISnapshot;
  dividends = new MatTableDataSource<ISharedHolder>();
  dividendsColumns = ["investor", "amount", "total", "status"];
  payoutPressed = false;

  constructor(
    private _location: Location,
    private _dividendsService: DividendsService,
    private _activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _walletService: WalletService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.dividendId = params.id;
    });

    this._walletService.appTokenPrice$.subscribe(price => {
      this.tokenPice = price;
    });
  }

  ngOnInit(): void {
    this.requestDividendData(this.dividendId);
  }

  async requestDividendData(snapshotId: string) {
    const res = await this._dividendsService.getPayoutById(snapshotId);
    this.data = res;
    this.dividends.data = res.shared;
  }

  goBack() {
    this._location.back();
  }

  renderDividendStatus(status: SC_TRANSACTION_STATUS) {
    if (status === undefined || status === null) {
      return "Not paid";
    }

    switch (status) {
      case SC_TRANSACTION_STATUS.PENDING:
        return "Pending";
      case SC_TRANSACTION_STATUS.COMPLETED:
        return "Paid";
      case SC_TRANSACTION_STATUS.FAILED:
        return "Failed";
      case SC_TRANSACTION_STATUS.CANCELLED:
        return "Cancelled";
      case SC_TRANSACTION_STATUS.NOT_STARTED:
        return "In queue";
    }
  }

  checkCanPay() {
    const canPayout = this.data.shared.some(holder => {
      if (!holder.transaction || holder.transaction?.status !== SC_TRANSACTION_STATUS.COMPLETED) {
        return true;
      } else {
        return false;
      }
    });
    return canPayout;
  }

  async doPayout() {
    this.payoutPressed = true;
    try {
      await this._dividendsService.paySnapshot(this.dividendId);
      this._toastService.open("Payout successful", "success");
      this.requestDividendData(this.dividendId);
    } catch (error) {
      this.payoutPressed = false;
      throw error;
    }
  }

  calculateEuroAmount(userTokensAmount: number) {
    const allTokens = this.data.shared.reduce((acc, curr) => acc + curr.tokensAmount, 0);
    const userPercent = userTokensAmount / allTokens;
    return this.data.euroAmount * userPercent;
  }
}
