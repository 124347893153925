import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, filter, first, take, takeLast } from "rxjs";
import { IProperty } from "src/app/models/property";
import { FavoritesService } from "src/app/services/favorites.service";

@Component({
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.scss"],
})
export class FavoritesComponent implements OnInit {
  favorites: IProperty[] = [];
  subscription: Subscription;
  page = 0;
  hasMorePages = false;
  isLoading = true;

  constructor(private _favoritesService: FavoritesService, private _router: Router) {}

  async ngOnInit() {
    await this.requestProperties(this.page);
    this.isLoading = false;

    // When removing item from favorites
    this._favoritesService.favoriteList$.subscribe(ids => {
      this.favorites = this.favorites.filter(p => ids.includes(p.id));
    });
  }

  async requestProperties(page: number) {
    const res = await this._favoritesService.getFavorites(page);
    this.favorites.push(...res.items);
    this.hasMorePages = page + 1 < res.pagination.totalPages;
  }

  openPropertyPage(id: string) {
    this._router.navigateByUrl(`/dashboard/properties/${id}`);
  }

  loadMore() {
    this.page++;
    this.requestProperties(this.page);
  }
}
