<section class="mon">
  <div class="mon__header">
    <h2 class="title">Monetization</h2>
  </div>

  <app-tabs
    [tabs]="tabs"
    [currentTabNumber]="currentTabNumber"
    (tabSelected)="onTabChange($event)"
    [withQuery]="true"
  ></app-tabs>

  <ng-container [ngSwitch]="currentTabNumber">
    <ng-template ngSwitchDefault>
      <app-payouts-table></app-payouts-table>
    </ng-template>

    <ng-template ngSwitchCase="1">
      <app-monetization-table></app-monetization-table>
    </ng-template>

    <ng-template ngSwitchCase="2">
      <app-payout-history-table></app-payout-history-table>
    </ng-template>
  </ng-container>
</section>
