import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { ACCOUNT_ROLE } from "../models/auth";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      !this._authService.isUserLogged ||
      this._authService.accountData?.role !== ACCOUNT_ROLE.ADMIN
    ) {
      this._router.navigateByUrl("/admin/login");
      return false;
    }

    return true;
  }
}
