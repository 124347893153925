import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { IAuthData, IEmailChangeData } from "src/app/models/auth";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss", "../login/login.component.scss"],
})
export class PasswordComponent implements OnInit {
  invalidCode = false;
  resendTimer: number;
  userEmail: string = "";
  code = "";
  oldPassword: string;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userEmail = sessionStorage.getItem("email");
    if (this.userEmail == null) {
      this._router.navigateByUrl("/login");
    }
    this.oldPassword = JSON.parse(sessionStorage.getItem("oldPassword"));
  }

  async sendVerificationCode() {
    if (this.code.length !== 6) {
      this.invalidCode = true;
      return;
    }

    try {
      if (this.oldPassword) {
        await this.performChangeEmail();
        this._router.navigateByUrl("dashboard/settings");
        sessionStorage.setItem("emailWasChanged", "true");
      } else {
        await this.performLogin();
        this._router.navigateByUrl("dashboard/properties");
      }
    } catch (error) {
      this.invalidCode = true;
      return;
    }
  }

  async performLogin() {
    const dataToSend: IAuthData = {
      email: this.userEmail,
      password: this.code,
    };
    await this._authService.signIn(dataToSend);
    sessionStorage.removeItem("email");
  }

  async performChangeEmail() {
    const dataToSend: IEmailChangeData = {
      email: this.userEmail,
      password: this.code,
      oldPassword: this.oldPassword,
    };
    await this._authService.changeEmail(dataToSend);
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("oldPassword");
    this._toastService.open("Email successfully changed", "success");
  }

  async resendCode() {
    await this.sendOtp();
  }

  async sendOtp() {
    await this._authService.sendOtpToEmail(this.userEmail);
  }
}
