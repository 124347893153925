import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { withdrawalCommissionPercent } from "src/app/models/wallet";
import { SumsubService, SUMSUB_STATUS } from "src/app/services/sumsub.service";

@Component({
  templateUrl: "./withdrawal.component.html",
  styleUrls: ["./withdrawal.component.scss"],
})
export class WithdrawalComponent implements OnInit {
  step: 0 | 1 | 2 | 3 = 0;
  commissionPercent = withdrawalCommissionPercent;

  withdrawalForm: FormGroup;
  bankForm: FormGroup;
  confirmationCode: number = null;

  constructor(
    private sumsubService: SumsubService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    this.withdrawalForm = this.formBuilder.group({
      amount: [undefined, [Validators.required, Validators.min(20)]],
      isTermsAccepted: [false, Validators.requiredTrue],
    });

    this.bankForm = this.formBuilder.group({
      iban: [undefined, Validators.required],
      bic: [undefined, Validators.required],
      recipientName: [undefined, Validators.required],
      isAccountConfirmation: [false, Validators.requiredTrue],
    });
  }

  async ngOnInit() {
    // Check verification status on open page, maybe create a guard?
    const sumsubStatus = await this.sumsubService.getVerificationStatus();
    if (sumsubStatus.verificationStatus != SUMSUB_STATUS.COMPLETE) {
      this.location.back();
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  renderReceiveAmount() {
    const amount = this.withdrawalForm.value.amount;
    const valueWithPercent = amount - (this.commissionPercent / 100) * amount;
    return valueWithPercent;
  }

  getAmountError() {
    const control = this.withdrawalForm.controls.amount;

    if (control.touched === false) {
      return null;
    }

    if (control.errors?.required) {
      return "Amount required";
    }
    if (control.errors?.min) {
      return "Below minimum";
    }

    return null;
  }

  requestCode() {
    // TODO request withdrawal confirmation code from server
  }

  confirmRequest() {
    this.nextStep();
  }
}
