import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import {
  IP2pMyRequest,
  IP2pTransaction,
  P2P_REQUEST_STATUS,
  P2P_REQUEST_TYPE_ENUM,
} from "src/app/models/p2p";
import { TOKEN } from "src/app/constants/token-name.constant";
import { ModalService } from "../_modal";
import { P2pService } from "src/app/services/p2p.service";
import { Sort } from "@angular/material/sort";
import { ORDER_TYPE } from "src/app/models/filters";
import { PageEvent } from "@angular/material/paginator";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-p2p-orders",
  templateUrl: "./p2p-orders.component.html",
  styleUrls: ["./p2p-orders.component.scss"],
})
export class P2pOrdersComponent implements OnInit {
  TOKEN = TOKEN;
  orderTabs = ["P2P.ACTIVE_ORDERS_TAB", "P2P.HISTORY_TAB"];
  selectedTab = 0;

  activeOrders = new MatTableDataSource<IP2pMyRequest>();
  activeOrdersPage = 0;
  activeOrdersCount = 0;
  activeOrdersColumns = [
    "order_id",
    "property_name",
    "type",
    "area",
    "price",
    "filled",
    "expiration",
  ];
  activeOrdersSorting: string[] = [];

  ordersHistory = new MatTableDataSource<IP2pMyRequest>();
  historyPage = 0;
  historyCount = 0;
  ordersHistoryColumns = ["order_id", "property_name", "type", "area", "price", "filled", "status"];
  historySorting: string[] = [];

  selectedOrder: IP2pMyRequest;
  selectedOrderTransactions = new MatTableDataSource<IP2pTransaction>();
  transactionColumns = ["date", "area", "amount", "hash"];

  deletionInProgress = false;

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
    private p2pService: P2pService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.getInitialData();

    this.p2pService.onOrderUpdate.subscribe(() => {
      this.getInitialData();
    });
  }

  async getInitialData() {
    const activeOrders = await this.getOrders(
      this.activeOrdersPage,
      "active",
      this.activeOrdersSorting
    );
    this.activeOrders.data = activeOrders.items;
    const historyOrders = await this.getOrders(this.historyPage, "history", this.historySorting);
    this.ordersHistory.data = historyOrders.items;
  }

  onTabChange(tabNumber: number) {
    this.selectedTab = tabNumber;
  }

  async onActiveTableSort(sort: Sort) {
    const { res, sortingArray } = await this.onSort(sort, "active");
    this.activeOrders.data = res.items;
    this.activeOrdersSorting = sortingArray;
  }

  async onHistoryTableSort(sort: Sort) {
    const { res, sortingArray } = await this.onSort(sort, "history");
    this.ordersHistory.data = res.items;
    this.historySorting = sortingArray;
  }

  async onActivePageChange(e: PageEvent) {
    this.activeOrdersPage = e.pageIndex;
    const data = await this.getOrders(this.activeOrdersPage, "active", this.activeOrdersSorting);
    this.activeOrders.data = data.items;
  }

  async onHistoryPageChange(e: PageEvent) {
    this.historyPage = e.pageIndex;
    const data = await this.getOrders(this.historyPage, "history", this.historySorting);
    this.ordersHistory.data = data.items;
  }

  renderOrderType(type: P2P_REQUEST_TYPE_ENUM) {
    switch (type) {
      case P2P_REQUEST_TYPE_ENUM.BUY:
        return this.translate.instant("P2P.BUY");
      case P2P_REQUEST_TYPE_ENUM.SELL:
        return this.translate.instant("P2P.SELL");
    }
  }

  renderOrderStatus(status: P2P_REQUEST_STATUS) {
    switch (status) {
      case P2P_REQUEST_STATUS.ACTIVE:
        return this.translate.instant("P2P.STATUS_ACTIVE");
      case P2P_REQUEST_STATUS.CANCELLED:
        return this.translate.instant("P2P.STATUS_CANCELLED");
      case P2P_REQUEST_STATUS.COMPLETED:
        return this.translate.instant("P2P.STATUS_COMPLETED");
      case P2P_REQUEST_STATUS.EXPIRED:
        return this.translate.instant("P2P.STATUS_EXPIRED");
    }
  }

  async openOrderPopup(order: IP2pMyRequest) {
    this.selectedOrder = order;
    this.modalService.open("orderPopup");

    this.selectedOrderTransactions.data = await this.p2pService.getOrderTransactionsList(
      this.selectedOrder.request.id
    );
  }
  closeOrderPopup() {
    this.modalService.close("orderPopup");
  }

  openDeletePopup() {
    this.closeOrderPopup();
    this.modalService.open("deleteOrderModal");
  }
  closeDeletePopup() {
    this.modalService.close("deleteOrderModal");
    this.modalService.open("orderPopup");
  }

  openDeletionSuccessPopup() {
    this.modalService.open("deleteSuccessModal");
  }
  closeDeletionSuccessPopup() {
    this.modalService.close("deleteSuccessModal");
  }

  async handleDeleteOrder() {
    this.deletionInProgress = true;
    try {
      await this.p2pService.deleteOrder(this.selectedOrder.request.id);
      this.toastService.open("Order deleted!", "success");
      this.modalService.close("deleteOrderModal");
      this.openDeletionSuccessPopup();
    } catch (error) {
      throw error;
    } finally {
      this.deletionInProgress = false;
    }
  }

  private async getOrders(page: number, type: "active" | "history", sorting: string[]) {
    const res = await this.p2pService.getMyOrders(page, type, sorting);
    const count = res.pagination.perPage * res.pagination.totalPages;

    if (type === "active") {
      this.activeOrdersCount = count;
    } else if (type === "history") {
      this.historyCount = count;
    }

    return res;
  }

  private async onSort(sort: Sort, type: "active" | "history") {
    const dir =
      sort.direction === "asc"
        ? ORDER_TYPE.ASC
        : sort.direction === "desc"
        ? ORDER_TYPE.DESC
        : null;

    const sortingArray = dir ? [`order[field]=${sort.active}`, `order[direction]=${dir}`] : [];

    if (type === "active") {
      this.activeOrdersPage = 0;
    } else if (type === "history") {
      this.historyPage = 0;
    }

    const res = await this.getOrders(0, type, sortingArray);
    return { res, sortingArray };
  }
}
