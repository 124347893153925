<div class="monFilters">
  <app-input
    type="search"
    placeholder="Search by name or ticker"
    icon="search"
    [(ngModel)]="searchField"
    (onSearchClick)="applyMonetizationFilters()"
    (keyup.enter)="applyMonetizationFilters()"
  ></app-input>

  <app-switch
    caption="Monetized"
    [(ngModel)]="monetizationIsActiveFilter"
    (onSwitch)="applyMonetizationFilters()"
  ></app-switch>
  <a class="button" routerLink="/admin/monetization/create"> Create monetization for an object </a>
</div>

<div class="monTableWrapper">
  <table
    mat-table
    [dataSource]="monetization"
    matSort
    class="matTable"
    (matSortChange)="onMonetizationSort($event)"
  >
    <ng-container [matColumnDef]="monetizationColumns[0]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
      <td mat-cell *matCellDef="let prop">
        {{ prop.property.tokenTicker }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="monetizationColumns[1]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Object</th>
      <td mat-cell *matCellDef="let prop">{{ prop.property.name }}</td>
    </ng-container>
    <ng-container [matColumnDef]="monetizationColumns[2]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actual date</th>
      <td mat-cell *matCellDef="let prop">{{ prop.startsAt | prettifyDate }}</td>
    </ng-container>
    <ng-container [matColumnDef]="monetizationColumns[3]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Planned date</th>
      <td mat-cell *matCellDef="let prop">{{ prop.plannedStartsAt | prettifyDate }}</td>
    </ng-container>
    <ng-container [matColumnDef]="monetizationColumns[4]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Profit</th>
      <td mat-cell *matCellDef="let prop">
        {{ prop.euroAmount | prettifyNumber }} EUR{{
          prop.type === MonetizationType.PERIODIC ? "/" + periodToName(prop.period) : ""
        }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="monetizationColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: monetizationColumns"
      (click)="openMonetizationEditPage(row.id)"
    ></tr>
  </table>

  <mat-paginator
    [pageIndex]="monetizationPage"
    [pageSize]="monetizationPageSize"
    [length]="monetizationTotal"
    (page)="onMonetizationPageChange($event)"
    aria-label="Select page"
  ></mat-paginator>
</div>
