import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ILanguage, IUserSettings, languages, LANGUAGE_TAGS } from "src/app/models/settings";
import { SettingsService } from "src/app/services/settings.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  languages = languages;
  currentLanguage: ILanguage = this.languages.find(l => l.tag === LANGUAGE_TAGS.ENGLISH);
  userSettings: IUserSettings;

  @Output() languageChanged = new EventEmitter();

  constructor(public translate: TranslateService, private _settingsService: SettingsService) {
    this._settingsService.userSettings$.subscribe(value => {
      this.userSettings = value;
      if (this.userSettings) {
        this.changeLanguage(this.userSettings.language);
        this.currentLanguage = this.findLanguage(this.userSettings.language);
      }
    });
  }

  ngOnInit() {}

  findLanguage(lang: LANGUAGE_TAGS | string) {
    const foundLanguage = this.languages.find(
      (x: ILanguage) => x.tag === lang || x.language === lang
    );
    return foundLanguage;
  }

  getLanguages() {
    return this.languages.map(x => x.language);
  }

  setLanguage(language: string) {
    const chosenLanguage = this.findLanguage(language);
    this.currentLanguage = chosenLanguage;
    this.changeLanguage(chosenLanguage.tag);
  }

  private async changeLanguage(lang: LANGUAGE_TAGS) {
    this.translate.use(lang.toLowerCase());
    this.languageChanged.emit(lang);
  }
}
