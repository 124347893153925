import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ISnapshot } from "src/app/models/dividends";
import { ORDER_TYPE } from "src/app/models/filters";
import { DividendsService } from "src/app/services/dividends.service";

@Component({
  selector: "app-payouts-table",
  templateUrl: "./payouts-table.component.html",
  styleUrls: ["./payouts-table.component.scss"],
})
export class PayoutsTableComponent implements OnInit {
  searchField: string = "";

  payouts = new MatTableDataSource<ISnapshot>();
  payoutsColumns = ["ticker", "object", "investors", "dividends", "payment_date", "status"];
  payoutsPage = 0;
  payoutsPageSize = 10;
  payoutsTotal = 0;
  payoutsIsPaymentToday = false;
  payoutsSort: string[] = [
    `order[field]=${this.payoutsColumns[4]}`,
    `order[direction]=${ORDER_TYPE.DESC}`,
  ];

  constructor(private _dividendsService: DividendsService, private _router: Router) {}

  ngOnInit(): void {
    this.requestFuturePayouts();
  }

  async requestFuturePayouts() {
    const filters = this.createFilters();

    const res = await this._dividendsService.getFuturePayouts(
      this.payoutsPage,
      this.payoutsPageSize,
      filters
    );
    this.payouts.data = res.items;
    this.payoutsTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  applyPayoutsFilters() {
    this.payoutsPage = 0;
    this.requestFuturePayouts();
  }

  onPayoutsSort(sort: Sort) {
    this.payoutsSort = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];
    this.applyPayoutsFilters();
  }

  onPayoutsPageChange(e: PageEvent) {
    this.payoutsPage = e.pageIndex;
    this.requestFuturePayouts();
  }

  openPayPage(item: ISnapshot) {
    if (item.isExists) {
      this._router.navigateByUrl(`/admin/monetization/${item.id}/dividends-details`);
    }
  }

  renderStatus(item: ISnapshot) {
    if (item.isExists) {
      return "Payout available";
    } else {
      return "Future payment";
    }
  }

  private createFilters() {
    const filtersArray = [];

    if (this.searchField.trim()) {
      filtersArray.push(`search=${this.searchField}`);
    }

    filtersArray.push(`filter[isPaymentToday]=${this.payoutsIsPaymentToday}`);

    if (this.payoutsSort.length) {
      filtersArray.push(...this.payoutsSort);
    }

    return filtersArray;
  }
}
