import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ModalService } from "../components/_modal";
import {
  IAdminFundingTransaction,
  ICompleteTransactionBody,
  ICryptoCurrency,
  IInvoice,
  INewDeposit,
  ITransaction,
  IWalletActivity,
  IWalletBalance,
  TRANSACTION_STATUS,
} from "../models/wallet";
import { BaseRequestService } from "./base-request.service";
import { Utils } from "../utils/utils";
import { IResponseWithPagination } from "../models/api-response";

export enum DEPOSIT_STEP {
  CREATION,
  CONFIRMATION,
}

@Injectable({
  providedIn: "root",
})
export class WalletService {
  depositStep: DEPOSIT_STEP = DEPOSIT_STEP.CREATION;
  depositStepChange = new Subject<DEPOSIT_STEP>();
  currentTransactionId: string;
  private walletBalance = new BehaviorSubject<IWalletBalance>(null);
  walletBalance$ = this.walletBalance.asObservable();
  private appTokenPrice = new BehaviorSubject<number>(1);
  appTokenPrice$ = this.appTokenPrice.asObservable();

  onDepositCreated = new EventEmitter();

  constructor(private _api: BaseRequestService, private _modalService: ModalService) {
    this.depositStepChange.subscribe(value => {
      this.depositStep = value;
    });
  }

  openDepositModal(depositStep: DEPOSIT_STEP = DEPOSIT_STEP.CREATION, transactionId?: string) {
    this._modalService.open("deposit-modal");
    this.currentTransactionId = transactionId;
    this.depositStepChange.next(depositStep);
  }

  closeDepositModal() {
    this._modalService.close("deposit-modal");
  }

  async getWalletInfo() {
    const res = await this._api.get<IWalletBalance>("/wallet");
    this.walletBalance.next(res);
  }

  async getTransactionsList(page: number, perPage: number = 10, filters?: string[]) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    const response = await this._api.get<IResponseWithPagination<IWalletActivity>>(
      `/wallet/transactions?pagination[perPage]=${perPage}&pagination[page]=${page}${joinedFilters}`
    );
    return response;
  }

  getTransactionById(id: string) {
    return this._api.get<ITransaction>(`/wallet/transaction/${id}`);
  }

  async getDepositInvoice(amount: string) {
    return await this._api.get<IInvoice>(`/wallet/deposit/invoice?amount=${amount}`);
  }

  async depositRequest(newRequest: INewDeposit) {
    try {
      const response = await this._api.post<string>("/wallet/deposit", newRequest);
      this.onDepositCreated.emit();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async completeDepositRequest(transactionId: string, body: ICompleteTransactionBody) {
    try {
      const res = await this._api.put(`/wallet/deposit/${transactionId}/complete`, { ...body });
      this.onDepositCreated.emit();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getCryptoCurrencies() {
    const response = await this._api.get<ICryptoCurrency[]>("/crypto/currencies");
    return response;
  }

  async getAdminTransactionsList(page: number, filters?: string[]) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    const response = await this._api.get<IResponseWithPagination<IAdminFundingTransaction>>(
      `/wallet/management/transactions?pagination[page]=${page}&pagination[perPage]=10${joinedFilters}`
    );
    return response;
  }

  async adminAcceptTransaction(transactionId: string, amountAdrd: number) {
    return await this._api.post(`/wallet/management/${transactionId}/accept`, { amountAdrd });
  }

  async adminSetTransactionStatus(transactionId: string, status: TRANSACTION_STATUS) {
    return await this._api.post(`/wallet/management/${transactionId}/status`, { status });
  }

  async getAppTokenPrice() {
    try {
      const price = await this._api.get<number>("/crypto/currencies/adrd/price");
      this.appTokenPrice.next(price || 1);
    } catch (error) {
      throw error;
    }
  }
}
