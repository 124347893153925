<main class="myOrders">
  <app-main-header [headline]="'P2P.MY_ORDERS' | translate"></app-main-header>

  <a class="button" routerLink="/dashboard/p2p">{{ "P2P.BACK_LINK" | translate }}</a>

  <div class="myOrders__funds">
    <div class="myOrders__fundsItem">
      <p>{{ "P2P.FROZEN_FUNDS" | translate }}</p>
      <p>
        {{ frozenFunds | prettifyNumber }} <span>{{ TOKEN }}</span>
      </p>
    </div>
  </div>

  <app-p2p-orders></app-p2p-orders>
</main>
