import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { pairwise, startWith } from "rxjs";
import { IDetailedProperty, IOwnedPropertyAmount } from "src/app/models/property";
import { IWalletBalance } from "src/app/models/wallet";
import { FavoritesService } from "src/app/services/favorites.service";
import { PropertiesService } from "src/app/services/properties.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { ModalService } from "../_modal";
import { PrettifyNumberPipe } from "src/app/pipes/prettify-number.pipe";
import { P2pService } from "src/app/services/p2p.service";
import { IP2pOrder } from "src/app/models/p2p";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-buy-sell-form",
  templateUrl: "./buy-sell-form.component.html",
  styleUrls: ["./buy-sell-form.component.scss"],
})
export class BuySellFormComponent implements OnInit {
  TOKEN = TOKEN;

  @Input() usedIn: "property" | "p2p";
  @Input() type: "buy" | "sell";
  @Input() propertyData: IDetailedProperty;
  @Input() p2pOrderData: IP2pOrder;
  @Input() ownedAmount: IOwnedPropertyAmount;

  currentStep: 1 | 2 | 3 | 4 = 1;
  valueInTokens: number = 0;
  walletInfo: IWalletBalance;
  tokenName: string;
  minTokens: number;
  maxTokens: number;
  minSqm: number;
  maxSqm: number;
  isPending = false;
  tokenPrice: number;

  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    private _propertyService: PropertiesService,
    private _walletService: WalletService,
    private _translate: TranslateService,
    private _modalService: ModalService,
    private _favoritesService: FavoritesService,
    private _p2pService: P2pService,
    private _toastService: ToastService
  ) {}

  form = new FormGroup({
    tokens: new FormControl(undefined, [Validators.required]),
  });

  async ngOnInit() {
    this._walletService.walletBalance$.subscribe(balance => {
      this.walletInfo = balance;
    });

    this.tokenName = this.propertyData.token.ticker;

    let calculatedMaxTokens = 0;

    if (this.usedIn === "property") {
      this.minTokens = this.propertyData.investInfo.minInvestTokensAmount;
      calculatedMaxTokens = +this.propertyData.token.left.toFixed(0);
      this.tokenPrice = this.propertyData.tokenPrice;
    } else if (this.usedIn === "p2p") {
      this.minTokens = 1;
      calculatedMaxTokens = this.p2pOrderData.tokensAmount - this.p2pOrderData.tokensFullfilled;
      this.tokenPrice = this.p2pOrderData.tokenPrice;
    }

    if (
      this.type === "sell" &&
      this.ownedAmount &&
      this.ownedAmount.tokens <= calculatedMaxTokens
    ) {
      this.maxTokens = this.ownedAmount.tokens;
    } else {
      this.maxTokens = calculatedMaxTokens;
    }

    this.maxSqm = this.calculateMaxSqm();
    this.minSqm = this.calculateMinSqm();

    this.form.controls.tokens.addValidators([
      Validators.min(this.minTokens),
      Validators.max(this.maxTokens),
    ]);

    this.form.controls.tokens.valueChanges
      .pipe(startWith(null as number), pairwise())
      .subscribe(([prev, next]) => {
        this.valueInTokens = next * this.tokenPrice;
      });
  }

  cancelForm() {
    this.valueInTokens = 0;
    this.onCancel.emit();
  }

  checkFunds() {
    if (this.type === "sell") {
      this.nextStep();
      return;
    }

    const hasEnoughFunds = this.valueInTokens <= this.walletInfo.balanceRemain;

    if (!hasEnoughFunds) {
      this.openInsufficientFundsModal();
      if (this.usedIn === "property") {
        this._favoritesService.addPropertyToFav(this.propertyData.id);
      }
      return;
    }

    this.nextStep();
  }

  async handleTransaction() {
    try {
      this.isPending = true;

      if (this.usedIn === "property") {
        await this._propertyService.buyPropertyPart(this.propertyData.id, this.form.value.tokens);
      } else if (this.usedIn === "p2p") {
        await this._p2pService.transferOrder(this.p2pOrderData.id, this.form.value.tokens);
      }

      this._toastService.open("Transaction successful!", "success");
      this.nextStep();
      this.onSubmit.emit(this.form.value.tokens);
      this._walletService.getWalletInfo();
    } catch (error) {
      throw error;
    } finally {
      this.isPending = false;
    }
  }

  renderTitle() {
    const titles = {
      buy: {
        1: "BUY_PROPERTY.FORM_TITLE_BUY",
        2: "BUY_PROPERTY.FORM_TITLE_CONFIRM",
        3: "BUY_PROPERTY.FORM_TITLE_PROCESS",
        4: "BUY_PROPERTY.FORM_TITLE_SUCCESS",
      },
      sell: {
        1: "SELL_PROPERTY.TITLE_ENTER",
        2: "SELL_PROPERTY.TITLE_CONFIRM",
        3: "SELL_PROPERTY.TITLE_PROCESS",
        4: "SELL_PROPERTY.TITLE_SOLD",
      },
    };

    return this._translate.instant(titles[this.type][this.currentStep]);
  }

  nextStep() {
    this.currentStep++;

    // TEMP because we cannot track the transaction status
    if (this.currentStep === 3) {
      setTimeout(() => {
        this.nextStep();
      }, 1000);
    }
  }

  prevStep() {
    this.currentStep--;
  }

  openInsufficientFundsModal() {
    this._modalService.open("insuf-funds-modal");
  }

  closeInsufficientFundsModal() {
    this._modalService.close("insuf-funds-modal");
  }

  renderInsufficientAmount() {
    return new PrettifyNumberPipe().transform(
      this.walletInfo?.balanceRemain
        ? this.valueInTokens - this.walletInfo.balanceRemain
        : this.valueInTokens
    );
  }

  renderSqmFromTokens() {
    return this.form.value.tokens * this.propertyData.token.squarePerToken;
  }

  openDepositModal() {
    this.closeInsufficientFundsModal();
    this._walletService.openDepositModal();
  }

  renderTokensError() {
    const tokensControl = this.form.controls.tokens;

    if (tokensControl.touched) {
      if (tokensControl.errors?.required) {
        return this._translate.instant("BUY_PROPERTY.ERROR_REQUIRED");
      }
      if (tokensControl.errors?.max) {
        return this._translate.instant("BUY_PROPERTY.ERROR_MAX");
      }
      if (tokensControl.errors?.min) {
        return this._translate.instant("BUY_PROPERTY.ERROR_MIN");
      }
    }
    return "";
  }

  private calculateMaxSqm(): number {
    const maxSqm = this.maxTokens * this.propertyData.token.squarePerToken;
    return parseFloat(maxSqm.toFixed(2));
  }

  private calculateMinSqm(): number {
    const minSqm = this.minTokens * this.propertyData.token.squarePerToken;
    return parseFloat(minSqm.toFixed(2));
  }
}
