<app-header></app-header>

<section class="login dialogue">
  <h2>{{ "LOGIN.BLOCKED_TITLE" | translate }}</h2>
  <h4>
    {{ "LOGIN.BLOCKED_TEXT" | translate }}
    <a href="mailto:support@website.com">support@website.com</a>.
  </h4>

  <button class="button" (click)="logoutAndBackToLogin()">
    {{ "LOGIN.BACK_TO_LOGIN" | translate }}
  </button>
</section>

<app-footer></app-footer>
