<app-main-header [headline]="propertyData?.name"></app-main-header>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-container *ngIf="propertyData; else loader">
  <section class="property">
    <div class="property-wrapper">
      <div class="propertySlider">
        <swiper [slidesPerView]="1" [navigation]="true" class="propertySlider__carousel">
          <ng-template swiperSlide *ngFor="let img of propertyData.images">
            <img swiperSlide [src]="baseUrl + img" [alt]="img" />
          </ng-template>
        </swiper>
        <div class="propertySlider__info">
          <div class="propertySlider__description">
            <img src="assets/icons/geotag.svg" alt="geotag" />
            <p>
              {{ displayAddress() }}
            </p>
          </div>
        </div>
      </div>
      <div class="property-bottom">
        <div class="property__params-wrapper">
          <div class="property__param">
            <span>{{ "PROPERTY.AREA" | translate }}</span>
            <span>{{ propertyData.housingInfo.totalArea + " m²" }}</span>
          </div>
          <div class="property__param">
            <span>{{ "PROPERTY.LIVING_SPACE" | translate }}</span>
            <span>{{ propertyData.housingInfo.livingSpace + " m²" }}</span>
          </div>
          <div class="property__param">
            <span>{{ "PROPERTY.ROOMS" | translate }}</span>
            <span>{{ propertyData.housingInfo.rooms }}</span>
          </div>
          <div class="property__param">
            <span>{{ "PROPERTY.OBJECT_TYPE" | translate }}</span>
            <span>{{ getObjectTypeName(propertyData.housingInfo.objectType) }}</span>
          </div>
          <div class="property__param">
            <span>{{ "PROPERTY.RENTAL_TYPE" | translate }}</span>
            <span>{{ getRentTypeName(propertyData.housingInfo.rentType) }}</span>
          </div>
        </div>
        <p class="property__description">{{ propertyData.shortDescription }}</p>
        <div class="property__info-wrapper">
          <div class="property__investors-wrapper">
            <span>{{ "PROPERTY.INVESTORS" | translate }}</span>
            <h3>{{ propertyData.totalInvestors }}</h3>
          </div>
          <p>{{ propertyData.fullDescription }}</p>
        </div>
      </div>
    </div>

    <div class="property-price-wrapper">
      <div class="property-price-top">
        <div class="property__price-info">
          <span>{{ "PROPERTY.PRICE" | translate }}</span>
          <img matTooltip="Test" src="assets/icons/info.svg" alt="info" />
        </div>

        <h2 class="property__price">
          {{ (propertyData.investInfo.totalPrice | prettifyNumber) + " " + TOKEN }}
        </h2>
        <div class="property__progress-wrapper">
          <app-progress-bar [percentage]="percentage"></app-progress-bar>
          <span>{{ percentage + "%" }}</span>
        </div>

        <div class="property__collected">{{ "PROPERTY.COLLECTED" | translate }}</div>
        <div class="property__collected_perc">
          {{
            (propertyData.investInfo.collected | prettifyNumber) +
              " " +
              TOKEN +
              " (" +
              percentage +
              "%)"
          }}
        </div>

        <div class="property__income-wrapper">
          <div class="property__income">
            <span>{{ "PROPERTY.ANNUAL_INCOME" | translate }}</span>
            <h3>{{ propertyData.investInfo.estimatedAnnualIncome + "%" }}</h3>
          </div>

          <div class="property__income" *ngIf="isUserInvested; else startFrom">
            <span>{{ "PROPERTY.TIME_TILL_DIVIDENDS" | translate }}</span>
            <h3>{{ propertyData.dividend.daysToNextPayment ?? "-" }}</h3>
          </div>
          <ng-template #startFrom>
            <div class="property__income" *ngIf="isCollecting">
              <span>{{ "PROPERTY.START_FROM" | translate }}</span>
              <h3>
                {{
                  propertyData.investInfo.minInvestTokensAmount * propertyData.tokenPrice
                    | prettifyNumber
                }}
                {{ TOKEN }}
              </h3>
            </div>
            <div class="property__income" *ngIf="isFutureFundraising">
              <span>{{ "PROPERTY.FUTURE_START" | translate }}</span>
              <h3>{{ propertyData.fundraisingStartDate | prettifyDate }}</h3>
            </div>
          </ng-template>
        </div>

        <div
          class="property__buttons-wrapper"
          *ngIf="isCollecting || isUserInvested || isP2PAvailable"
        >
          <a class="button" routerLink="buy" *ngIf="isCollecting">
            {{ isUserInvested ? ("PROPERTY.BUY_MORE" | translate) : ("PROPERTY.BUY" | translate) }}
          </a>
          <a *ngIf="!isCollecting && isP2PAvailable" class="button" routerLink="/dashboard/p2p">
            {{ "PROPERTY.BUY_P2P" | translate }}
          </a>
          <a
            *ngIf="isUserInvested && isP2PAvailable"
            class="button button_outline"
            routerLink="/dashboard/p2p"
            [queryParams]="{ currentTab: 1 }"
          >
            {{ "PROPERTY.SELL_P2P" | translate }}
          </a>
        </div>
      </div>

      <div class="property-price-bot">
        <div class="property__bot-wrapper">
          <h5>{{ "PROPERTY.TOKEN_INFO" | translate }}</h5>
          <p>
            {{ "PROPERTY.TICKER" | translate }} <span>{{ propertyData.token.ticker }}</span>
          </p>
          <p *ngIf="isCollecting">
            {{ "PROPERTY.TOKEN_PRICE" | translate }}
            <span>{{ propertyData.tokenPrice }} {{ TOKEN }}</span>
          </p>
          <p>
            {{ "PROPERTY.TOTAL_SUPPLY" | translate }}
            <span
              >{{ propertyData.token.amount | prettifyNumber }}
              {{ propertyData.token.ticker }}</span
            >
          </p>
          <p *ngIf="isCollecting || isP2PAvailable">
            {{ "PROPERTY.AVAILABLE_TOKENS" | translate }}
            <span
              >{{ propertyData.token.left | prettifyNumber }} {{ propertyData.token.ticker }}</span
            >
          </p>
          <p *ngIf="propertyData.owned">
            {{ "PROPERTY.OWNED_TOKENS" | translate }}
            <span>
              {{ areaToTokens(propertyData.owned.quantity) | prettifyNumber }}
              {{
                propertyData.owned.frozenQuantity
                  ? "(" +
                    (areaToTokens(propertyData.owned.frozenQuantity) | prettifyNumber) +
                    " hold)"
                  : ""
              }}
              {{ propertyData.token.ticker }}
            </span>
          </p>
          <p *ngIf="isCollecting">
            {{ "PROPERTY.MIN_INVESTMENT" | translate }}
            <span>
              {{ propertyData.investInfo.minInvestTokensAmount }} {{ propertyData.token.ticker }} /
              {{
                propertyData.investInfo.minInvestTokensAmount * propertyData.token.squarePerToken
              }}
              m²
            </span>
          </p>
        </div>

        <div class="property__bot-wrapper" *ngIf="isUserInvested || isCollecting || isP2PAvailable">
          <div *ngIf="isUserInvested; else beforeInvest">
            <h5>{{ "PROPERTY.PROFIT" | translate }}</h5>
            <div class="property__income-wrapper">
              <div class="property__income property__income_moreGap">
                <span>{{ "PROPERTY.INVESTED" | translate }}</span>
                <h3>{{ renderInvestedAmount() | prettifyNumber }}</h3>
                <span>{{ TOKEN }}</span>
              </div>
              <div class="property__income property__income_moreGap">
                <span>{{ "PROPERTY.DIVIDENDS" | translate }}</span>
                <h3>{{ propertyData.dividend.income | prettifyNumber }}</h3>
                <span>{{ TOKEN }}</span>
              </div>
            </div>
            <p>
              {{ "PROPERTY.PURCHASED" | translate }}
              {{ propertyData.investInfo.firstPurchaseDate | prettifyDate }}
            </p>
          </div>

          <ng-template #beforeInvest>
            <div *ngIf="isCollecting || isP2PAvailable">
              <h5>{{ "PROPERTY.AFTER_INVESTMENT" | translate }}</h5>
              <p>
                {{ "PROPERTY.AFTER_TEXT" | translate }}
              </p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>

  <app-property-activity-table [propertyId]="id"></app-property-activity-table>
</ng-container>
