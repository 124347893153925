<section class="orderWrapper">
  <div class="box order">
    <ng-container *ngIf="currentStep === OrderSteps.SELECTION">
      <div class="order__header">
        <h3 class="title title_sub">{{ "P2P.SELECT_PROPERTY" | translate }}</h3>
        <app-tabs
          [tabs]="returnOrderTypeTabs()"
          [currentTabNumber]="selectedOrderTab"
          (tabSelected)="switchOrderType($event)"
          variant="mini"
        ></app-tabs>
      </div>

      <ng-container *ngIf="orderTypeTabs[selectedOrderTab].type === OrderType.SELL">
        <div class="order__listing" *ngIf="myProperties?.length > 0; else noSellProp">
          <article
            *ngFor="let item of myProperties"
            class="propertyItem"
            [ngClass]="{ propertyItem_selected: selectedPropertyToSell === item }"
            (click)="setPropertyToSell(item)"
          >
            <img [src]="baseUrl + item.images[0]" [alt]="item.name" />
            <div class="propertyItem__descr">
              <h4>{{ item.property.name }}</h4>
              <p>
                {{ item.owned.quantity / item.token.squarePerToken | prettifyNumber }}
                {{ item.token.ticker }}
                {{ "P2P.AVAILABLE_TO_SELL" | translate }}
              </p>
            </div>
          </article>
        </div>
        <ng-template #noSellProp>
          <div>{{ "P2P.NO_PROPERTIES_TO_CREATE_AN_ORDER" | translate }}</div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="orderTypeTabs[selectedOrderTab].type === OrderType.BUY">
        <div *ngIf="propertiesToBuy?.length > 0; else noBuyProp" class="order__listing">
          <article
            *ngFor="let item of propertiesToBuy"
            class="propertyItem"
            [ngClass]="{ propertyItem_selected: selectedPropertyToBuy === item }"
            (click)="setPropertyToBuy(item)"
          >
            <img [src]="baseUrl + item.images[0]" [alt]="item.name" />
            <div class="propertyItem__descr">
              <h4>{{ item.property.name }}</h4>
              <p>
                {{ "PROPERTY_CARD.ESTIMATED_INCOME" | translate }}
                {{ item.investInfo.profitability }}%
              </p>
            </div>
          </article>
        </div>
        <ng-template #noBuyProp>
          <div>{{ "P2P.NOTHING_IN_PROPERTIES" | translate }}</div>
        </ng-template>
      </ng-container>

      <button
        class="button"
        [disabled]="!getIsCanStartCreation()"
        (click)="startCreation()"
        [class.button_success]="orderTypeTabs[selectedOrderTab].type === OrderType.BUY"
        [class.button_error]="orderTypeTabs[selectedOrderTab].type === OrderType.SELL"
      >
        {{
          orderTypeTabs[selectedOrderTab].type === OrderType.BUY
            ? ("P2P.WANT_TO_BUY" | translate)
            : ""
        }}
        {{
          orderTypeTabs[selectedOrderTab].type === OrderType.SELL
            ? ("P2P.WANT_TO_SELL" | translate)
            : ""
        }}
      </button>
    </ng-container>

    <form
      *ngIf="currentStep === OrderSteps.INPUT"
      class="order__form"
      [formGroup]="orderForm"
      (ngSubmit)="prepareOrder()"
    >
      <h3 class="title title_sub">
        {{ orderFor === OrderType.SELL ? ("P2P.ENTER_AMOUNT_TO_SELL" | translate) : "" }}
        {{ orderFor === OrderType.BUY ? ("P2P.ENTER_AMOUNT_TO_BUY" | translate) : "" }}
      </h3>
      <app-tokens-area-input
        *ngIf="orderFor === OrderType.SELL"
        formControlName="tokensAmount"
        minTokens="1"
        [maxTokens]="getMaxTokensToSell()"
        [tokenName]="selectedPropertyToSell.token.ticker"
        [minSqm]="selectedPropertyToSell.token.squarePerToken"
        [maxSqm]="selectedPropertyToSell.owned.quantity"
        [errorTokens]="renderTokensError()"
      ></app-tokens-area-input>
      <app-tokens-area-input
        *ngIf="orderFor === OrderType.BUY"
        formControlName="tokensAmount"
        minTokens="1"
        [maxTokens]="getMaxTokensToBuy()"
        [tokenName]="selectedPropertyToBuy.token.ticker"
        [minSqm]="selectedPropertyToBuy.token.squarePerToken"
        [maxSqm]="selectedPropertyToBuy.property.totalSpace"
        [errorTokens]="renderTokensError()"
      ></app-tokens-area-input>
      <div class="order__row">
        <app-input
          type="number"
          [caption]="'P2P.TOKEN_PRICE' | translate : { token: TOKEN }"
          formControlName="tokenPrice"
          appIntegerNumber
        ></app-input>
        <app-input
          type="datetime"
          [caption]="'P2P.ORDER_EXPIRATION' | translate"
          [tooltip]="'P2P.EXPIRATION_TOOLTIP' | translate"
          formControlName="endsAt"
        ></app-input>
      </div>
      <p class="order__textInfo">
        {{ "P2P.TOTAL_ORDER_AMOUNT" | translate }}
        <span>{{ calculateOrderAmount() }} {{ TOKEN }}</span>
      </p>
      <div class="order__row">
        <button type="button" class="button button_outline" (click)="backToListing()">
          {{ "P2P.BACK" | translate }}
        </button>
        <button type="submit" class="button" [disabled]="orderForm.invalid">
          {{ "P2P.PROCEED" | translate }}
        </button>
      </div>
    </form>

    <ng-container
      *ngIf="currentStep === OrderSteps.CONFIRMING || currentStep === OrderSteps.SUCCESS"
    >
      <h3 *ngIf="currentStep === OrderSteps.CONFIRMING" class="title title_sub">
        {{ orderFor === OrderType.SELL ? ("P2P.CONFIRM_TO_SELL" | translate) : "" }}
        {{ orderFor === OrderType.BUY ? ("P2P.CONFIRM_TO_BUY" | translate) : "" }}
      </h3>
      <h3 *ngIf="currentStep === OrderSteps.SUCCESS" class="title title_sub">
        {{ "P2P.ORDER_CREATED" | translate }}
      </h3>
      <div class="order__row">
        <p *ngIf="orderFor === OrderType.SELL" class="order__textInfo">
          {{ "P2P.YOU_SELL" | translate }}
          <span>
            {{ orderForm.value.tokensAmount }} {{ selectedPropertyToSell.token.ticker }} /
            {{ convertTokensToArea(orderForm.value.tokensAmount) }} m&#xB2;
          </span>
        </p>
        <p *ngIf="orderFor === OrderType.BUY" class="order__textInfo">
          {{ "P2P.YOU_BUY" | translate }}
          <span>
            {{ orderForm.value.tokensAmount }} {{ selectedPropertyToBuy.token.ticker }} /
            {{ convertTokensToArea(orderForm.value.tokensAmount) }} m&#xB2;
          </span>
        </p>
        <p class="order__textInfo">
          {{ "PROPERTY.TOKEN_PRICE" | translate }}
          <span>{{ orderForm.value.tokenPrice }} {{ TOKEN }}</span>
        </p>
      </div>
      <div class="order__row">
        <p class="order__textInfo">
          {{ "P2P.TOTAL_ORDER_AMOUNT" | translate }}
          <span>{{ calculateOrderAmount() }} {{ TOKEN }}</span>
        </p>
        <p class="order__textInfo">
          {{ "P2P.EXPIRATION" | translate }}
          <span>{{
            orderForm.value.endsAt ? (orderForm.value.endsAt | prettifyDate : "full") : "N/A"
          }}</span>
        </p>
      </div>
      <div *ngIf="currentStep === OrderSteps.CONFIRMING" class="order__row">
        <button type="button" class="button button_outline" (click)="prevStep()">
          {{ "P2P.BACK" | translate }}
        </button>
        <button type="submit" class="button" (click)="createOrder()">
          {{ "P2P.CONFIRM" | translate }}
        </button>
      </div>

      <button *ngIf="currentStep === OrderSteps.SUCCESS" class="button" (click)="onDoneClick()">
        {{ "P2P.DONE" | translate }}
      </button>
    </ng-container>
  </div>

  <app-property-card
    *ngIf="orderTypeTabs[selectedOrderTab].type === OrderType.SELL"
    [data]="selectedPropertyToSell"
    cardType="p2pCreateSell"
    variant="vertical"
  ></app-property-card>

  <app-property-card
    *ngIf="orderTypeTabs[selectedOrderTab].type === OrderType.BUY"
    [data]="selectedPropertyToBuy"
    cardType="p2pCreateBuy"
    variant="vertical"
  ></app-property-card>
</section>
