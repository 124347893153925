import { Component, OnInit } from "@angular/core";
import {
  ITransactionManagerStatus,
  TransactionManagerType,
  TRANSACTION_MANAGER_STATUS,
} from "src/app/models/transaction-manager";
import { TransactionsService } from "src/app/services/transactions.service";

@Component({
  templateUrl: "./tm.component.html",
  styleUrls: ["./tm.component.scss"],
})
export class TmComponent implements OnInit {
  adminStatus: ITransactionManagerStatus;
  clientStatus: ITransactionManagerStatus;

  constructor(private _transactionsService: TransactionsService) {
    this._transactionsService.adminTmStatus$.subscribe(data => {
      this.adminStatus = data;
    });
    this._transactionsService.clientTmStatus$.subscribe(data => {
      this.clientStatus = data;
    });
  }

  ngOnInit() {}

  async handleAdminTm(perform: "activate" | "stop") {
    await this.setTransactionManagerState("admin", perform);
  }

  async handleClientTm(perform: "activate" | "stop") {
    await this.setTransactionManagerState("client", perform);
  }

  async setTransactionManagerState(type: TransactionManagerType, perform: "activate" | "stop") {
    const transactionService =
      perform === "activate"
        ? this._transactionsService.resumeTransactionManager
        : this._transactionsService.stopTransactionManager;

    await transactionService.call(this._transactionsService, type);
  }

  renderStatusText(obj: ITransactionManagerStatus) {
    switch (obj.status) {
      case TRANSACTION_MANAGER_STATUS.ACTIVE:
        return "Active";
      case TRANSACTION_MANAGER_STATUS.FAILED:
        return "Failed";
      case TRANSACTION_MANAGER_STATUS.STOPPED:
        return "Stopped";
    }
  }
}
