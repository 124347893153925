import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, interval } from "rxjs";
import { MONETIZATION_TYPE, PERIOD_VARIANTS } from "src/app/models/dividends";
import { IAdminPropertyItem } from "src/app/models/property";
import { PrettifyDatePipe } from "src/app/pipes/prettify-date.pipe";
import { PrettifyNumberPipe } from "src/app/pipes/prettify-number.pipe";
import { DividendsService } from "src/app/services/dividends.service";
import { PropertiesService } from "src/app/services/properties.service";
import { ToastService } from "src/app/services/toast.service";
import { WalletService } from "src/app/services/wallet.service";
import { TOKEN } from "src/app/constants/token-name.constant";

@Component({
  templateUrl: "./monetization-page.component.html",
  styleUrls: ["./monetization-page.component.scss"],
})
export class MonetizationPageComponent implements OnInit {
  TOKEN = TOKEN;
  tokenPrice: number;
  propertiesList: IAdminPropertyItem[] = [];
  types = [
    { type: MONETIZATION_TYPE.ONETIME, name: "One-time" },
    { type: MONETIZATION_TYPE.PERIODIC, name: "Periodic" },
  ];
  periods = [
    { type: PERIOD_VARIANTS.YEAR, name: "Year" },
    { type: PERIOD_VARIANTS.QUARTER, name: "Quarter" },
    { type: PERIOD_VARIANTS.MONTH, name: "Month" },
  ];
  monetForm = new FormGroup({});
  payoutDate = "";
  isEditMode: boolean = false;
  dividendId: string;

  intervalRef: any;

  constructor(
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
    private _dividendsService: DividendsService,
    private _toastService: ToastService,
    private _propertiesService: PropertiesService,
    private _walletService: WalletService
  ) {
    this._activatedRoute.params.subscribe(p => {
      if (p.id && this._router.url.includes("edit")) {
        this.isEditMode = true;
        this.dividendId = p.id;
      }
    });

    this.monetForm = this.fb.group({
      isActive: [false],
      type: [MONETIZATION_TYPE.ONETIME],
      object: [undefined],
      plannedStartsAt: [undefined, [Validators.required]],
      startsAt: [undefined, [Validators.required, dateRangeValidator(new Date(), null)]],
      euroAmount: [undefined, [Validators.required, Validators.min(1)]],
      period: [PERIOD_VARIANTS.YEAR],
    });

    this._walletService.appTokenPrice$.subscribe(price => {
      this.tokenPrice = price;
    });
  }

  async ngOnInit() {
    if (this.isEditMode) {
      const res = await this._dividendsService.genMonetizationById(this.dividendId);
      this.monetForm.patchValue(res);
      this.monetForm.controls.object.setValue(res.property);
      this.calculateNextPayoutDate(res.startsAt, res.period);
    } else {
      this.getProperties();
    }

    this.monetForm.controls.startsAt.valueChanges.subscribe(value => {
      this.monetForm.controls.plannedStartsAt.setValidators([
        Validators.required,
        dateRangeValidator(this.monetForm.value.startsAt, null),
      ]);
      this.monetForm.controls.plannedStartsAt.updateValueAndValidity();

      if (this.getSelectedType() === MONETIZATION_TYPE.PERIODIC) {
        this.calculateNextPayoutDate(value, this.monetForm.value.period);
      }
    });

    this.monetForm.controls.period.valueChanges.subscribe(period => {
      this.calculateNextPayoutDate(this.monetForm.value.startsAt, period);
    });

    // Request token price every minute
    this.intervalRef = interval(60 * 1000).subscribe(() => {
      this._walletService.getAppTokenPrice();
    });
  }

  async getProperties() {
    const properties = await this._propertiesService.getPropertiesForMonetization();
    this.propertiesList = properties;
    this.monetForm.controls.object.setValue(this.propertiesList[0]);
  }

  getPropertiesNames() {
    if (this.propertiesList.length) {
      return this.propertiesList.map(x => x.name);
    } else {
      return [];
    }
  }

  setProperty(propertyName: string) {
    const prop = this.propertiesList.find(x => x.name === propertyName);
    this.monetForm.controls.object.setValue(prop);
  }

  goBack() {
    this._location.back();
  }

  selectType(type: (typeof this.types)[0]) {
    this.monetForm.controls.type.setValue(type.type);
    if (type.type === MONETIZATION_TYPE.PERIODIC && this.monetForm.value.startsAt) {
      this.calculateNextPayoutDate(this.monetForm.value.startsAt, this.monetForm.value.period);
    }
  }
  getSelectedType(): MONETIZATION_TYPE {
    return this.monetForm.value.type;
  }

  getPeriodNames() {
    return this.periods.map(period => period.name);
  }
  getSinglePeriodName(type: PERIOD_VARIANTS) {
    return this.periods.find(period => period.type === type).name;
  }
  setPeriod(periodName: string) {
    const foundType = this.periods.find(period => period.name === periodName).type;
    this.monetForm.controls.period.setValue(foundType);
  }

  returnDividendsPerToken() {
    const eurAmount = this.monetForm.value.euroAmount;
    const tokensAmount = eurAmount / this.tokenPrice;
    return `${new PrettifyNumberPipe().transform(
      eurAmount
    )} EUR = ${new PrettifyNumberPipe().transform(tokensAmount)} ${TOKEN}`;
  }

  async handleSubmit() {
    if (this.isEditMode) {
      const updated = { ...this.monetForm.value };
      updated.euroAmount = +updated.euroAmount;
      delete updated.object;

      await this._dividendsService.updateMonetization(this.dividendId, updated);
      this._toastService.open("Monetization updated", "success");
    } else {
      const newMonetization = { ...this.monetForm.value };
      newMonetization.propertyId = this.monetForm.value.object.id;
      delete newMonetization.object;
      newMonetization.euroAmount = +newMonetization.euroAmount;

      await this._dividendsService.createMonetization(newMonetization);
      this._toastService.open("Monetization created", "success");
      this._router.navigate(["/admin/monetization"], { queryParams: { currentTab: 1 } });
    }
  }

  ngOnDestroy() {
    this.intervalRef.unsubscribe();
  }

  private calculateNextPayoutDate(currentDate: string, period: PERIOD_VARIANTS) {
    if (!currentDate) return;

    const nextDate = new Date(currentDate);
    switch (period) {
      case PERIOD_VARIANTS.YEAR:
        nextDate.setDate(nextDate.getDate() + 365);
        break;
      case PERIOD_VARIANTS.QUARTER:
        nextDate.setDate(nextDate.getDate() + 90);
        break;
      case PERIOD_VARIANTS.MONTH:
        nextDate.setDate(nextDate.getDate() + 30);
        break;
    }
    this.payoutDate = new PrettifyDatePipe().transform(nextDate.toISOString());
  }
}

function dateRangeValidator(min: Date, max: Date): ValidatorFn {
  return control => {
    if (!control.value) return null;

    const dateValue = new Date(control.value);

    if (min && dateValue < min) {
      return { dateError: "Date less than required" };
    }

    if (max && dateValue > max) {
      return { dateError: "Date is more than required" };
    }

    return null;
  };
}
