<app-header></app-header>
<section class="login dialogue">
  <h2>{{ "LOGIN.LOGIN_OR_SIGNUP" | translate }}</h2>
  <h4>{{ "LOGIN.ENTER_EMAIL" | translate }}</h4>

  <app-input
    type="email"
    caption="Email"
    [formControl]="email"
    [error]="returnEmailError()"
    icon="mail"
    [placeholder]="'LOGIN.ENTER_EMAIL_HERE' | translate"
    [shouldFocus]="true"
  ></app-input>

  <button class="button" [disabled]="email.invalid || isPending" (click)="sendUserEmail()">
    {{ "LOGIN.CONTINUE" | translate }}
  </button>
</section>

<app-footer></app-footer>
