import { SC_TRANSACTION_STATUS } from "./property";

export interface IMonetization {
  propertyId?: string;
  type: MONETIZATION_TYPE;
  period: PERIOD_VARIANTS;
  euroAmount: number;
  startsAt: string;
  plannedStartsAt: string;
  isActive: boolean;
}

export interface IMonetizationListItem extends IMonetization {
  id: string;
  property: {
    name: string;
    id: string;
    tokenTicker: string;
  };
}

export enum MONETIZATION_TYPE {
  ONETIME,
  PERIODIC,
}

export enum PERIOD_VARIANTS {
  MONTH,
  QUARTER,
  YEAR,
}

export interface ISharedHolder {
  accountIdentity: {
    firstName: string;
    secondName: string;
  };
  id: string;
  tokensAmount: number;
  adrdAmount: number;
  transaction?: {
    id: string;
    hash: string;
    status: SC_TRANSACTION_STATUS;
  };
}

export interface ISnapshot {
  id: string;
  paymentDate: string;
  property: {
    id: string;
    name: string;
  };
  euroAmount: number;
  token: {
    ticker: string;
  };
  sharedCount?: number;
  shared?: ISharedHolder[];
  isExists?: boolean;
  status: SNAPSHOT_STATUS;
}

export enum SNAPSHOT_STATUS {
  NOT_STARTED,
  PENDING,
  COMPLETED,
}
