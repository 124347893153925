import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-big-input",
  templateUrl: "./big-input.component.html",
  styleUrls: ["./big-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BigInputComponent),
      multi: true,
    },
  ],
})
export class BigInputComponent implements OnInit {
  @Input() caption: string;
  @Input() textConditions: string;
  @Input() ticker: string;
  @Input() error?: string;
  @Input() disabled?: boolean = false;
  value: number = null;

  constructor() {}

  ngOnInit(): void {}

  onChange: any = (_: any) => {};
  onTouched: any = () => {};

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
