import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  templateUrl: "./blocked.component.html",
  styleUrls: ["./blocked.component.scss", "../login/login.component.scss"],
})
export class BlockedComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {}

  logoutAndBackToLogin() {
    this._authService.logout();
  }
}
