import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  @Input() headline: string;
  @Input() subtitle?: string;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {}

  logout() {
    this._authService.logout();
  }
}
