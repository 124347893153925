import { Component, OnInit } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ModalService } from "src/app/components/_modal";
import { ORDER_TYPE } from "src/app/models/filters";
import { OWNED_PROPERTIES_SORTING_LIST } from "src/app/models/filters";
import {
  IPortfolioResponse,
  IProperty,
  IPropertyTransaction,
  SC_TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "src/app/models/property";
import { PropertiesService } from "src/app/services/properties.service";
import { TOKEN } from "src/app/constants/token-name.constant";
import { Utils } from "src/app/utils/utils";
import { AREA_IN_TOKEN } from "src/app/constants/token-area.constant";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent implements OnInit {
  Utils = Utils;
  TOKEN = TOKEN;

  tabs = ["PORTFOLIO.MY_PROPERTIES", "PORTFOLIO.ACTIVITIES"];
  currentTab = 0;

  portfolioResponse: IPortfolioResponse;
  ownedProperties: IProperty[] = [];
  propertyPage = 0;
  propertySorting = OWNED_PROPERTIES_SORTING_LIST;
  currentPropertySort = this.propertySorting[0];
  propertySearch: string;
  isPropertiesLoading = true;
  ownedHasMorePages = false;
  pendingProperties: IProperty[] = [];

  userActivities = new MatTableDataSource<IPropertyTransaction>();
  activitiesColumns = [
    "created_at",
    "property_name",
    "transaction_type",
    "tokens_amount",
    "adrd_amount",
    "action",
  ];
  activityPage = 0;
  selectedActivity: IPropertyTransaction;
  isTransactionsLimitReached = false;
  activitySearch: string;
  activitySort: any = null;
  isActivityLoading: boolean = true;

  constructor(
    private readonly _propertiesService: PropertiesService,
    private readonly _modalService: ModalService,
    private readonly _router: Router
  ) {}

  async ngOnInit() {
    this.getUserPropertiesData();

    const transactions = await this.getUserTransactions();
    this.userActivities.data = transactions.items;
  }

  switchTab(tabNumber: number) {
    this.currentTab = tabNumber;
  }

  async getUserPropertiesData() {
    const filters = this.buildFilters(this.propertySearch, this.currentPropertySort);
    this.portfolioResponse = await this._propertiesService.getUserProperties(
      this.propertyPage,
      filters
    );
    this.isPropertiesLoading = false;
    this.ownedProperties.push(...this.portfolioResponse.items);
    this.ownedHasMorePages = this.propertyPage + 1 < this.portfolioResponse.pagination.totalPages;
    this.pendingProperties.push(...this.portfolioResponse.meta.pendingProperties);
  }

  async getUserTransactions() {
    const filters = this.buildFilters(this.activitySearch, this.activitySort);
    const resp = await this._propertiesService.getMyTransactions(this.activityPage, filters);
    this.isTransactionsLimitReached = this.activityPage + 1 === resp.pagination.totalPages;
    this.isActivityLoading = false;
    return resp;
  }

  getPropertySortingNames() {
    return this.propertySorting.map(x => x.name);
  }

  async performPropertySort(sortName: string) {
    this.currentPropertySort = this.propertySorting.find(x => x.name === sortName);
    this.isPropertiesLoading = true;
    this.ownedProperties = [];
    this.getUserPropertiesData();
  }

  async loadMoreTransactions() {
    this.activityPage++;
    const newTransactions = await this.getUserTransactions();
    this.userActivities.data = [...this.userActivities.data, ...newTransactions.items];
  }

  async loadMoreProperties() {
    this.propertyPage++;
    await this.getUserPropertiesData();
  }

  openTransactionModal(act: IPropertyTransaction) {
    this.selectedActivity = act;
    this._modalService.open("transaction-details-modal");
  }

  closeTransactionModal() {
    this._modalService.close("transaction-details-modal");
  }

  calculateAreaFromTokens(tokens: number) {
    return (+tokens * AREA_IN_TOKEN).toFixed(2);
  }

  openPropertyPage(propId: string) {
    this._router.navigateByUrl(`/dashboard/properties/${propId}`);
  }

  renderTransactionType(trans: TRANSACTION_TYPE) {
    switch (trans) {
      case TRANSACTION_TYPE.BUY:
        return "Buy";
      case TRANSACTION_TYPE.SELL:
        return "Sell";
      case TRANSACTION_TYPE.P2P:
        return "P2P";
    }
  }

  performSearchProperty() {
    this.isPropertiesLoading = true;
    this.ownedProperties = [];
    this.getUserPropertiesData();
  }

  async performActivitySearch() {
    this.isActivityLoading = true;
    this.activityPage = 0;
    const data = await this.getUserTransactions();
    this.userActivities.data = data.items;
  }

  renderActivityStatus(status: SC_TRANSACTION_STATUS) {
    switch (status) {
      case SC_TRANSACTION_STATUS.PENDING:
        return "Pending";
      case SC_TRANSACTION_STATUS.CANCELLED:
        return "Cancelled";
      case SC_TRANSACTION_STATUS.COMPLETED:
        return "Completed";
      case SC_TRANSACTION_STATUS.FAILED:
        return "Failed";
      case SC_TRANSACTION_STATUS.NOT_STARTED:
        return "Not Started";
    }
  }

  async onActivitySort(sort: Sort) {
    if (!sort.direction) {
      this.activitySort = null;
    } else {
      this.activitySort = { type: sort.active, order: sort.direction.toUpperCase() };
    }
    this.activityPage = 0;
    const sortedTransactions = await this.getUserTransactions();
    this.userActivities.data = sortedTransactions.items;
  }

  private buildFilters(search: string, sort?: { type: string; order: ORDER_TYPE }) {
    const filters = [];
    const searchQuery = `search=${search}`;
    if (search) {
      filters.push(searchQuery);
    }
    if (sort) {
      const orderFieldQuery = `order[field]=${sort.type}`;
      const orderDirQuery = `order[direction]=${sort.order}`;
      filters.push(orderFieldQuery, orderDirQuery);
    }
    return filters;
  }
}
