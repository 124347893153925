<main class="wallet">
  <app-main-header [headline]="'WALLET.TITLE' | translate"></app-main-header>

  <section class="wallet__header walHeader">
    <p class="walHeader__descr">{{ "WALLET.WALLET_BALANCE" | translate }}</p>

    <div class="walHeader__wrapper">
      <div class="walHeader__balanceWrapper">
        <div class="walHeader__balanceItem">
          <p style="font-size: 2rem">
            {{ walletInfo?.balanceRemain + walletInfo?.totalInvested | prettifyNumber }}
            <span style="margin-left: 0.75rem">{{ TOKEN }}</span>
          </p>
        </div>
        <div class="walHeader__balanceItem">
          <small>{{ "WALLET.AVAILABLE" | translate }}</small>
          <p>
            {{ walletInfo?.balanceRemain | prettifyNumber }}
            <span>{{ TOKEN }}</span>
          </p>
        </div>
        <div class="walHeader__balanceItem">
          <small>
            {{ "WALLET.IN_HOLD" | translate }} <img src="assets/icons/lock.svg" alt="In hold" />
          </small>
          <p>
            {{ walletInfo?.balanceFrozen | prettifyNumber }} <span>{{ TOKEN }}</span>
          </p>
        </div>
      </div>

      <div class="walHeader__buttons">
        <button class="button button_outline" (click)="openDepositModal()">
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.5 6H8.5V0H6.5V6H0.5V8H6.5V14H8.5V8H14.5V6Z" fill="#0066ff" />
          </svg>
          {{ "SIDEBAR.DEPOSIT" | translate }}
        </button>
        <button class="button" (click)="checkCanWithdraw()">
          <img src="assets/icons/withdrawal.svg" alt="Withdrawal" />
          {{ "WALLET.WITHDRAWAL" | translate }}
        </button>
      </div>
    </div>
  </section>

  <h2 class="wallet__activityTitle">{{ "WALLET.WALLET_ACTIVITY" | translate }}</h2>

  <section *ngIf="!isLoading; else loader">
    <div class="wallet__activity" *ngIf="dataSource.data; else noActivity">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onTableSorting($event)"
        class="matTable wallet__tableWrapper"
      >
        <ng-container [matColumnDef]="displayedColumns[0]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "PROPERTY_ACTIVITY.DATE" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop" [title]="prop.date | prettifyDate : 'full'">
            {{ prop.date | prettifyDate }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[1]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "PROPERTY_ACTIVITY.TYPE" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop" [class.mat-column-type_success]="prop.type === 1">
            {{ renderTransactionType(prop.type) | translate }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[2]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "PROPERTY_ACTIVITY.AMOUNT" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop">
            {{ prop.amountAdrd | prettifyNumber }} {{ TOKEN }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[3]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "PROPERTY_ACTIVITY.STATUS" | translate }}
          </th>
          <td mat-cell *matCellDef="let prop">
            <span
              class="walActivity__badge"
              [class.walActivity__badge_completed]="prop.status === 1"
              [class.walActivity__badge_cancelled]="prop.status === 2"
              >{{ renderStatus(prop.status) | translate }}</span
            >
          </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[4]">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let prop">
            <button class="walActivity__infoBtn" (click)="openTransactionInfoModal(prop.id)">
              <img src="assets/icons/eye.svg" alt="show" />
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <button
        class="button button_light walActivity__loadBtn"
        (click)="loadMore()"
        *ngIf="!isLimitReached"
      >
        <img src="assets/icons/refresh.svg" alt="" />
        {{ "PROPERTIES.LOAD_MORE" | translate }}
      </button>
    </div>

    <ng-template #noActivity>
      <div class="noActivity">
        <img src="assets/icons/sad-smile.svg" alt="" />
        <p>{{ "WALLET.NO_TRANSACTIONS" | translate }}</p>
        <button class="button" (click)="openDepositModal()">
          + {{ "WALLET.DEPOSIT" | translate }}
        </button>
      </div>
    </ng-template>
  </section>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</main>

<jw-modal id="verification-req-modal">
  <div class="verificationReq">
    <div class="verificationReq__header">
      <h4 class="title title_sub">{{ "WALLET.COMPLETE_VERIFICATION" | translate }}</h4>
      <button (click)="closeModalAboutVerification()">&#10006;</button>
    </div>
    <p class="verificationReq__text">
      {{ "WALLET.IN_ORDER" | translate }}
      <span>{{ "SETTINGS.PLEASE_PREPARE" | translate }}</span>
    </p>
    <div class="verificationReq__buttons">
      <button class="button button_outline" (click)="closeModalAboutVerification()">
        {{ "SETTINGS.MAYBE_LATER" | translate }}
      </button>
      <button class="button" routerLink="/dashboard/settings/verification">
        {{ "SETTINGS.START_VERIFICATION" | translate }}
      </button>
    </div>
  </div>
</jw-modal>
