<article
  class="property-card"
  [ngClass]="{
    'property-card_horizontal': variant === 'horizontal',
    'property-card_clickable': cardType === 'property' || cardType === 'userDetails'
  }"
  (click)="cardType !== 'p2pOrderSell' && cardType !== 'p2pOrderBuy' && onCardClick($event)"
  *ngIf="data"
>
  <app-image-carousel [images]="data.images"></app-image-carousel>

  <div
    *ngIf="status"
    class="property-card__status"
    [class.property-card__status_pending]="status === 'pending'"
  >
    {{ status === "pending" ? ("PROPERTY_CARD.STATUS_PENDING" | translate) : "" }}
  </div>

  <button
    *ngIf="showFavButton"
    class="property-card__fav"
    [ngClass]="{ 'property-card__fav_in': isInFavorites }"
    (click)="handleFavoriteClick($event)"
  >
    <img *ngIf="!isInFavorites" src="assets/icons/heart-outline.svg" alt="Add to favorite" />
    <img *ngIf="isInFavorites" src="assets/icons/heart-fill.svg" alt="Delete from favorite" />
  </button>

  <div class="property-card__descr">
    <h3 class="property-card__name">
      {{ data.name ?? data.property.name }}
    </h3>
    <div
      class="property-card__tags"
      *ngIf="data.tags?.length > 0 || data.property?.tags.length > 0"
    >
      <span
        *ngFor="let tag of data.tags ?? data.property.tags; index as i"
        class="property-card__singleTag"
      >
        {{ renderTag(tag, i) }}
      </span>
    </div>

    <div
      class="property-card__p2pButton"
      [matTooltip]="'P2P.NOT_ENOUGH_FRAGMENTS_TO_SELL' | translate"
      matTooltipPosition="above"
      [matTooltipDisabled]="data.isAvailable || cardType !== 'p2pOrderSell'"
    >
      <button
        *ngIf="cardType === 'p2pOrderBuy' || cardType === 'p2pOrderSell'"
        class="button button_success"
        [class.button_success]="cardType === 'p2pOrderBuy'"
        [class.button_error]="cardType === 'p2pOrderSell'"
        (click)="onCardClick($event)"
        [disabled]="!data.isAvailable"
      >
        {{ cardType === "p2pOrderBuy" ? ("P2P.BUY_FRAGMENT" | translate) : "" }}
        {{ cardType === "p2pOrderSell" ? ("P2P.SELL_FRAGMENT" | translate) : "" }}
      </button>
    </div>

    <ng-container *ngIf="cardType === 'property'">
      <p class="property-card__text property-card__text_smaller">
        {{ "PROPERTY_CARD.SHORT_DESCR" | translate }}: {{ data.description }}
      </p>
      <div
        class="property-card__textWrapper"
        [ngClass]="{ 'property-card__textWrapper_horizontal': variant === 'horizontal' }"
      >
        <p class="property-card__text">
          {{ "PROPERTY_CARD.START_INVESTING" | translate }}:
          <span>{{ data.startInvestingFrom | prettifyNumber }} {{ TOKEN }}</span>
        </p>
        <p class="property-card__text">
          {{ "PROPERTY_CARD.ESTIMATED_INCOME" | translate }}
          <span>{{ data.estimatedIncome }} %</span>
        </p>
      </div>

      <div class="property-card__progressWrapper">
        <app-progress-bar
          [percentage]="calculatePercentage(data.collected, data.target)"
        ></app-progress-bar>
        <p class="property-card__text">{{ calculatePercentage(data.collected, data.target) }}%</p>
      </div>

      <div class="property-card__summary">
        <div>
          <p>{{ "PROPERTY_CARD.COLLECTED" | translate }}</p>
          <h4>
            {{ data.collected.toLocaleString() }} {{ TOKEN }} ({{
              calculatePercentage(data.collected, data.target)
            }}%)
          </h4>
        </div>
        <div>
          <p>{{ "PROPERTY_CARD.TARGET" | translate }}</p>
          <h4>{{ data.target.toLocaleString() }} {{ TOKEN }}</h4>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cardType === 'userDetails'">
      <div class="property-card__owned">
        <div class="property-card__ownedItem">
          {{ "WALLET.IN_OWN" | translate }}:
          <span style="font-weight: 700; color: var(--light-blue)">
            {{ data.quantity / AREA_IN_TOKEN | prettifyNumber }} {{ data.tokenTicker }}
          </span>
        </div>
        <span></span>
        <div class="property-card__ownedItem">
          {{ "WALLET.IN_HOLD" | translate }}:
          <span style="color: var(--main-blue)">
            {{ data.frozenQuantity / AREA_IN_TOKEN | prettifyNumber }} {{ data.tokenTicker }}
          </span>
          <img src="assets/icons/lock.svg" alt="In hold" />
        </div>
      </div>
      <hr class="property-card__divider" />
      <div class="property-card__userDetails">
        <p class="property-card__detail">
          {{ "PROPERTY_CARD.CURRENT_INCOME" | translate }}
          <span>{{ (data.currentIncome | prettifyNumber) + " " + TOKEN }}</span>
        </p>
        <p class="property-card__detail">
          {{ "PROPERTY_CARD.PURCHASED_QUANTITY" | translate }}
          <span>{{ (data.quantity | prettifyNumber) + " m²" }}</span>
        </p>
        <p class="property-card__detail">
          {{ "PROPERTY_CARD.AMOUNT_INVESTED" | translate }}
          <span>{{ (data.amountInvested | prettifyNumber) + " " + TOKEN }}</span>
        </p>
        <p class="property-card__detail">
          {{ "PROPERTY_CARD.EXPECTED_INCOME" | translate }}
          <span>{{ data.expectedIncome }}%</span>
        </p>
      </div>
    </ng-container>

    <div
      *ngIf="cardType === 'p2pOrderBuy' || cardType === 'p2pOrderSell'"
      class="property-card__p2pOrder"
    >
      <p class="property-card__p2pOrderField">
        {{ "PROPERTY_CARD.ORDERS" | translate }} <span>{{ data.totalRequests }}</span>
      </p>
      <p class="property-card__p2pOrderField">
        {{ "PROPERTY_CARD.TOKEN_PRICE" | translate }}
        <span>{{ data.minPrice }} - {{ data.maxPrice }} {{ TOKEN }}</span>
      </p>
      <p class="property-card__p2pOrderField">
        {{ "PROPERTY_CARD.AVAILABLE_AREA" | translate }}
        <span
          >{{ data.totalRequestsTokens | prettifyNumber }} {{ data.token.ticker }} ({{
            data.totalRequestsArea | prettifyNumber
          }}
          m²)</span
        >
      </p>
      <p class="property-card__p2pOrderField">
        {{ "PROPERTY_CARD.TOTAL_ORDERS_AMOUNT" | translate }}
        <span>{{ data.totalOrdersAmount | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <p *ngIf="data.profitability" class="property-card__p2pOrderField">
        {{ "PROPERTY_CARD.ESTIMATED_INCOME" | translate }} <span>{{ data.profitability }}%</span>
      </p>
    </div>

    <div *ngIf="cardType === 'p2p'" class="property-card__p2pInfo">
      <p *ngIf="data.profitability" class="property-card__infoItem">
        {{ "PROPERTY_CARD.ESTIMATED_INCOME" | translate }} <span>{{ data.profitability }}%</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.ORDERS" | translate }} <span>{{ data.totalRequests }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TOTAL_ORDERS_AMOUNT" | translate }}
        <span>{{ data.totalOrdersAmount | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <hr class="property-card__p2pInfoDivider" />
      Property token info
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TICKER" | translate }}
        <span>{{ data.token.ticker }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TOKEN_PRICE" | translate }}
        <span>{{ data.minPrice }} - {{ data.maxPrice }} {{ TOKEN }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TOTAL_TOKEN_SUPPLY" | translate }}
        <span>{{ data.token.amount | prettifyNumber }} {{ data.token.ticker }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.AVAILABLE_TOKENS" | translate }}
        <span>{{ data.totalRequestsTokens | prettifyNumber }} {{ data.token.ticker }}</span>
      </p>
    </div>

    <div
      *ngIf="cardType === 'p2pCreateSell' || cardType === 'p2pCreateBuy'"
      class="property-card__p2pInfo"
    >
      <p class="property-card__smallText">
        {{ "PROPERTY_CARD.SHORT_DESCR" | translate }}: {{ data.property.description }}
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.OBJECT_PRICE" | translate }}
        <span>{{ data.investInfo.totalPrice | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TICKER" | translate }} <span>{{ data.token.ticker }}</span>
      </p>
      <p class="property-card__infoItem">
        {{ "PROPERTY_CARD.TOKEN_PRICE" | translate }}
        <span>{{ data.token.price | prettifyNumber }} {{ TOKEN }}</span>
      </p>
      <ng-container *ngIf="cardType === 'p2pCreateSell'">
        <p class="property-card__infoItem">
          {{ "PROPERTY_CARD.TOKENS_IN_PORTFOLIO" | translate }}
          <span
            >{{
              (data.owned.quantity + data.owned.frozenQuantity) / data.token.squarePerToken
                | prettifyNumber
            }}
            {{ data.token.ticker }}</span
          >
        </p>
        <p class="property-card__infoItem">
          {{ "PROPERTY_CARD.TOKENS_AVAILABLE_TO_SELL" | translate }}
          <span
            >{{ data.owned.quantity / data.token.squarePerToken | prettifyNumber }}
            {{ data.token.ticker }}</span
          >
        </p>
      </ng-container>
      <ng-container *ngIf="cardType === 'p2pCreateBuy'">
        <p class="property-card__infoItem">
          {{ "PROPERTY_CARD.TOTAL_TOKEN_SUPPLY" | translate }}
          <span
            >{{ data.investInfo.totalPrice / data.token.price | prettifyNumber }}
            {{ data.token.ticker }}</span
          >
        </p>
        <p class="property-card__infoItem">
          {{ "PROPERTY_CARD.ESTIMATED_INCOME" | translate }}
          <span>{{ data.investInfo.profitability | prettifyNumber }}%</span>
        </p>
      </ng-container>
    </div>
  </div>
</article>
