import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { SwiperModule } from "swiper/angular";
import { ModalModule } from "./components/_modal";

import { PropertyComponent } from "./views/property/property.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./views/login/login/login.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PasswordComponent } from "./views/login/password/password.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MainComponent } from "./components/main/main.component";
import { SettingsComponent } from "./views/settings/settings.component";
import { PropertiesComponent } from "./views/properties/properties.component";
import { PropertyCardComponent } from "./components/property-card/property-card.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { PortfolioComponent } from "./views/portfolio/portfolio.component";
import { WalletComponent } from "./views/wallet/wallet.component";
import { BuyPropertyComponent } from "./views/property/buy-property/buy-property.component";
import { TwoDigitDecimaNumberDirective } from "./directives/two-decimal-number.directive";
import { SelectComponent } from "./components/select/select.component";
import { IdentityComponent } from "./views/login/identity/identity.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { MatDatepickerModule } from "@matheo/datepicker";
import { NativeDateModule, DateAdapter } from "@matheo/datepicker/core";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { SumsubContainerComponent } from "./components/sumsub-container/sumsub-container.component";
import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { LogoutButtonComponent } from "./components/logout-button/logout-button.component";
import { DateMaskDirective } from "./directives/date-mask.directive";
import { AdminComponent } from "./views/admin/admin.component";
import { AdminSidebarComponent } from "./components/_admin/admin-sidebar/admin-sidebar.component";
import { AdminPropertiesComponent } from "./views/admin/admin-properties/admin-properties.component";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { InputComponent } from "./components/input/input.component";
import { MatChipsModule } from "@angular/material/chips";
import { AdminLoginComponent } from "./views/admin/admin-login/admin-login.component";
import { PropertiesCreateComponent } from "./views/admin/properties-create/properties-create.component";
import { TabsComponent } from "./components/tabs/tabs.component";
import { VerificationComponent } from "./views/settings/verification/verification.component";
import { AdminPropertyComponent } from "./components/_admin/admin-property/admin-property.component";
import { AdminPropertyPageComponent } from "./views/admin/admin-property-page/admin-property-page.component";
import { ScTabComponent } from "./components/_admin/sc-tab/sc-tab.component";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { SwitchComponent } from "./components/switch/switch.component";
import { FilterBarComponent } from "./components/filter-bar/filter-bar.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { InputSliderComponent } from "./components/input-slider/input-slider.component";
import { PropertyActivityTableComponent } from "./components/property-activity-table/property-activity-table.component";
import { BigInputComponent } from "./components/big-input/big-input.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { WithdrawalComponent } from "./views/wallet/withdrawal/withdrawal.component";
import { InvestInfoTabComponent } from "./components/_admin/invest-info-tab/invest-info-tab.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { P2pComponent } from "./views/p2p/p2p.component";
import { ImageCarouselComponent } from "./components/image-carousel/image-carousel.component";
import { BuyP2pComponent } from "./views/p2p/buy-p2p/buy-p2p.component";
import { OrderCreationComponent } from "./components/order-creation/order-creation.component";
import { P2pOrdersComponent } from "./components/p2p-orders/p2p-orders.component";
import { TokensAreaInputComponent } from "./components/tokens-area-input/tokens-area-input.component";
import { SellP2pComponent } from "./views/p2p/sell-p2p/sell-p2p.component";
import { Platform } from "@angular/cdk/platform";
import { CustomDateAdapter, MY_DATE_FORMAT } from "./utils/custom-date-adapter";
import { FavoritesComponent } from "./views/favorites/favorites.component";
import { DepositModalComponent } from "./components/deposit-modal/deposit-modal.component";
import { AdminRequestsComponent } from "./views/admin/admin-requests/admin-requests.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { httpInterceptorProviders } from "./utils/http.interceptor";
import { PrettifyDatePipe } from "./pipes/prettify-date.pipe";
import { PrettifyNumberPipe } from "./pipes/prettify-number.pipe";
import { ShortenTextPipe } from "./pipes/shorten-text.pipe";
import { PositiveNumberDirective } from "./directives/positive-number.directive";
import { BuySellFormComponent } from "./components/buy-sell-form/buy-sell-form.component";
import { EmailChangeComponent } from "./views/email-change/email-change.component";
import { TmComponent } from "./views/admin/tm/tm.component";
import { MonetizationComponent } from "./views/admin/monetization/monetization.component";
import { DividendsPageComponent } from "./views/admin/monetization/dividends-page/dividends-page.component";
import { CopyButtonComponent } from "./components/copy-button/copy-button.component";
import { TickerDirective } from "./directives/ticker.directive";
import { MonetizationPageComponent } from "./views/admin/monetization/monetization-page/monetization-page.component";
import { CustomPaginator } from "./utils/custom-paginator";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CardSkeletonComponent } from "./components/card-skeleton/card-skeleton.component";
import { ReferralsComponent } from "./views/referrals/referrals.component";
import { MonetizationTableComponent } from "./components/_admin/monetization-table/monetization-table.component";
import { PayoutsTableComponent } from "./components/_admin/payouts-table/payouts-table.component";
import { PayoutHistoryTableComponent } from "./components/_admin/payout-history-table/payout-history-table.component";
import { IntersectionObserverDirective } from "./directives/intersection-observer.directive";
import { BlockedComponent } from "./views/login/blocked/blocked.component";
import { TransactionsComponent } from "./views/admin/transactions/transactions.component";
import { UsersComponent } from "./views/admin/users/users.component";
import { UserPageComponent } from "./views/admin/users/user-page/user-page.component";
import { A11yModule } from "@angular/cdk/a11y";
import { IntegerNumberDirective } from "./directives/integer-number.directive";
import { MyOrdersComponent } from "./views/p2p/my-orders/my-orders.component";
import { CreateP2pComponent } from "./views/p2p/create-p2p/create-p2p.component";
import { LeadingZeroesPipe } from "./pipes/leading-zeroes.pipe";

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    PasswordComponent,
    SidebarComponent,
    MainComponent,
    SettingsComponent,
    PropertyComponent,
    PropertiesComponent,
    PropertyCardComponent,
    ProgressBarComponent,
    PortfolioComponent,
    WalletComponent,
    BuyPropertyComponent,
    TwoDigitDecimaNumberDirective,
    SelectComponent,
    IdentityComponent,
    MainHeaderComponent,
    SumsubContainerComponent,
    LanguageSelectorComponent,
    LogoutButtonComponent,
    DateMaskDirective,
    AdminComponent,
    AdminSidebarComponent,
    AdminPropertiesComponent,
    InputComponent,
    AdminLoginComponent,
    PropertiesCreateComponent,
    TabsComponent,
    VerificationComponent,
    AdminPropertyComponent,
    AdminPropertyPageComponent,
    ScTabComponent,
    SwitchComponent,
    FilterBarComponent,
    InputSliderComponent,
    PropertyActivityTableComponent,
    BigInputComponent,
    WithdrawalComponent,
    InvestInfoTabComponent,
    P2pComponent,
    ImageCarouselComponent,
    BuyP2pComponent,
    OrderCreationComponent,
    P2pOrdersComponent,
    TokensAreaInputComponent,
    SellP2pComponent,
    FavoritesComponent,
    DepositModalComponent,
    AdminRequestsComponent,
    LoaderComponent,
    PrettifyDatePipe,
    PrettifyNumberPipe,
    ShortenTextPipe,
    PositiveNumberDirective,
    BuySellFormComponent,
    EmailChangeComponent,
    TmComponent,
    MonetizationComponent,
    DividendsPageComponent,
    MonetizationPageComponent,
    CopyButtonComponent,
    TickerDirective,
    CardSkeletonComponent,
    ReferralsComponent,
    MonetizationTableComponent,
    PayoutsTableComponent,
    PayoutHistoryTableComponent,
    IntersectionObserverDirective,
    BlockedComponent,
    TransactionsComponent,
    UsersComponent,
    UserPageComponent,
    IntegerNumberDirective,
    MyOrdersComponent,
    CreateP2pComponent,
    LeadingZeroesPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SwiperModule,
    ModalModule,
    NgxIntlTelInputModule,
    MatDatepickerModule,
    NativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatPaginatorModule,
    NgxSliderModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxSkeletonLoaderModule,
    A11yModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMAT,
    },
    httpInterceptorProviders,
    {
      provide: MatPaginatorIntl,
      useValue: CustomPaginator(),
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
