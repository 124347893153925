import { Component, OnInit } from "@angular/core";

import { SettingsService } from "src/app/services/settings.service";
import { WalletService } from "src/app/services/wallet.service";
import { SumsubService } from "src/app/services/sumsub.service";
import { FavoritesService } from "src/app/services/favorites.service";
import { IWalletBalance } from "src/app/models/wallet";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  walletInfo: IWalletBalance = {
    balanceRemain: 0,
    totalInvested: 0,
    balanceFrozen: 0,
  };

  routeSubscription: Subscription;

  constructor(
    private _settingsService: SettingsService,
    private _walletService: WalletService,
    private _sumsubService: SumsubService,
    private _favoritesService: FavoritesService,
    private _router: Router
  ) {
    this.routeSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._walletService.getWalletInfo();
      }
    });
  }

  async ngOnInit() {
    await this._sumsubService.getVerificationStatus();
    this._walletService.getAppTokenPrice();

    const settings = await this._settingsService.getUserSettings();
    await this._settingsService.changeLanguage(settings.language);

    this._favoritesService.getFavorites();
  }

  openDeposit() {
    this._walletService.openDepositModal();
  }

  closeDeposit() {
    this._walletService.closeDepositModal();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
