import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortenText",
})
export class ShortenTextPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length > 5) {
      return value.slice(0, 3) + "..." + value.slice(-3);
    } else {
      return value;
    }
  }
}
