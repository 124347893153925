import { Component, HostListener } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { EMAIL_REGEX } from "src/app/models/auth";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  @HostListener("window:keydown.enter", ["$event"])
  handleKeyPress() {
    if (this.email.valid) {
      this.sendUserEmail();
    }
  }

  email = new FormControl("", [
    Validators.required,
    Validators.email,
    Validators.pattern(EMAIL_REGEX),
  ]);

  isPending: boolean = false;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.queryParams.subscribe(params => {
      if (params.referralCode) {
        localStorage.setItem("referralCode", JSON.stringify(params.referralCode));
      } else {
        localStorage.removeItem("referralCode");
      }
    });
  }

  ngOnInit(): void {}

  async sendUserEmail() {
    this.isPending = true;
    try {
      await this._authService.sendOtpToEmail(this.email.value);
      sessionStorage.setItem("email", this.email.value);
      this._router.navigateByUrl("login/verification");
    } catch (error) {
      throw error;
    } finally {
      this.isPending = false;
    }
  }

  returnEmailError() {
    if (
      this.email.invalid &&
      this.email.dirty &&
      this.email.touched &&
      !this.email.errors?.required
    ) {
      return "Enter correct email";
    }
    if (this.email.errors?.required && this.email.touched) {
      return "This field is required";
    }
    return "";
  }
}
