import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ModalService } from "src/app/components/_modal";
import { SC_TRANSACTION_STATUS } from "src/app/models/property";
import {
  IRawTransaction,
  RAW_TRANSACTION_TYPE,
  TRANSACTION_FAIL_REASON,
  TransactionManagerType,
} from "src/app/models/transaction-manager";
import { FiltersService } from "src/app/services/filters.service";
import { TransactionsService } from "src/app/services/transactions.service";

@Component({
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent implements OnInit {
  tabs = ["Admin", "Client"];
  selectedTabIndex = 0;

  adminTxs = new MatTableDataSource<IRawTransaction>();
  adminTxsColumns = ["id", "hash", "initializer", "type", "status"];
  adminTxsPage = 0;
  adminTxsPageSize = 10;
  adminTxsTotal = 0;
  adminTxsSort: string[] = [];
  adminTxsFilters: string[] = [];

  clientTxs = new MatTableDataSource<IRawTransaction>();
  clientTxsColumns = ["id", "hash", "initializer", "type", "status"];
  clientTxsPage = 0;
  clientTxsPageSize = 10;
  clientTxsTotal = 0;
  clientTxsSort: string[] = [];
  clientTxsFilters: string[] = [];

  txData: IRawTransaction;
  txDataLoading = true;
  txDataButtonsDisabled = false;

  constructor(
    private modalService: ModalService,
    private transactionsService: TransactionsService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.requestAdminTransaction();
    this.requestClientTransaction();
  }

  async requestAdminTransaction() {
    const filters = this.adminTxsSort.concat(this.adminTxsFilters);
    const res = await this.transactionsService.getTransactions(
      "admin",
      this.adminTxsPage,
      this.adminTxsPageSize,
      filters
    );
    this.adminTxs.data = res.items;
    this.adminTxsTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  async requestClientTransaction() {
    const filters = this.clientTxsSort.concat(this.clientTxsFilters);
    const res = await this.transactionsService.getTransactions(
      "client",
      this.clientTxsPage,
      this.clientTxsPageSize,
      filters
    );
    this.clientTxs.data = res.items;
    this.clientTxsTotal = res.pagination.perPage * res.pagination.totalPages;
  }

  onTabChange(tabNumber: number) {
    this.selectedTabIndex = tabNumber;
    if (this.selectedTabIndex === 0) {
      this.adminTxsPage = 0;
      this.adminTxsSort = [];
      this.adminTxsFilters = [];
      this.requestAdminTransaction();
    } else {
      this.clientTxsPage = 0;
      this.clientTxsSort = [];
      this.clientTxsFilters = [];
      this.requestClientTransaction();
    }
  }

  handlePageChange(type: TransactionManagerType, page: PageEvent): void {
    if (type === "admin") {
      this.adminTxsPage = page.pageIndex;
      this.requestAdminTransaction();
    } else {
      this.clientTxsPage = page.pageIndex;
      this.requestClientTransaction();
    }
  }

  handleSort(type: TransactionManagerType, sort: Sort): void {
    const sortFields = sort.direction
      ? [`order[field]=${sort.active}`, `order[direction]=${sort.direction.toUpperCase()}`]
      : [];

    if (type === "admin") {
      this.adminTxsPage = 0;
      this.adminTxsSort = sortFields;
      this.requestAdminTransaction();
    } else {
      this.clientTxsPage = 0;
      this.clientTxsSort = sortFields;
      this.requestClientTransaction();
    }
  }

  async getTransactionData(txId: string) {
    this.txDataLoading = true;
    try {
      this.txData = await this.transactionsService.getTransactionById(txId);
    } catch (error) {
      throw error;
    } finally {
      this.txDataLoading = false;
    }
  }

  openTransactionModal(txId: string) {
    this.modalService.open("tx-modal");
    this.getTransactionData(txId);
  }

  closeTransactionModal() {
    this.modalService.close("tx-modal");
  }

  renderType(type: RAW_TRANSACTION_TYPE) {
    switch (type) {
      case RAW_TRANSACTION_TYPE.BUY_PROPERTY:
        return "Buy Property";
      case RAW_TRANSACTION_TYPE.BURN_PROPERTY:
        return "Burn Property";
      case RAW_TRANSACTION_TYPE.CREATE_PROPERTY:
        return "Create Property";
      case RAW_TRANSACTION_TYPE.DEPOSIT:
        return "Deposit";
      case RAW_TRANSACTION_TYPE.PAY_DIVIDENDS:
        return "Pay Dividends";
      case RAW_TRANSACTION_TYPE.TRANSFER_TOKENS:
        return "Transfer Tokens";
    }
  }

  renderStatus(status: SC_TRANSACTION_STATUS) {
    switch (status) {
      case SC_TRANSACTION_STATUS.PENDING:
        return "Pending";
      case SC_TRANSACTION_STATUS.CANCELLED:
        return "Cancelled";
      case SC_TRANSACTION_STATUS.COMPLETED:
        return "Completed";
      case SC_TRANSACTION_STATUS.FAILED:
        return "Failed";
      case SC_TRANSACTION_STATUS.NOT_STARTED:
        return "Not Started";
    }
  }

  renderFailReason(reason: TRANSACTION_FAIL_REASON) {
    switch (reason) {
      case TRANSACTION_FAIL_REASON.LOW_BALANCE:
        return "Low Balance";
      case TRANSACTION_FAIL_REASON.NO_PERMISSIONS:
        return "No Permissions";
      case TRANSACTION_FAIL_REASON.TIMEOUT:
        return "Timeout";
      case TRANSACTION_FAIL_REASON.UNKNOWN:
        return "Unknown";
    }
  }

  async restartTransaction() {
    this.txDataButtonsDisabled = true;
    try {
      await this.transactionsService.restartTransactionById(this.txData.id);
      this.closeTransactionModal();
    } catch (error) {
    } finally {
      this.txDataButtonsDisabled = false;
    }
  }

  async cancelTransaction() {
    this.txDataButtonsDisabled = true;
    try {
      await this.transactionsService.cancelTransactionById(this.txData.id);
      this.closeTransactionModal();
    } catch (error) {
    } finally {
      this.txDataButtonsDisabled = false;
    }
  }

  onAdminFiltersApply() {
    this.adminTxsFilters = this.filtersService.filtersQuery;
    this.adminTxsPage = 0;
    this.requestAdminTransaction();
  }

  onClientFiltersApply() {
    this.clientTxsFilters = this.filtersService.filtersQuery;
    this.clientTxsPage = 0;
    this.requestClientTransaction();
  }
}
