import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BaseRequestService } from "./base-request.service";
import { Utils } from "../utils/utils";
import { IResponseWithPagination } from "../models/api-response";
import {
  IRawTransaction,
  ITransactionManagerStatus,
  TransactionManagerType,
} from "../models/transaction-manager";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  private adminTmStatus = new BehaviorSubject<ITransactionManagerStatus>(null);
  adminTmStatus$ = this.adminTmStatus.asObservable();

  private clientTmStatus = new BehaviorSubject<ITransactionManagerStatus>(null);
  clientTmStatus$ = this.clientTmStatus.asObservable();

  constructor(private _api: BaseRequestService) {}

  async resumeTransactionManager(type: TransactionManagerType) {
    const response = await this._api.put(`/transaction-managers/${type}/resume`, {});
    this.getTransactionManagerStatus(type);
    return response;
  }

  async stopTransactionManager(type: TransactionManagerType) {
    const response = await this._api.put(`/transaction-managers/${type}/stop`, {});
    this.getTransactionManagerStatus(type);
    return response;
  }

  async getTransactionManagerStatus(type: TransactionManagerType) {
    try {
      const response = await this._api.get<ITransactionManagerStatus>(
        `/transaction-managers/${type}/status`
      );
      if (type === "admin") {
        this.adminTmStatus.next(response);
      } else {
        this.clientTmStatus.next(response);
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  getTransactions(
    wallet: TransactionManagerType,
    page: number,
    perPage: number = 10,
    filters: string[] = []
  ) {
    const joinedFilters = Utils.mergeQueryParams(filters);
    return this._api.get<IResponseWithPagination<IRawTransaction>>(
      `/transaction-managers/${wallet}/transactions?pagination[page]=${page}&pagination[perPage]=${perPage}${joinedFilters}`
    );
  }

  getTransactionById(id: string) {
    return this._api.get<IRawTransaction>(`/transaction-managers/transactions/${id}`);
  }

  cancelTransactionById(id: string) {
    return this._api.post(`/transaction-managers/transactions/${id}/cancel`, {});
  }

  restartTransactionById(id: string) {
    return this._api.post(`/transaction-managers/transactions/${id}/restart`, {});
  }
}
