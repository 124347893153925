import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import snsWebSdk from "@sumsub/websdk";
import { SumsubService } from "src/app/services/sumsub.service";

@Component({
  selector: "app-sumsub-container",
  templateUrl: "./sumsub-container.component.html",
  styleUrls: ["./sumsub-container.component.scss"],
})
export class SumsubContainerComponent implements OnInit {
  @ViewChild("sumsubWebsdkContainer") sumsubContainer: ElementRef;
  showEndButton: boolean = false;

  constructor(private _sumsubService: SumsubService) {}

  async ngOnInit() {}

  async ngAfterViewInit() {
    const at = await this._sumsubService.getNewAccessToken();
    this.launchWebSdk(at);
  }

  launchWebSdk(accessToken: string) {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => this.getNewAccessToken())
      .withConf({
        lang: "en",
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on("idCheck.stepCompleted", payload => {
        console.log("stepCompleted", payload);
      })
      .on("idCheck.onApplicantSubmitted", payload => {
        console.log("onApplicantSubmitted", payload);
        this.showEndButton = true;
        this._sumsubService.getVerificationStatus();
      })
      .on("idCheck.onError", error => {
        console.log("onError", error);
      })
      .build();

    snsWebSdkInstance.launch(this.sumsubContainer.nativeElement);
  }

  getNewAccessToken() {
    return this._sumsubService.getNewAccessToken();
  }
}
